import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import SelectFormField from 'components/molecules/SelectFormField/SelectFormField';
import { ClientDomainType } from 'generated/graphql';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { FC, useState } from 'react';
import { Button, Modal, Typography } from '@material-ui/core';
import useClientSelectModalStyle from './ClientSelectModalStyle';

export interface TClientSelectModal {
  open: boolean;
  message?: string;
  isLoading?: boolean;
  onSave: (clientDomainType: ClientDomainType) => void;
  onClose: () => void;
  sendBtnText?: string;
  errorMsg?: string;
}
interface IOptions {
  label: string;
  value: string;
  disabled?: boolean;
}
const selectOption: IOptions[] = [
  {
    label: 'Candidate',
    value: ClientDomainType.CandidateAppDomain,
  },
  {
    label: 'Company',
    value: ClientDomainType.CompanyAppDomain,
  },
];

const ClientSelectModal: FC<TClientSelectModal> = ({
  open,
  message,
  errorMsg = '',
  onSave,
  onClose,
  isLoading = false,
  sendBtnText = 'Send',
}) => {
  const classes = useClientSelectModalStyle();
  const [selectedDomainType, setSelectedDomainType] = useState<
    ClientDomainType
  >(ClientDomainType.CandidateAppDomain);

  const handleSave = () => {
    onSave(selectedDomainType);
  };

  const handleCancel = () => {
    onClose();
  };

  const onChange = (value: TSelectFormFieldValue) => {
    if (value === '') {
      return;
    }
    setSelectedDomainType(value as ClientDomainType);
  };

  return (
    <Modal onClose={onClose} open={open} className={classes.modal}>
      {isLoading ? (
        <CircularLoader customStyle={classes.loaderWrapper} />
      ) : (
        <div className={classes.paper}>
          {errorMsg.length > 0 ? (
            <Typography color="error" className={classes.title}>
              {errorMsg}
            </Typography>
          ) : (
            <div className={classes.box}>
              <Typography className={classes.title}>{message}</Typography>
              <SelectFormField
                id="domainFormField"
                name="domain"
                label="Domains"
                onChange={onChange}
                value={selectedDomainType}
                options={selectOption}
              />
            </div>
          )}
          <div className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              {sendBtnText}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ClientSelectModal;
