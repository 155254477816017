import { ChangeEvent, FC, useMemo, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from 'components/atoms/Button/Button';
import Modal from 'components/molecules/Modal/Modal';
import { ModalTypes } from 'enums/modalTypes';
import {
  NormGroupType,
  TestCandidateFindManyByCandidateDocument,
  TestSubType,
  useTestCandidateUpdateOneMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { useReportModalStyle } from 'views/ModalView/ReportsModal/ReportsModalStyle';
import { IFilteredTestCandidate } from 'views/User/UserDetails/UserMainResets/UserReset/UserResets.interface';

export interface IAssessmentData {
  allowedTimePerSection?: number | null;
  testCandidateId: number;
  createdAt: string;
  id: number;
  nQuestionsPerTrait: number;
  manuallyCreated: boolean;
  normGroupType: NormGroupType;
  startingAbilityLowerBound: number;
  startingAbilityUpperBound: number;
  traitIds?: (number | null | undefined)[] | null;
}

interface IMitigateCircumstanceModal {
  isDefault: boolean;
  testCandidate: IFilteredTestCandidate;
  candidateId: number;
  getCurrentAssessmentId: (assesementId: number) => void;
}

const INDUCTIVE_TEST_DEFAULT_TIME = 60;
const OTHER_TESTS_DEFAULT_TIME = 180;

const MitigateCircumstanceModal: FC<IMitigateCircumstanceModal> = ({
  testCandidate,
  candidateId,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useReportModalStyle();

  const { subType } = testCandidate.test;
  const timeModifierPercentage = testCandidate.timeModifierPercentage || 0;

  const testTimes = useMemo(() => {
    if (subType === TestSubType.Inductive) {
      return {
        default: INDUCTIVE_TEST_DEFAULT_TIME,
        increased: 80,
      };
    }
    return {
      default: OTHER_TESTS_DEFAULT_TIME,
      increased: 210,
    };
  }, [subType]);

  const calculateTimeModifierFromPercentage = () => {
    return testTimes.default * (1 + timeModifierPercentage);
  };

  const calculateTimeModifierPercentage = () => {
    return allowedTimePerSection / testTimes.default - 1;
  };

  const [allowedTimePerSection, setAllowedTimePerSection] = useState(
    calculateTimeModifierFromPercentage() || testTimes.default
  );

  const getCustomValue = () => {
    if (
      allowedTimePerSection === testTimes.default ||
      allowedTimePerSection === testTimes.increased
    ) {
      return '';
    }
    return allowedTimePerSection;
  };

  const [customValue, setCustomValue] = useState(getCustomValue());

  const [testCandidateUpdateOne] = useTestCandidateUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: TestCandidateFindManyByCandidateDocument,
        variables: {
          candidateId: candidateId,
        },
      },
    ],
    onCompleted: () => {
      closeModal(ModalTypes.MITIGATE_CIRCUMSTANCE_MODAL);
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllowedTimePerSection(Number(event.target.value));
  };

  const handleCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      setCustomValue(value);
      setAllowedTimePerSection(Number(value));
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.MITIGATE_CIRCUMSTANCE_MODAL);
  };

  const handleSubmit = () => {
    testCandidateUpdateOne({
      variables: {
        id: testCandidate.id,
        timeModifierPercentage: calculateTimeModifierPercentage(),
      },
    });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography>{`Extend ${subType?.toLowerCase()} test`}</Typography>
        </Grid>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={allowedTimePerSection}
            onChange={handleChange}
          >
            <FormControlLabel
              value={testTimes.default}
              control={<Radio />}
              label={`${testTimes.default} default`}
            />
            <FormControlLabel
              value={testTimes.increased}
              control={<Radio />}
              label={`${testTimes.increased} seconds per testlet`}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                value={Number(customValue)}
                control={<Radio />}
                label="Custom"
              />{' '}
              <input
                type="text"
                style={{
                  border: '1px solid grey',
                  outline: 'none',
                  width: '50px',
                  padding: '10px',
                  marginRight: '10px',
                  borderRadius: '5px',
                }}
                value={customValue}
                onChange={handleCustomValueChange}
              />
              <p>per testlets</p>
            </div>
          </RadioGroup>
        </FormControl>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
          style={{ marginTop: '20px' }}
        >
          <Button
            color="default"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default MitigateCircumstanceModal;
