import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../components/atoms/Button/Button';
import Snackbar from '../../../../components/atoms/Snackbar/Snackbar';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import ConfirmationModal from '../../../../components/organisms/ConfirmationModal/ConfirmationModal';
import { appRoutes } from '../../../../constants/navigation';
import {
  ClientDomainType,
  useRequestPasswordRecoveryMutation,
  useUserDeleteMutation,
  useUserUpdateMutation,
} from '../../../../generated/graphql';
import validate from '../../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import { ISnackBar } from '../../../../interfaces/snackBar';
import useUserStyles from '../../UserStyle';
import {
  IEditUserInfo,
  IErrors,
  IModalStatus,
  ISearchOption,
} from '../UserDetails.interface';
import ClientSelectModal from './ClientSelectModal/ClientSelectModal';

const EditUserInfo: FC<IEditUserInfo> = ({ userInfo }) => {
  const { handleMsgType } = useNotification();
  const history = useHistory();
  const classes = useUserStyles();

  const [searchOption, setSearchOption] = useState<ISearchOption>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [modalStatus, setModalStatus] = useState<IModalStatus>({
    isOpen: false,
    msg: '',
  });
  const [clientSelectModalStatus, setClientSelectModalStatus] = useState<
    boolean
  >(false);
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const [errors, setErrors] = useState<IErrors>({
    email: '',
    firstName: '',
    lastName: '',
  });

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setSearchOption(props => ({
      ...props,
      [name]: value,
    }));
  };

  const [userUpdateMutation] = useUserUpdateMutation({
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'user details updated successfully' });
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [requestPasswordRecoveryMutation] = useRequestPasswordRecoveryMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setClientSelectModalStatus(false);
      setSnackbar({
        show: true,
        msg: "user's password reset link sent to email successfully",
      });
    },
  });

  const [userDeleteMutation] = useUserDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'user deleted successfully' });
      closeModal();
      history.push(appRoutes.users.url());
    },
  });

  const handleComfirmDeleteUser = () => {
    if (userInfo) {
      userDeleteMutation({
        variables: { id: Number(userInfo.id) },
      });
    }
  };

  const handleDeleteUser = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: true,
    });
  };

  const handleUpdateUserInfo = () => {
    const inputError = formValidation(searchOption);
    if (inputError) {
      setErrors(inputError);
    } else if (userInfo?.id && searchOption) {
      const variables = {
        ...searchOption,
        id: Number(userInfo.id),
      };
      userUpdateMutation({ variables });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  useEffect(() => {
    if (userInfo) {
      const { email, firstName, lastName } = userInfo;
      setSearchOption(props => ({
        ...props,
        email,
        firstName,
        lastName,
      }));
    }
  }, [userInfo]);

  const formValidation = (newValues: IErrors) => {
    return validate(newValues, {
      email: {
        nonEmptyString: true,
        email: '^Not a valid email',
      },
      firstName: {
        nonEmptyString: true,
      },
      lastName: {
        nonEmptyString: true,
      },
    });
  };

  const handlePasswordReminder = (clientDomainType: ClientDomainType) => {
    if (searchOption.email) {
      requestPasswordRecoveryMutation({
        variables: {
          email: searchOption.email,
          clientDomainType: clientDomainType,
        },
      });
    }
  };

  const closeModal = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: false,
    });
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        style={{ marginBottom: '35px' }}
        className={classes.w100}
      >
        <p style={{ fontSize: 24 }}>Edit User</p>
        <Button color="default" onClick={handleDeleteUser}>
          DELETE
        </Button>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {userInfo && (
          <TextFormField
            id="id"
            name="id"
            label="Id"
            isDisabled
            value={`${userInfo?.id}`}
            onChange={handleChange}
            className={classes.mr10}
          />
        )}
        {userInfo && (
          <TextFormField
            id="subId"
            name="subId"
            label="SubId"
            isDisabled
            value={`${userInfo?.subId}`}
            onChange={() => {}}
            className={classes.mr10}
          />
        )}
        <TextFormField
          id="firstName"
          name="firstName"
          label="Given Name"
          value={searchOption.firstName}
          onChange={handleChange}
          className={classes.mr10}
          hasError={!!errors?.firstName}
          errorText={errors.firstName ? errors?.firstName[0] : ''}
        />
        <TextFormField
          id="lastName"
          name="lastName"
          label="Family Name"
          value={searchOption.lastName}
          onChange={handleChange}
          className={classes.mr10}
          hasError={!!errors?.lastName}
          errorText={errors.lastName ? errors?.lastName[0] : ''}
        />
        <TextFormField
          id="email"
          name="email"
          label="Email"
          value={searchOption.email}
          onChange={handleChange}
          className={classes.mr10}
          hasError={!!errors?.email}
          errorText={errors.email ? errors?.email[0] : ''}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '20px 0',
        }}
      >
        <div className={classes.mr10}>
          <Button
            onClick={() => {
              setClientSelectModalStatus(true);
            }}
          >
            Send Password Reminder
          </Button>
        </div>
        <div className={classes.mr10}>
          <Button onClick={handleUpdateUserInfo}>Save</Button>
        </div>
        <Button>Cancel</Button>
      </div>
      <ConfirmationModal
        open={modalStatus.isOpen}
        message={`Please confirm you wish to delete ${searchOption.firstName} ${searchOption.lastName}'s account`}
        onSave={handleComfirmDeleteUser}
        onClose={closeModal}
        saveBtnText="DELETE"
      />
      <ClientSelectModal
        open={clientSelectModalStatus}
        message={`Please select the client domain type.`}
        onSave={handlePasswordReminder}
        onClose={() => setClientSelectModalStatus(false)}
      />
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default EditUserInfo;
