import Button from 'components/atoms/Button/Button';
import DeleteApiKeyButton from 'components/organisms/DeleteApiKeyButton/DeleteApiKeyButton';
import { usersRoutes } from 'constants/navigation';
import { UserFragment, UserFragmentDoc } from 'generated/graphql';
import { formatDate } from 'helpers/date';
import { TApiKey } from 'interfaces/apiKey';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { TableCell, TableRow } from '@material-ui/core';

interface IApiKeysUserListItem {
  apiKey: TApiKey;
}

const ApiKeysUserListItem: FC<IApiKeysUserListItem> = ({
  apiKey: { ownerId, key, createdAt, id },
}) => {
  const client = useApolloClient();

  const apiKeyOwnerUser = client.readFragment<UserFragment>({
    id: `User:${ownerId}`,
    fragment: UserFragmentDoc,
    fragmentName: 'User',
  });

  return (
    <TableRow>
      <TableCell>{key}</TableCell>
      <TableCell>
        {formatDate(new Date(createdAt).toISOString(), 'dd MMM yyyy')}
      </TableCell>
      <TableCell>{apiKeyOwnerUser?.email}</TableCell>
      <TableCell>
        <Link to={usersRoutes.user.url(ownerId)}>
          <Button>Edit</Button>
        </Link>
        <span style={{ marginLeft: 5 }}>
          <DeleteApiKeyButton apiKey={key} apiKeyId={id} />
        </span>
      </TableCell>
    </TableRow>
  );
};

export default ApiKeysUserListItem;
