import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../../components/atoms/Button/Button';
import { reportsRoutes } from '../../../constants/navigation';
import { ReportConfig } from '../../../generated/graphql';
import useWidgetStyles from '../WidgetStyles';

interface IReportConfigs {
  id: number;
  newPageBefore: boolean;
  report: {
    id: number;
    name: string;
  };
  reportId: number;
}

interface IWidgetReports {
  reportConfigs: ReportConfig[];
}

const WidgetReports: FC<IWidgetReports> = ({ reportConfigs }) => {
  const history = useHistory();
  const classes = useWidgetStyles();

  const handleViewReportBtnClick = (reportId: number) => {
    history.push(reportsRoutes.report.url(reportId));
  };

  return (
    <Grid>
      <Grid item xs={3}>
        <Typography variant="h6" className={classes.title}>
          Used in Reports
        </Typography>
      </Grid>
      {reportConfigs?.map((item: IReportConfigs) => (
        <Grid key={item.id} className={classes.mb10}>
          <div className={classes.widgetOption}>
            <p style={{ width: '280px' }}>{item.report.name}</p>
            <div>
              <Button
                color="default"
                className={classes.firstBtn}
                onClick={() => handleViewReportBtnClick(item.reportId)}
              >
                VIEW
              </Button>
              <Button className={classes.btn} color="default">
                REMOVE
              </Button>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default WidgetReports;
