import Button from 'components/atoms/Button/Button';
import Modal from 'components/molecules/Modal/Modal';
import { ModalTypes } from 'enums/modalTypes';
import { WidgetOptionFieldType } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { IReportWidget } from 'interfaces/report';
import { TWidgetOption, TWidgetOptions } from 'interfaces/widget';
import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { formatUploadAssetsData } from './WidgetConfigModal.helpers';
import { UploadFieldAsset } from './WidgetConfigModal.interfaces';
import { useReportModalStyle } from './WidgetConfigModalStyle';
import WidgetConfigTextField from './WidgetConfigTextField/WidgetConfigTextField';
import WidgetUploadFieldDisplay from './WidgetUploadFieldDisplay/WidgetUploadFieldDisplay';

interface IWidgetConfigModal {
  reportConfig: IReportWidget;
  isAvailableWidget?: boolean;
  getModalWidgetInfo: (
    updatedWidgetOption: TWidgetOptions[],
    dndId?: number
  ) => void;
}

export interface IWidgetOption {
  id?: number;
  name?: string;
  fieldType?: WidgetOptionFieldType;
  question?: string;
  defaultValue?: string;
  isArchived?: boolean;
  settings?: string;
  widgetOption?: TWidgetOption[];
}

export interface IWidgetSetting {
  settings?: string;
  id?: number;
}

const WidgetConfigModal: FC<IWidgetConfigModal> = ({
  reportConfig,
  isAvailableWidget = false,
  getModalWidgetInfo,
}) => {
  const classes = useReportModalStyle();
  const [updatedWidgetOption, setUpdatedWidgetOption] = useState<
    TWidgetOptions[]
  >(reportConfig?.widgetOptions || []);

  const [uploadFieldAssets, setUploadFieldAssets] = useState<
    UploadFieldAsset[]
  >(formatUploadAssetsData(updatedWidgetOption));

  const isImageUploadWidget = (type: WidgetOptionFieldType | undefined) => {
    return type === 'FILE_UPLOAD_FIELD';
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.WIDGET_CONFIG_MODAL);
  };

  const getWidgetInfo = (value: IWidgetSetting, index: number) => {
    let temp = [...updatedWidgetOption];
    if (temp) {
      temp[index] = value;
      setUpdatedWidgetOption(temp);
    }
  };

  const handleUpdateImageWidgetInfo = (
    newAssetValues: UploadFieldAsset[],
    newWidgetData: TWidgetOptions,
    index: number
  ) => {
    setUploadFieldAssets(newAssetValues);
    getWidgetInfo(newWidgetData, index);
  };

  const handleSubmitWidget = () => {
    if (updatedWidgetOption) {
      getModalWidgetInfo(updatedWidgetOption, reportConfig?.dndId);
    }
    handleCloseModal();
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid style={{ marginBottom: 10 }}>
          {isAvailableWidget && (
            <Typography variant="h2" className={classes.h2}>
              Widget Config Preview
            </Typography>
          )}
          <Typography>Widget Name: {reportConfig?.name}</Typography>
        </Grid>
        <div
          style={{
            maxHeight: 350,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            paddingRight: 8,
          }}
        >
          {reportConfig?.widgetOptions?.map(
            (item: TWidgetOptions, index: number) =>
              isImageUploadWidget(item.fieldType) ? (
                <Grid key={item.id} item className={classes.textfieldContainer}>
                  <WidgetUploadFieldDisplay
                    uploadFieldAssets={uploadFieldAssets}
                    updateWidgetInfo={(newAssetValues, newWidgetData) =>
                      handleUpdateImageWidgetInfo(
                        newAssetValues,
                        newWidgetData,
                        index
                      )
                    }
                    widget={{ ...item, type: reportConfig.type }}
                  />
                </Grid>
              ) : (
                <Grid key={item.id} item className={classes.textfieldContainer}>
                  {isAvailableWidget && (
                    <Typography variant="h6">
                      <strong>{item.fieldType}</strong>
                    </Typography>
                  )}
                  <Typography style={{ marginBottom: 5 }}>
                    <strong>{item.question}</strong>
                  </Typography>
                  <WidgetConfigTextField
                    item={item}
                    getWidgetInfo={value => getWidgetInfo(value, index)}
                    isAvailableWidget={isAvailableWidget}
                    index={index}
                  />
                </Grid>
              )
          )}
        </div>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="default"
            className={isAvailableWidget ? classes.closeBtn : classes.btn}
            onClick={handleCloseModal}
          >
            {`${isAvailableWidget ? 'Close' : 'Cancel'}`}
          </Button>
          {!isAvailableWidget && (
            <Button
              color="primary"
              className={classes.btn}
              onClick={handleSubmitWidget}
            >
              save
            </Button>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default WidgetConfigModal;
