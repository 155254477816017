import { FC, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import Button from '../../../../components/atoms/Button/Button';
import CompanySelectFormField from '../../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import SelectFormField from '../../../../components/molecules/SelectFormField/SelectFormField';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import {
  FieldInstanceType,
  FieldType,
  FormManagerType,
} from '../../../../enums/forms/form';
import validate from '../../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import { TFieldSettingsModel } from '../../../../interfaces/forms/formGQL';
import useCandidateInfoFieldFormStyle from './CandidateInfoFieldFormStyle';

export interface ICandidateInfoFieldFormValues {
  name: string;
  question: string;
  hint: string;
  companyId: string;
  type: TSelectFormFieldValue;
  allowedValues: string;
  instanceType: TSelectFormFieldValue;
  formManagerType: FormManagerType;
  settings: TFieldSettingsModel;
}

interface ICandidateInfoFieldFormErrors {
  name: string;
  question: string;
  companyId: string;
  type: string;
  hint: string;
  allowedValues: string;
  instanceType: string;
  formManagerType: string;
}

export interface ICandidateInfoFieldFormSubmitValues {
  name: string;
  question: string;
  hint: string;
  companyId: string;
  type: TSelectFormFieldValue;
  allowedValues: string;
  instanceType: TSelectFormFieldValue;
  formManagerType: FormManagerType;
  settings: TFieldSettingsModel;
}

interface ICandidateInfoFieldForm {
  onSubmit: (formValues: ICandidateInfoFieldFormSubmitValues) => void;
  initialValues?: ICandidateInfoFieldFormValues | { companyId: string };
  title: string;
  isEditMode?: boolean;
}

const candidateInfoFieldTypeOptions = [
  {
    value: FieldType.SINGLE_SELECT_FIELD,
    label: 'Select single',
  },
  {
    value: FieldType.MULTIPLE_SELECT_FIELD,
    label: 'Select multiple',
  },
  {
    value: FieldType.SHORT_TEXT_FIELD,
    label: 'Short text',
  },
  {
    value: FieldType.LONG_TEXT_FIELD,
    label: 'Long text',
  },
  {
    value: FieldType.DATE_FIELD,
    label: 'Date',
  },
  {
    value: FieldType.COMPANY_EMPLOYEE_SELECT_FIELD,
    label: 'Company Employee Select Field',
  },
];

const candidateInfoFieldInstanceTypeOptions = [
  {
    value: FieldInstanceType.MULTIPLE,
    label: 'Multiple',
  },
  {
    value: FieldInstanceType.SINGLETON,
    label: 'Singleton',
  },
];

const formManagerTypeOptions = [
  {
    value: FormManagerType.CI,
    label: 'CI',
  },
  {
    value: FormManagerType.TR,
    label: 'TR',
  },
  {
    value: FormManagerType.GLOBAL,
    label: 'Global',
  },
  {
    value: FormManagerType.MANAGER_SURVEY,
    label: 'Manager survey',
  },
  {
    value: FormManagerType.QUALITY_OF_HIRE,
    label: 'Quality of hire',
  },
  {
    value: FormManagerType.RS,
    label: 'Reskilling',
  },
  {
    value: FormManagerType.APPRENTICE,
    label: 'Apprentice',
  },
];

const CandidateInfoFieldForm: FC<ICandidateInfoFieldForm> = ({
  isEditMode = false,
  onSubmit,
  initialValues,
  title,
}) => {
  const classes = useCandidateInfoFieldFormStyle();
  const [values, setValues] = useState<ICandidateInfoFieldFormValues>({
    name: '',
    question: '',
    companyId: '',
    hint: '',
    settings: {
      allowFreeText: false,
      searchable: false,
    },
    type: FieldType.SINGLE_SELECT_FIELD,
    allowedValues: '',
    instanceType: FieldInstanceType.MULTIPLE,
    formManagerType: FormManagerType.CI,
    ...initialValues,
  });
  const [errors, setErrors] = useState<
    ICandidateInfoFieldFormErrors | undefined
  >();
  const isAllowedValuesFieldVisible =
    values.type === FieldType.SINGLE_SELECT_FIELD ||
    values.type === FieldType.MULTIPLE_SELECT_FIELD;
  const isInstanceTypeFieldVisible = !values.companyId;

  const handleChangeName = (value: string) => {
    setValues(prevValues => ({ ...prevValues, name: value }));
    if (errors?.name) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, name: '' } : undefined
      );
    }
  };

  const handleChangeQuestion = (value: string) => {
    setValues(prevValues => ({ ...prevValues, question: value }));
    if (errors?.question) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, question: '' } : undefined
      );
    }
  };
  const handleChangeTip = (value: string) => {
    setValues(prevValues => ({ ...prevValues, hint: value }));
    if (errors?.hint) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, hint: '' } : undefined
      );
    }
  };
  const handleChangeCompany = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, companyId: value as string }));
    if (errors?.companyId) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, companyId: '' } : undefined
      );
    }
  };

  const handleChangeType = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      type: value,
    }));
    if (errors?.type) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, type: '' } : undefined
      );
    }

    if (!isAllowedValuesFieldVisible) {
      setValues(prevValues => ({
        ...prevValues,
        allowedValues: '',
      }));
      if (errors?.allowedValues) {
        setErrors(prevErrors =>
          prevErrors ? { ...prevErrors, allowedValues: '' } : undefined
        );
      }
    }
  };

  const handleChangeAllowedValues = (value: string) => {
    setValues(prevValues => ({ ...prevValues, allowedValues: value }));
    if (errors?.allowedValues) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, allowedValues: '' } : undefined
      );
    }
  };

  const handleChangeInstanceType = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      instanceType: value,
    }));
  };

  const handleChangeFormManagerType = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      formManagerType: value as FormManagerType,
    }));
  };
  const handleChangeAllowFreeText = () => {
    setValues(prevValues => ({
      ...prevValues,
      settings: {
        ...prevValues.settings,
        allowFreeText: !prevValues.settings.allowFreeText,
      },
    }));
  };
  const handleChangeSearchable = () => {
    setValues(prevValues => ({
      ...prevValues,
      settings: {
        ...prevValues.settings,
        searchable: !prevValues.settings.searchable,
      },
    }));
  };
  const validateCreateCandidateInfoFieldForm = () => {
    return validate(values, {
      name: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
      question: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
      allowedValues: isAllowedValuesFieldVisible
        ? {
            nonEmptyString: {
              message: 'must be provided.',
            },
          }
        : {},
    });
  };

  const handleSubmit = () => {
    const newErrors = validateCreateCandidateInfoFieldForm();
    if (!newErrors) {
      onSubmit({
        ...values,
        // If company not selected admin should be able to
        // set if field value will be shared between multiple forms.
        instanceType: isInstanceTypeFieldVisible
          ? values.instanceType
          : FieldInstanceType.MULTIPLE,
      });
    } else {
      setErrors(newErrors);
    }
  };

  const key = 'cciff';
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      className={classes.container}
    >
      <Grid item className={classes.titleWrapper}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-name`}
          name="name"
          label="Name"
          onChange={handleChangeName}
          value={values.name}
          errorText={errors?.name}
          hasError={!!errors?.name}
        />
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-question`}
          name="question"
          label="Question"
          onChange={handleChangeQuestion}
          value={values.question}
          errorText={errors?.question}
          hasError={!!errors?.question}
        />
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-hint`}
          name="hint"
          label="Tip"
          onChange={handleChangeTip}
          value={values.hint}
          errorText={errors?.hint}
          hasError={!!errors?.hint}
          multiline
          rows={4}
        />
      </Grid>
      <Grid item>
        <CompanySelectFormField
          id={`${key}-company`}
          name="companyId"
          label="Company"
          value={values.companyId}
          onChange={handleChangeCompany}
          errorText={errors?.companyId}
          hasError={!!errors?.companyId}
          isDisabled={isEditMode}
        />
      </Grid>
      <Grid item>
        <SelectFormField
          id={`${key}-type`}
          name="type"
          label="Type"
          value={values.type}
          onChange={handleChangeType}
          options={candidateInfoFieldTypeOptions}
          hasNullValue={false}
          errorText={errors?.type}
          hasError={!!errors?.type}
          isDisabled={isEditMode}
        />
      </Grid>
      {(isAllowedValuesFieldVisible ||
        values.type === FieldType.COMPANY_EMPLOYEE_SELECT_FIELD) && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.settings.searchable}
                onChange={handleChangeSearchable}
                name="searchable"
                color="primary"
              />
            }
            label="Searchable"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.settings.allowFreeText}
                onChange={handleChangeAllowFreeText}
                name="allowFreeText"
                color="primary"
              />
            }
            label="Allow Free text"
          />
        </Grid>
      )}
      <Grid item>
        <SelectFormField
          id={`${key}-form-manager-type`}
          name="formManagerType"
          label="For use by"
          value={values.formManagerType}
          onChange={handleChangeFormManagerType}
          options={formManagerTypeOptions}
          hasNullValue={false}
          isDisabled={isEditMode}
        />
      </Grid>
      {isInstanceTypeFieldVisible && (
        <Grid item>
          <SelectFormField
            id={`${key}-instance-type`}
            name="instanceType"
            label="Instance type"
            value={values.instanceType}
            onChange={handleChangeInstanceType}
            options={candidateInfoFieldInstanceTypeOptions}
            hasNullValue={false}
            isDisabled={isEditMode}
          />
        </Grid>
      )}
      {isAllowedValuesFieldVisible && (
        <Grid item>
          <TextFormField
            id={`${key}-allowedValues`}
            name="allowedValues"
            label="Allowed values"
            value={values.allowedValues}
            onChange={handleChangeAllowedValues}
            multiline
            rows={6}
            errorText={errors?.allowedValues}
            hasError={!!errors?.allowedValues}
          />
        </Grid>
      )}
      <Grid className={classes.buttonWrapper} item>
        <Button onClick={handleSubmit}>Save</Button>
      </Grid>
    </Grid>
  );
};

export default CandidateInfoFieldForm;
