import { FC } from 'react';
import { Grid } from '@material-ui/core';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { TTextFromFieldValue } from '../../../interfaces/forms/form';

interface ICompaniesOptions {
  search: TTextFromFieldValue;
  setSearch: (value: TTextFromFieldValue) => void;
}

const CompaniesOptions: FC<ICompaniesOptions> = ({ search, setSearch }) => {
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={4}
    >
      <Grid item>
        <TextFormField
          id="search"
          name="Search"
          value={search}
          label="Search"
          onChange={setSearch}
        />
      </Grid>
    </Grid>
  );
};

export default CompaniesOptions;
