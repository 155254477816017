import React, { FC } from 'react';
import InterviewGuidesNavigation from '../../navigation/InterviewGuidesNavigation/InterviewGuidesNavigation';
import InterviewGuidesSwitch from '../../navigation/InterviewGuidesNavigation/InterviewGuidesSwitch';
import useInterviewGuideStyle from './InterviewGuidesStyle';

interface IInterviewGuides {}
const InterviewGuides: FC<IInterviewGuides> = () => {
  const classes = useInterviewGuideStyle();
  return (
    <>
      <InterviewGuidesNavigation />
      <div className={classes.container}>
        <InterviewGuidesSwitch />
      </div>
    </>
  );
};

export default InterviewGuides;
