import { makeStyles } from '@material-ui/core/styles';

const useSoftSkillContentListItemStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
  gridItemWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  gridTextArea: {
    '@media (max-width: 1550px)': {
      maxWidth: '194.5px',
    },
  },
}));

export default useSoftSkillContentListItemStyle;
