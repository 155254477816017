import { ProjectJobRoleFragment } from 'generated/graphql';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { FC } from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export interface ISelectFormField {
  id: string;
  name: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  projectJobRoles: ProjectJobRoleFragment[] | [];
  label?: string;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean | undefined;
  errorText?: string | undefined;
  className?: string;
  isDisabled?: boolean;
  hasNullValue?: boolean;
  inputProps?: InputBaseComponentProps;
}

const ProjectJobRoleSelectFormField: FC<ISelectFormField> = ({
  id,
  name,
  label,
  projectJobRoles,
  onChange,
  onBlur,
  hasError = false,
  errorText = '',
  isDisabled = false,
  className = '',
  inputProps,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as TSelectFormFieldValue;
    onChange(newValue, name);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, name);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth error={hasError}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        native
        onChange={handleChange}
        disabled={isDisabled}
        className={className}
        label={label}
        inputProps={{
          ...inputProps,
          id,
          name,
          onBlur: handleBlur,
        }}
      >
        {projectJobRoles?.map(projectJobRole => (
          <option key={projectJobRole.id} value={projectJobRole.id}>
            {projectJobRole.jobRole.name}
          </option>
        ))}
      </Select>
      {hasError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default ProjectJobRoleSelectFormField;
