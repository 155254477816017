import { ActorRole, ProductSolution, StageType } from 'generated/graphql';
import {
  TMultiselectOption,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import { StageModes } from './types';

const defaultActorRoles = [
  ActorRole.RecruiterOrTalentAcquisitionPartner,
  ActorRole.HiringManager,
  ActorRole.Interviewer,
  ActorRole.HeadOfPeopleOrTalent,
  ActorRole.EarlyCareerTeamMember,
  ActorRole.AssessorOrInterviewer,
  ActorRole.ReskillingTeamMember,
  ActorRole.LineManager,
];

const professionalHiringActorRoles = [
  ActorRole.RecruiterOrTalentAcquisitionPartner,
  ActorRole.HiringManager,
  ActorRole.Interviewer,
  ActorRole.HeadOfPeopleOrTalent,
  ActorRole.EarlyCareerTeamMember,
  ActorRole.AssessorOrInterviewer,
  ActorRole.ReskillingTeamMember,
  ActorRole.LineManager,
];

const reskillingActorRoles = [
  ActorRole.ReskillingTeamMember,
  ActorRole.HiringManager,
  ActorRole.LineManager,
  ActorRole.Interviewer,
  ActorRole.HeadOfPeopleOrTalent,
  ActorRole.RecruiterOrTalentAcquisitionPartner,
  ActorRole.EarlyCareerTeamMember,
  ActorRole.AssessorOrInterviewer,
];

const earlyCareersActorRoles = [
  ActorRole.EarlyCareerTeamMember,
  ActorRole.AssessorOrInterviewer,
  ActorRole.HeadOfPeopleOrTalent,
  ActorRole.RecruiterOrTalentAcquisitionPartner,
  ActorRole.HiringManager,
  ActorRole.Interviewer,
  ActorRole.ReskillingTeamMember,
  ActorRole.LineManager,
];

const productSolutionOrdering: Record<ProductSolution, ActorRole[]> = {
  [ProductSolution.ProfessionalHiring]: professionalHiringActorRoles,
  [ProductSolution.Reskilling]: reskillingActorRoles,
  [ProductSolution.EarlyCareerHiring]: earlyCareersActorRoles,
  // default order
  [ProductSolution.Learning]: defaultActorRoles,
  [ProductSolution.SuccessionPlanning]: defaultActorRoles,
  [ProductSolution.VolumeHiring]: defaultActorRoles,
};

export const actorRoleLabels = {
  [ActorRole.HiringManager]: 'Hiring manager',
  [ActorRole.HeadOfPeopleOrTalent]: 'Head of People',
  [ActorRole.RecruiterOrTalentAcquisitionPartner]: 'Recruiter',
  [ActorRole.Interviewer]: 'Interviewer',
  [ActorRole.ReskillingTeamMember]: 'Reskilling team member',
  [ActorRole.LineManager]: 'Line manager',
  [ActorRole.EarlyCareerTeamMember]: 'Early Careers team member',
  [ActorRole.AssessorOrInterviewer]: 'Assessor',
};

export const stageModes = {
  [StageModes.ASSIGNEE_BASED]: {
    value: StageModes.ASSIGNEE_BASED,
    label: 'Assignee based',
    labelNote: `Only assignees are notified when their candidates' assessments are complete.`,
  },
  [StageModes.ACCESS_BASED]: {
    value: StageModes.ACCESS_BASED,
    label: 'Access based',
    labelNote: `All project team members with access to a candidate are notified when their assessments are complete.`,
  },
  [StageModes.PROJECT_ROLE_BASED]: {
    value: StageModes.PROJECT_ROLE_BASED,
    label: 'Project role based',
    labelNote: `Only specified project roles within the project team will be notified when candidates complete their assessments.`,
  },
  [StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED]: {
    value: StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED,
    label: 'Project role & assignee based',
    labelNote: `Only specified project roles within the project and all assignees will be notified when candidates complete their assessments.`,
  },
  [StageModes.DISABLED]: {
    value: 'disabled',
    label: 'Disabled',
    labelNote: `No notifications will be sent when candidates complete their assessments, even if they have assignees.`,
  },
};

export const stageModeOptions: TSelectOption[] = [
  stageModes[StageModes.ASSIGNEE_BASED],
  stageModes[StageModes.ACCESS_BASED],
  stageModes[StageModes.PROJECT_ROLE_BASED],
  stageModes[StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED],
  stageModes[StageModes.DISABLED],
];

export function getOrderedActorRoles(
  productSolution: ProductSolution | undefined | null
): ActorRole[] {
  if (!productSolution) {
    return defaultActorRoles;
  }

  return productSolutionOrdering[productSolution];
}

export function parseActorRolesForSelect(
  productSolution: ProductSolution | undefined | null
): TMultiselectOption[] {
  return getOrderedActorRoles(productSolution).map(actorRole => ({
    label: actorRoleLabels[actorRole],
    value: actorRole,
  }));
}

export function getSendEmailSettings(
  stageMode: string
): { emailManagerReport: boolean; emailProjectTeam: boolean } {
  switch (stageMode) {
    case StageModes.ACCESS_BASED:
      return {
        emailManagerReport: true,
        emailProjectTeam: true,
      };

    case StageModes.ASSIGNEE_BASED:
      return {
        emailManagerReport: true,
        emailProjectTeam: false,
      };
    case StageModes.PROJECT_ROLE_BASED:
      return {
        emailManagerReport: false,
        emailProjectTeam: true,
      };

    case StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED:
      return {
        emailManagerReport: true,
        emailProjectTeam: true,
      };

    case StageModes.DISABLED:
      return {
        emailManagerReport: false,
        emailProjectTeam: false,
      };

    default:
      return {
        emailManagerReport: true,
        emailProjectTeam: false,
      };
  }
}

export const STAGE_TYPE_FULL = {
  label: 'Full',
  value: StageType.Full,
};

export const stageTypeOptions = [
  {
    label: 'External',
    value: StageType.External,
  },
  STAGE_TYPE_FULL,
  {
    label: 'Partial',
    value: StageType.Partial,
  },
];
