import {
  ProjectJobRoleFragment,
  SuccessProfileSoftSkillNameFragment,
  SuccessProfileTechnicalSkillNameFragment,
  TechnicalSkillFragment,
  useProjectJobRoleFindManyQuery,
} from 'generated/graphql';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { FC, useMemo } from 'react';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ProjectJobRolesOverviewPresentational from '../ProjectJobRolesOverviewPresentational/ProjectJobRolesOverviewPresentational';
import { TSkillOverview } from '../ProjectJobRolesOverviewSkillsList/ProjectJobRolesOverviewSkillsList.types';

interface IProjectJobRolesOverview {
  projectId: number;
  companyId: number;
}

const defaulErrorMessage =
  'Ops, some issue happened while getting Project job roles';

const emptySkills = {
  softSkills: [] as TSkillOverview[],
  technicalSkills: [] as TSkillOverview[],
};

const ProjectJobRolesOverview: FC<IProjectJobRolesOverview> = ({
  projectId,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const { data, error } = useProjectJobRoleFindManyQuery({
    variables: {
      id: projectId,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message || defaulErrorMessage,
      });
    },
  });

  const projectJobRoles = useMemo(() => data?.ProjectJobRoleFindMany || [], [
    data?.ProjectJobRoleFindMany,
  ]);

  const getProjectJobRoleActiveSkillsProfile = (
    projectJobRole: ProjectJobRoleFragment
  ) => {
    return projectJobRole.jobRole.skillsProfiles.find(
      sp => sp.id === projectJobRole.skillsProfileId
    );
  };

  const getOverviewSoftSkills = (
    successProfileSoftSkills?: SuccessProfileSoftSkillNameFragment[] | null
  ) => {
    return (
      successProfileSoftSkills?.map(spSoftSkill => ({
        id: spSoftSkill.softSkillId,
        name: spSoftSkill.name,
      })) || []
    );
  };

  const getOverviewTechnicalSkills = (
    successProfileTechnicalSkills?:
      | SuccessProfileTechnicalSkillNameFragment[]
      | null
  ) => {
    return (
      successProfileTechnicalSkills
        ?.filter(spTechnicalSkill => spTechnicalSkill.technicalSkill)
        .map(({ technicalSkill }) => ({
          id: (technicalSkill as TechnicalSkillFragment).id,
          name: (technicalSkill as TechnicalSkillFragment).name,
        })) || []
    );
  };

  const getUniqueSkills = (skills: TSkillOverview[]) => {
    const uniqueSkills = uniqBy(skills, skill => skill.id);
    return sortBy(uniqueSkills, skill => skill.name.toLocaleLowerCase());
  };

  const {
    softSkills: jobRolesSoftSkillsOverview,
    technicalSkills: jobRolesTechnicalSkillsOverview,
  } = useMemo(() => {
    if (!projectJobRoles.length) {
      return emptySkills;
    }

    /**
     * Gets all skills from all job roles.
     */
    return projectJobRoles.reduce((acc, projectJobRole) => {
      /**
       * "JobRole" may contain multiple skills profile versions,
       * here we get the one related to the provided "ProjectJobRole".
       */
      const skillsProfile = getProjectJobRoleActiveSkillsProfile(
        projectJobRole
      );

      if (!skillsProfile) {
        return acc;
      }

      const {
        successProfileSoftSkills,
        successProfileTechnicalSkills,
      } = skillsProfile;

      const softSkillsParsed = getOverviewSoftSkills(successProfileSoftSkills);
      const softSkills = [...acc.softSkills, ...softSkillsParsed];

      const technicalSkillsParsed = getOverviewTechnicalSkills(
        successProfileTechnicalSkills
      );
      const technicalSkills = [
        ...acc.technicalSkills,
        ...technicalSkillsParsed,
      ];

      return {
        softSkills,
        technicalSkills,
      };
    }, emptySkills);
  }, [projectJobRoles]);

  /**
   * Skills should be unique and sorted alphabetically.
   */
  const parsedSoftSkills = useMemo(
    () => getUniqueSkills(jobRolesSoftSkillsOverview),
    [jobRolesSoftSkillsOverview]
  );
  const parsedTechnicalSkills = useMemo(
    () => getUniqueSkills(jobRolesTechnicalSkillsOverview),
    [jobRolesTechnicalSkillsOverview]
  );

  if (error) {
    // TODO: Add better error handling.
    return <div>{error.message}</div>;
  }

  if (data) {
    return (
      <ProjectJobRolesOverviewPresentational
        projectId={projectId}
        companyId={companyId}
        technicalSkills={parsedTechnicalSkills}
        softSkills={parsedSoftSkills}
        projectJobRoles={projectJobRoles}
      />
    );
  }

  return (
    <div data-testid="project-job-roles-overview-loader">
      <Loader variant="bubbles" />
    </div>
  );
};

export default ProjectJobRolesOverview;
