import {
  useCompaniesQuery,
  useProjectFindManyByCompanyQuery,
  useScheduleRoiCsvFileExportMutation,
} from 'generated/graphql';
import { useROIExportPolling } from 'helpers/useROIExportPolling';
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  InlineNotification,
  NotificationType,
  Multiselect,
  TMultiselectOption,
  TNotification,
  useNotification,
  SelectFormField,
  TSelectOption,
  Checkbox,
} from '@spotted-zebra-uk/ui-components';
import styles from './QBR.module.scss';

const initialFormValues: {
  company: TSelectOption;
  projects: TMultiselectOption[];
} = {
  company: { label: '', value: '' },
  projects: [],
};

export type TFormValues = {
  company: string;
  projects: number[];
};

const QBRData = () => {
  const [QBRValues, setQBRValues] = useState(initialFormValues);
  const [isArchivedIncluded, setIsArchivedIncluded] = useState(false);
  const { handleMsgType } = useNotification();

  const { data: companiesData } = useCompaniesQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${error.message}`,
      });
    },
  });

  const { data: projectsData } = useProjectFindManyByCompanyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      companyId: parseInt(QBRValues.company.value),
    },
    skip: !QBRValues.company.value,
    onCompleted: () => {
      QBRValues.projects.length !== 0 && handleSelectProjects([], '');
    },
  });

  const [
    generateCSV,
    { loading: loadingGenerateCSV, data: ScheduledExportData },
  ] = useScheduleRoiCsvFileExportMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: `${error}` });
    },
    onCompleted: data => {
      if (data.FileExportScheduledTask.subId) {
        startPolling();
      }
    },
  });

  const { startPolling, isPolling, errorMessage } = useROIExportPolling(
    ScheduledExportData?.FileExportScheduledTask?.subId
  );

  //Select options
  const companySelectOptions: TSelectOption[] = useMemo(
    () =>
      companiesData?.Companies?.map(company => ({
        label: company.name,
        value: company.id.toString(),
      })) || [],
    [companiesData?.Companies]
  );

  const projectsMultiSelectOptions: TMultiselectOption[] = useMemo(() => {
    const projects = isArchivedIncluded
      ? projectsData?.projects
      : projectsData?.projects.filter(project => !project.isArchived);
    return (
      projects?.map(project => ({
        label: project.name,
        value: project.id.toString(),
        additionalProps: project.isArchived,
      })) || []
    );
  }, [projectsData?.projects, isArchivedIncluded]);

  useEffect(() => {
    if (errorMessage) {
      handleMsgType({ type: TNotification.error, message: `${errorMessage}` });
    }
    // eslint-disable-next-line
  }, [errorMessage]);

  const handleSelectCompany = (newValue: TSelectOption) => {
    setQBRValues(prev => ({ ...prev, company: newValue }));
    setIsArchivedIncluded(false);
  };

  const handleSelectProjects = (
    newValue: TMultiselectOption<string>[],
    _: string
  ) => {
    setQBRValues(prev => ({ ...prev, projects: newValue }));
  };

  const handleSelectArchived = (newIsArchiveIncludedValue: boolean) => {
    if (!newIsArchiveIncludedValue) {
      // If archived projects were selected and the user toggles back to only unarchived projects,
      // filter the archived ones out of the selected array
      const filteredProjects = QBRValues.projects.filter(
        project => !project.additionalProps
      );
      setQBRValues(prev => ({ ...prev, projects: filteredProjects }));
    }
    setIsArchivedIncluded(prev => !prev);
  };

  const handleResetForm = () => {
    setQBRValues(initialFormValues);
    setIsArchivedIncluded(false);
  };

  const isSubmitButtonDisabled = () => {
    return !QBRValues.company.value && QBRValues.projects.length === 0;
  };

  const handleSubmit = () => {
    generateCSV({
      variables: {
        projectIds: QBRValues.projects.map(project =>
          parseInt(project?.value || '')
        ),
      },
    });
  };

  return (
    <main className={styles.container} data-testid="qbr">
      <h1 className={styles.title}>QBR ROI Report Generator</h1>
      <InlineNotification
        title={'Apply filters to generate report'}
        notificationType={NotificationType.INFORMATION}
      />
      <section className={styles.formContainer} data-testid="qbr__form">
        <div data-testid="qbr__company-select-field">
          <SelectFormField
            id="company"
            name="company"
            placeholder="Company"
            label="Company"
            onChange={handleSelectCompany}
            value={QBRValues.company}
            options={companySelectOptions}
          />
        </div>
        <div
          className={styles.projectsSelect}
          data-testid="qbr__projects-multiselect"
        >
          <Multiselect
            id="projects"
            name="projects"
            placeholder="Projects"
            value={QBRValues.projects}
            options={projectsMultiSelectOptions}
            onChange={handleSelectProjects}
            isDisabled={!QBRValues.company.value}
          />
          <Checkbox
            label="Include archived projects"
            onChange={() => handleSelectArchived(!isArchivedIncluded)}
            checked={isArchivedIncluded}
            disabled={!QBRValues.company.value}
            dataTestid="qbr__projects-include-archived-checkbox"
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            size="large"
            variant="primary"
            className={styles.submitButton}
            disabled={isSubmitButtonDisabled()}
            data-testid="qbr__submit-button"
            type="submit"
            onClick={handleSubmit}
            loading={loadingGenerateCSV || isPolling}
          >
            Download Report
          </Button>
          <Button
            size="large"
            variant="tertiary"
            data-testid="qbr__reset-button"
            type="reset"
            onClick={handleResetForm}
            disabled={loadingGenerateCSV || isPolling}
          >
            Reset
          </Button>
        </div>
      </section>
    </main>
  );
};

export default QBRData;
