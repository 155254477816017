import { Grid } from '@material-ui/core';
import { FC, useState } from 'react';
import Button from 'components/atoms/Button/Button';
import FileUploadFormField from 'components/molecules/FileUploadFormField/FileUploadFormField';
import CompanySelectFormField from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import SelectFormField from 'components/molecules/SelectFormField/SelectFormField';
import SoftSkillSelectFormField from 'components/molecules/SelectFormField/SoftSkillSelectFormField/SoftSkillSelectFormField';
import TextFormField from 'components/molecules/TextFormField/TextFormField';
import TextFormFieldWithActionIcon from 'components/organisms/TextFormFieldWithActionIcon/TextFormFieldWithActionIcon';
import { ModalTypes } from 'enums/modalTypes';
import { MeasurementConstructType } from 'enums/softSkill';
import { SoftSkillFragment } from 'generated/graphql';
import { openModal } from 'graphql/modals';
import validate from 'helpers/validate';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import SoftSkillFormHeader from './SoftSkillFormHeader/SoftSkillFormHeader';
import useSoftSkillFormStyle from './SoftSkillFormStyle';

export interface ISoftSkillForm {
  softSkill?: SoftSkillFragment;
  onSubmit: (values: ISoftSkillFieldFormValues) => void;
  isEditMode?: boolean;
}

export interface ISoftSkillFieldFormValues {
  name: string;
  category: string;
  companyId: string;
  softSkillId: string;
  softSkillIcon: File | null;
  measurementConstructType: TSelectFormFieldValue;
  description: string;
}

interface ISoftSkillFieldFormErrors {
  name: string;
  category: string;
  companyId: string;
  measurementConstructType: string;
}

const measurementConstructTypeOptions: {
  label: string;
  value: MeasurementConstructType;
}[] = [
  {
    label: 'Behavior',
    value: MeasurementConstructType.BEHAVIOR,
  },
  {
    label: 'Cognitive',
    value: MeasurementConstructType.COGNITIVE,
  },
  {
    label: 'Value',
    value: MeasurementConstructType.VALUE,
  },
  {
    label: 'Calculated on ss summary',
    value: MeasurementConstructType.CALCULATED_ON_SS_SUMMARY,
  },
  {
    label: 'None',
    value: MeasurementConstructType.NONE,
  },
];

const SoftSkillForm: FC<ISoftSkillForm> = ({
  softSkill,
  onSubmit,
  isEditMode = false,
}) => {
  const classes = useSoftSkillFormStyle();
  const [values, setValues] = useState<ISoftSkillFieldFormValues>({
    name: softSkill?.name ? softSkill.name : '',
    category: softSkill?.category ? softSkill.category : '',
    companyId: softSkill?.companyId ? `${softSkill.companyId}` : '',
    softSkillId: '',
    softSkillIcon: null,
    measurementConstructType: softSkill?.measurementConstructType || undefined,
    description: softSkill?.description || '',
  });
  const [errors, setErrors] = useState<ISoftSkillFieldFormErrors | undefined>();

  const handleChangeName = (value: string) => {
    setValues(prevValues => ({ ...prevValues, name: value }));
    if (errors?.name) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, name: '' } : undefined
      );
    }
  };

  const handleChangeCategory = (value: string) => {
    setValues(prevValues => ({ ...prevValues, category: value }));
    if (errors?.category) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, category: '' } : undefined
      );
    }
  };

  const handleChangeCompany = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, companyId: value as string }));
    if (errors?.companyId) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, companyId: '' } : undefined
      );
    }
  };

  const handleChangeSoftSkill = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, softSkillId: value as string }));
  };

  const handleChangeSoftSkillIcon = (value: File, name: string) => {
    setValues(prevValues => ({ ...prevValues, softSkillIcon: value as File }));
  };

  const handleClearSoftSkillIcon = () => {
    setValues(prevValues => ({ ...prevValues, softSkillIcon: null }));
  };

  const handleChangeMeasurementConstructType = (
    value: TSelectFormFieldValue
  ) => {
    setValues(prevValues => ({
      ...prevValues,
      measurementConstructType: value,
    }));
  };

  const handleChangeDescription = (value: TSelectFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      description: value as string,
    }));
  };

  const validateAddSoftSkillForm = () => {
    return validate(values, {
      name: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
      category: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
      companyId: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
      measurementConstructType: {
        nonEmptyString: {
          message: 'must be provided.',
        },
      },
    });
  };

  const handleSubmit = () => {
    const newErrors = validateAddSoftSkillForm();
    if (!newErrors) {
      onSubmit(values);
    } else {
      setErrors(newErrors);
    }
  };
  const handleOnClickDescription = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `softskil-description`,
      name: 'description',
      label: 'Description',
      initialValue: values.description,
      onChange: handleChangeDescription,
    });
  };

  const key = 'ssf';
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      className={classes.container}
    >
      <Grid item className={classes.titleWrapper}>
        <SoftSkillFormHeader softSkill={softSkill} isEditMode={isEditMode} />
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-name`}
          name="name"
          label="Name"
          onChange={handleChangeName}
          value={values.name}
          errorText={errors?.name}
          hasError={!!errors?.name}
        />
      </Grid>
      <Grid item>
        <TextFormField
          id={`${key}-category`}
          name="category"
          label="Category"
          onChange={handleChangeCategory}
          value={values.category}
          errorText={errors?.category}
          hasError={!!errors?.category}
        />
      </Grid>
      <Grid item>
        <CompanySelectFormField
          id={`${key}-company`}
          name="companyId"
          label="Company"
          value={values.companyId}
          onChange={handleChangeCompany}
          errorText={errors?.companyId}
          hasError={!!errors?.companyId}
        />
      </Grid>
      <Grid item>
        <SelectFormField
          id={`${key}-soft-skill-measurement-construct-type`}
          name="measurementConstructType"
          label="Measurement construct type"
          value={values.measurementConstructType}
          onChange={handleChangeMeasurementConstructType}
          options={measurementConstructTypeOptions}
          errorText={errors?.measurementConstructType}
          hasError={!!errors?.measurementConstructType}
        />
      </Grid>
      <Grid item>
        <TextFormFieldWithActionIcon
          id={`${key}-soft-skill-description`}
          name="description"
          label="Description"
          onChange={handleChangeDescription}
          value={values.description}
          onClick={handleOnClickDescription}
        />
      </Grid>
      <Grid item>
        <FileUploadFormField
          id={`${key}-soft-skill-icon`}
          name="softSkillIcon"
          label="Soft skill icon"
          value={values.softSkillIcon}
          onChange={handleChangeSoftSkillIcon}
          onClearClick={handleClearSoftSkillIcon}
        />
      </Grid>
      {!isEditMode ? (
        <Grid item>
          <SoftSkillSelectFormField
            id={`${key}-soft-skill`}
            name="softSkill"
            label="Softskill to copy from"
            value={values.softSkillId}
            onChange={handleChangeSoftSkill}
          />
        </Grid>
      ) : null}

      <Grid className={classes.buttonWrapper} item>
        <Button onClick={handleSubmit}>Save</Button>
      </Grid>
    </Grid>
  );
};

export default SoftSkillForm;
