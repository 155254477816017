import { endOfDay } from 'date-fns';
import { useFormik } from 'formik';
import {
  useCompaniesQuery,
  useResultValidatedSkillsLazyQuery,
} from 'generated/graphql';
import { getUtcBoundaryOfDay, sub } from 'helpers/date';
import { FC, useMemo, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import {
  Button,
  InlineNotification,
  NotificationType,
  Loader,
  TNotification,
  useNotification,
  Datepicker,
  TMultiselectOption,
  MultiselectFormField,
} from '@spotted-zebra-uk/ui-components';
import SkillsValidatedTable from './SkillsValidatedTable/SkillsValidatedTable';

export const ERROR_MSG =
  'Oops, something went wrong during the fetch of validated skills';

const NOTES = [
  'Data comes from assessments for completed candidates only',
  'The date refers to the date of candidate completion and not the date of a specific skill being validated',
  'Validated skills come from: personality, cognitive numerical, cognitive verbal, cognitive inductive',
  'If a candidate has had the same skill validated multiple times for this company in this time period, it is only counted once',
  'Limitation: ‘cognitive combined’ skill counts as one skill',
];
export const INITIAL_DATE_START = getUtcBoundaryOfDay(
  sub(new Date(), { months: 1 }),
  'start'
);
export const INITIAL_DATE_END = endOfDay(
  sub(new Date(), { days: 1 })
).toISOString();

const ITEMS_PER_PAGE = 10;

const initialFormValues: {
  companies: TMultiselectOption[];
  dateFrom: string;
  dateTo: string;
} = {
  companies: [],
  dateFrom: INITIAL_DATE_START,
  dateTo: INITIAL_DATE_END,
};

type TFormValues = {
  companies: TMultiselectOption[];
  dateFrom: string;
  dateTo: string;
};
const SkillsValidated: FC = () => {
  const { handleMsgType } = useNotification();
  const formik = useFormik<TFormValues>({
    initialValues: initialFormValues,
    onSubmit: values => handleFormSubmit(values),
  });
  const [selectedPage, setSelectedPage] = useState<number>(1);

  const companiesQueryResponse = useCompaniesQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${ERROR_MSG}: ${error.message}`,
      });
    },
  });
  const [
    fetchValidatedSkills,
    {
      data: validatedSkillsResponse,
      loading: validatedSkillsLoading,
      called: queryWasCalled,
    },
  ] = useResultValidatedSkillsLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${ERROR_MSG}: ${error.message}`,
      });
    },
  });

  const refetchCompanyValidatedSkills = async (
    pageNumber: number,
    formValues: TFormValues
  ) => {
    //get start of day for startDate and end of day for endDate
    const { companies, dateFrom, dateTo } = formValues;
    const companyIds = companies.map(company => parseInt(company.value ?? ''));
    const startOfDayStartDate =
      dateFrom && getUtcBoundaryOfDay(new Date(dateFrom), 'start');
    const endOfDayEndDate =
      dateTo && getUtcBoundaryOfDay(new Date(dateTo), 'end');
    await fetchValidatedSkills({
      variables: {
        companyIds: companyIds ? companyIds : [],
        dateFrom: startOfDayStartDate,
        dateTo: endOfDayEndDate,
        take: ITEMS_PER_PAGE,
        skip: (pageNumber - 1) * ITEMS_PER_PAGE,
      },
    });
  };

  const validatedSkills = validatedSkillsResponse?.ResultValidatedSkills;

  const handlePageChange = (_event: unknown, value: number) => {
    setSelectedPage(value);

    refetchCompanyValidatedSkills(value, formik.values);
  };

  const companyMultiselectOptions: TMultiselectOption[] = useMemo(
    () =>
      companiesQueryResponse.data?.Companies?.map(c => ({
        label: c.name,
        value: c.id.toString(),
      })) || [],
    [companiesQueryResponse.data?.Companies]
  );
  const handleFormSubmit = (formValues: TFormValues) => {
    refetchCompanyValidatedSkills(selectedPage, formValues);
  };

  const renderValidatedSkills = () => {
    if (validatedSkillsLoading) {
      return (
        <div data-testid="skills-validated-loader">
          <Loader variant="bubbles" />
        </div>
      );
    }

    if (!queryWasCalled) {
      return (
        <InlineNotification
          title={'Apply filters to see the summary'}
          notificationType={NotificationType.INFORMATION}
        />
      );
    }

    if (validatedSkills) {
      return (
        <>
          <SkillsValidatedTable
            validatedSkills={validatedSkills.resultValidatedSkills}
          />

          <div className="skills-validated__pages">
            <Pagination
              count={Math.ceil(validatedSkills?.total / ITEMS_PER_PAGE)}
              page={selectedPage}
              onChange={handlePageChange}
              size="large"
            />
          </div>
        </>
      );
    }

    return (
      <InlineNotification
        title={'Apply filters to see the summary'}
        notificationType={NotificationType.INFORMATION}
      />
    );
  };

  return (
    <div className="skills-validated">
      <form
        className="skills-validated__input-container"
        onSubmit={formik.handleSubmit}
      >
        <div
          className="skills-validated__input-item"
          data-testid="companies-dropdown"
        >
          <MultiselectFormField
            id="company-dropdown"
            name="companies"
            placeholder="Companies"
            label="Companies"
            onChange={(value: TMultiselectOption[]) =>
              formik.setFieldValue('companies', value)
            }
            options={companyMultiselectOptions}
            value={formik.values.companies}
          />
        </div>
        <div
          className="skills-validated__input-item"
          data-testid="input-date-from"
        >
          <Datepicker
            onDateChange={value => formik.setFieldValue('dateFrom', value)}
            date={formik.values.dateFrom}
            id="dateFrom"
            placeholder="Date from"
            name="dateFrom"
            label="Date from"
            inputType="input"
          />
        </div>
        <div
          className="skills-validated__input-item"
          data-testid="input-date-to"
        >
          <Datepicker
            onDateChange={value => formik.setFieldValue('dateTo', value)}
            date={formik.values.dateTo}
            id="dateTo"
            placeholder="Date to"
            name="dateTo"
            label="Date to"
            inputType="input"
          />
        </div>
        <div className="skills-validated__input-item">
          <Button
            type="submit"
            size="large"
            variant="primary"
            disabled={validatedSkillsLoading}
            data-testid="skills-validated-button"
          >
            View summary
          </Button>
        </div>
      </form>
      <div className="skills-validated__description">
        <b>Calculation notes:</b>
        <ul className="skills-validated__description__notes">
          {NOTES.map((note, index) => {
            return <li key={index}>{note}</li>;
          })}
        </ul>
      </div>
      {renderValidatedSkills()}
    </div>
  );
};

export default SkillsValidated;
