import { makeStyles } from '@material-ui/core/styles';

const useCreateCompanyStyle = makeStyles(() => ({
  container: {
    width: '100%',
    margin: 0,
    padding: 30,
  },
}));

export default useCreateCompanyStyle;
