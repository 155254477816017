import { FC } from 'react';
import UserNavigation from '../../navigation/UserNavigation/UserNavigation';
import UserSwitch from '../../navigation/UserNavigation/UserSwitch';
import useUserStyle from './UserStyle';

const User: FC = () => {
  const classes = useUserStyle();
  return (
    <>
      <UserNavigation />
      <div className={classes.container}>
        <UserSwitch />
      </div>
    </>
  );
};

export default User;
