import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { FC } from 'react';
import { UserFragment } from 'generated/graphql';
import CompanyEmployeesListItem from '../CompanyEmployeesListItem/CompanyEmployeesListItem';
import { Loader } from '@spotted-zebra-uk/ui-components';
import styles from './CompanyEmployeeListPresentation.module.scss';

interface ICompanyEmployeesListPresentational {
  employees: UserFragment[];
  companyId: number;
  handlePageChange: (_event: unknown, value: number) => void;
  selectedPage: number;
  totalEmployees: number;
  loading: boolean;
  employeesPerPage: number;
}

const CompanyEmployeesListPresentational: FC<ICompanyEmployeesListPresentational> = ({
  employees,
  companyId,
  handlePageChange,
  selectedPage,
  totalEmployees,
  loading,
  employeesPerPage,
}) => {
  const showPagination = totalEmployees > employeesPerPage || loading;
  const count = Math.ceil(totalEmployees / employeesPerPage);
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {showPagination && (
        <Pagination
          count={count}
          page={selectedPage}
          onChange={handlePageChange}
          size="large"
        />
      )}
      {loading && <Loader size="large" />}
      {!loading &&
        employees.map(employee => (
          <CompanyEmployeesListItem
            key={employee.email}
            companyId={companyId}
            companyEmployee={employee}
          />
        ))}
      {showPagination && (
        <Grid className={styles.pagination}>
          <Pagination
            count={count}
            page={selectedPage}
            onChange={handlePageChange}
            size="large"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CompanyEmployeesListPresentational;
