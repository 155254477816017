import { AUTH_CODE, AUTH_LOGIN_ROUTE } from 'constants/authentication';
import { useExchangeAuthCodeMutation } from 'generated/graphql';
import { setAccessToken, setRefreshToken } from 'helpers/auth';
import { FC, useEffect, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

const AuthenticationWrapper: FC = ({ children }) => {
  const { handleMsgType } = useNotification();
  const queryParams = new URLSearchParams(window.location.search);
  const authCode = queryParams.get(AUTH_CODE);

  const [loading, setLoading] = useState(true);

  const [exchangeAuthCode] = useExchangeAuthCodeMutation({
    variables: {
      authCode: authCode || '',
    },
    onCompleted: data => {
      const { accessToken, refreshToken } = data.ExchangeAuthCode;

      setAccessToken(accessToken);
      setRefreshToken(refreshToken);

      setLoading(false);
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: error?.message,
      });
      window.location.href = AUTH_LOGIN_ROUTE;
    },
  });

  useEffect(() => {
    if (authCode) {
      exchangeAuthCode();
    } else {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, []);

  return <>{loading ? null : children}</>;
};

export default AuthenticationWrapper;
