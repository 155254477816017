import TextFormField from 'components/molecules/TextFormField/TextFormField';
import DeleteApiKeyButton from 'components/organisms/DeleteApiKeyButton/DeleteApiKeyButton';
import { formatISO } from 'helpers/date';
import { FC } from 'react';
import { Grid } from '@material-ui/core';

interface IUserApiKeysListItem {
  apiKey: {
    id: number;
    key: string;
    createdAt: string;
  };
}

const UserApiKeysListItem: FC<IUserApiKeysListItem> = ({
  apiKey: { id, key, createdAt },
}) => {
  const formKey = 'aklif';
  return (
    <Grid style={{ padding: '8px 0' }} container item>
      <Grid item xs={5} style={{ paddingRight: '8px' }}>
        <TextFormField
          value={key}
          isDisabled
          id={`${formKey}-${id}`}
          name="Api key"
          label="Api key"
        />
      </Grid>
      <Grid item xs={4} style={{ paddingRight: '8px' }}>
        <TextFormField
          value={formatISO(createdAt)}
          isDisabled
          id={`${formKey}-${id}-createdAt`}
          name="Created at"
          label="Created at"
        />
      </Grid>
      <Grid
        xs={3}
        item
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <DeleteApiKeyButton apiKey={key} apiKeyId={id} />
      </Grid>
    </Grid>
  );
};

export default UserApiKeysListItem;
