import { makeStyles } from '@material-ui/core/styles';

const useCircularLoaderStyle = makeStyles(() => ({
  loaderWrapper: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useCircularLoaderStyle;
