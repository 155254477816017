import { makeStyles } from '@material-ui/core/styles';

const useEditTraitModalStyle = makeStyles({
  modalPaper: {
    minWidth: 800,
    minHeight: 300,
  },
  container: {
    padding: 30,
  },
});

export default useEditTraitModalStyle;
