import { Divider } from 'components/atoms/Divider';
import { contentRoutes } from 'constants/navigation';
import { Form, Formik, useField } from 'formik';
import {
  SkillCategoryFindManyQuery,
  useSkillCategoryFindManyQuery,
  useTechnicalSkillCreateOneMutation,
  useTechnicalSkillIconUploadMutation,
} from 'generated/graphql';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
  TNotification,
  useNotification,
  Button,
  Loader,
  TSelectOption,
  FormikFileInput,
  FormikSelectFormField,
  FormikTextInputField,
} from '@spotted-zebra-uk/ui-components';
import styles from './styles.module.scss';

type TFormValues = {
  skillIcon: File | undefined;
  name: string;
  category: number | undefined;
};

const initialFormValues: TFormValues = {
  skillIcon: undefined,
  name: '',
  category: undefined,
};

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Please fill out the skill name').nullable(),
});

const AddTechnicalSkillsForm = () => {
  const { handleMsgType } = useNotification();

  const { data: allCategoriesResponse } = useSkillCategoryFindManyQuery();

  const [
    createNewTechnicalSkillMutation,
    { loading: createLoading },
  ] = useTechnicalSkillCreateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error.message });
    },
  });

  const [
    uploadTechnicalSkillIconMutation,
  ] = useTechnicalSkillIconUploadMutation({
    context: {
      isUpload: true,
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error.message });
    },
  });

  const availableSkillCategories = getCategoriesForSelect(
    allCategoriesResponse?.SkillCategoryFindMany
  );

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleFormSubmit}
    >
      {({ dirty, errors }) => (
        <div className={styles.formContainer}>
          <Form>
            <FormikTextInputField
              id="name"
              name="name"
              placeholder="Name"
              data-testid="name"
              useFormikField={useField}
            />

            <div className={styles.formInlineFields}>
              <FormikSelectFormField
                placeholder="Category (optional)"
                id="categorySelect"
                name="categorySelect"
                options={availableSkillCategories}
                selectProps={{ 'data-testid': 'categorySelect' }}
                useFormikField={useField}
              />
              <FormikFileInput
                id="skillIcon"
                name="skillIcon"
                placeholder="Skill icon (optional)"
                data-testid="skillIcon"
                useFormikField={useField}
              />
            </div>
            <Divider />
            <div className={styles.formButtons}>
              <Link to={contentRoutes.technicalSkills.url()} tabIndex={-1}>
                <Button variant="secondary" type="button">
                  Cancel
                </Button>
              </Link>
              <Button
                disabled={!dirty || Object.keys(errors).length !== 0}
                variant="primary"
                type="submit"
              >
                {createLoading ? <Loader color="white" /> : 'Save'}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );

  async function handleFormSubmit(
    formValues: TFormValues,
    { resetForm }: { resetForm: Function }
  ) {
    const responseCreateTechnicalSkill = await createNewTechnicalSkillMutation({
      variables: {
        name: formValues.name,
        skillCategoryId: formValues.category,
      },
    });

    const technicalSkillId =
      responseCreateTechnicalSkill.data?.TechnicalSkillCreateOne.id;

    if (!technicalSkillId) {
      handleMsgType({
        type: TNotification.error,
        message: `There was an issue while creating a technical skill`,
      });
      return;
    }

    if (formValues.skillIcon) {
      await uploadTechnicalSkillIconMutation({
        variables: {
          file: formValues.skillIcon,
          id: technicalSkillId,
        },
      });
    }

    handleMsgType({
      type: TNotification.success,
      message: `Technical skill: ${formValues.name} successfully created!`,
    });

    resetForm({
      ...initialFormValues,
    });
  }
};

function getCategoriesForSelect(
  data: SkillCategoryFindManyQuery['SkillCategoryFindMany'] | undefined
): TSelectOption[] {
  if (!data) return [];

  const categories = data.map(
    (skillCategory: { id: number; name: string }) => ({
      value: `${skillCategory.id}`,
      label: skillCategory.name,
    })
  );

  return categories;
}

export default AddTechnicalSkillsForm;
