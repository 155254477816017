import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  InterviewQuestionRoleLevel,
  useInterviewQuestionRoleLevelContentFindManyAndCountLazyQuery,
} from '../../../generated/graphql';
import { StaticContentContext } from '../StaticContentContext/StaticContentContext';
import InterviewQuestionContentHeader from './InterviewQuestionContentHeader/InterviewQuestionContentHeader';
import InterviewQuestionContentList from './InterviewQuestionContentList/InterviewQuestionContentList';
import useInterviewQuestionContentStyle from './InterviewQuestionContentStyle';

export interface IInterviewQuestionContent {}

const InterviewQuestionContent: FC<IInterviewQuestionContent> = () => {
  const { filters } = useContext(StaticContentContext);
  const classes = useInterviewQuestionContentStyle();
  const [
    interviewQuestionContentQuery,
    interviewQuestionContentQueryResponse,
  ] = useInterviewQuestionRoleLevelContentFindManyAndCountLazyQuery();

  useEffect(() => {
    const {
      softSkills,
      traits,
      roleLevels,
      classifications,
      page,
      take,
      sortBy,
    } = filters;
    interviewQuestionContentQuery({
      variables: {
        softSkillIds: softSkills,
        traitIds: traits,
        roleLevels,
        classifications,
        page: page ? page : 1,
        take: take ? take : 50,
        order: sortBy ? sortBy : [],
      },
    });
  }, [filters, interviewQuestionContentQuery]);

  if (interviewQuestionContentQueryResponse.error) {
    return <Typography className={classes.container}>Error</Typography>;
  }

  if (interviewQuestionContentQueryResponse.loading) {
    return (
      <Typography className={classes.container}>Loading content...</Typography>
    );
  }

  if (interviewQuestionContentQueryResponse.data) {
    const interviewQuestionContent =
      interviewQuestionContentQueryResponse.data.interviewQuestionContent;
    const count = interviewQuestionContent.count as number;

    if (interviewQuestionContent.interviewQuestionContent) {
      return (
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={5}
          className={classes.container}
        >
          <InterviewQuestionContentHeader />
          <InterviewQuestionContentList
            interviewQuestionContent={
              interviewQuestionContent.interviewQuestionContent as InterviewQuestionRoleLevel[]
            }
            count={count}
          />
        </Grid>
      );
    }
  }

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
      className={classes.container}
    >
      <InterviewQuestionContentHeader />
      <Typography>No content to display</Typography>
    </Grid>
  );
};

export default InterviewQuestionContent;
