import { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import CircularLoader from '../../../../components/molecules/CircularLoader/CircularLoader';
import {
  FormManagerType,
  FormType,
  useGetFormReportCsvLazyQuery,
} from '../../../../generated/graphql';
import { IFormManagerValues } from '../FormManager';
import useFormManagerDownloadCSVStyle from './FormManagerDownloadCSVStyle';

export interface IFormManagerDownloadCSV {
  values?: IFormManagerValues;
}

const FormManagerDownloadCSV: FC<IFormManagerDownloadCSV> = ({ values }) => {
  const { handleMsgType } = useNotification();

  const classes = useFormManagerDownloadCSVStyle();
  const [
    getFormReportCSV,
    getFormReportCSVResponse,
  ] = useGetFormReportCsvLazyQuery({
    fetchPolicy: 'no-cache',
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [hasCSVBeenDownloaded, setHasCSVBeenDownloaded] = useState(false);

  const generateCSV = () => {
    if (
      values?.formManagerType &&
      values?.formType &&
      values?.formOwner &&
      values?.formAssociate
    ) {
      getFormReportCSV({
        variables: {
          formManagerType: values?.formManagerType as FormManagerType,
          formType: values?.formType as FormType,
          formOwnerId: +values?.formOwner,
          associatedId: +values?.formAssociate,
        },
      });

      setHasCSVBeenDownloaded(true);
    }
  };

  // If the values change, then the data for those values has not been downloaded
  useEffect(() => {
    setHasCSVBeenDownloaded(false);
  }, [values]);
  return (
    <Grid item container direction="row" justifyContent="center" spacing={2}>
      <Grid item xs={1}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={generateCSV}
        >
          Generate CSV
        </Button>
      </Grid>
      <Grid item xs={1} className={classes.loaderWrapper}>
        {getFormReportCSVResponse.loading ? (
          <CircularLoader customStyle={classes.loaderWrapper} />
        ) : null}
      </Grid>
      <Grid item xs={1}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={
            !(
              getFormReportCSVResponse.data?.getFormReportCSV &&
              hasCSVBeenDownloaded
            )
          }
          href={getFormReportCSVResponse.data?.getFormReportCSV || ''}
        >
          Download CSV
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormManagerDownloadCSV;
