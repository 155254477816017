import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { appRoutes, usersRoutes } from '../../constants/navigation';
import RetriveTpCandidates from '../../views/User/RetrieveTpCandidates/RetrieveTpCandidates';
import UserDetails from '../../views/User/UserDetails/UserDetails';
import UserMain from '../../views/User/UserMain/UserMain';

interface IUserSwitch {}

const UserSwitch: FC<IUserSwitch> = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.users.path}>
        <UserMain />
      </Route>
      <Route exact path={usersRoutes.retrieveTpDetails.path}>
        <RetriveTpCandidates />
      </Route>
      <Route path={usersRoutes.user.path}>
        <UserDetails />
      </Route>
    </Switch>
  );
};

export default UserSwitch;
