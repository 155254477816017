import { makeStyles } from '@material-ui/core/styles';

const useConnectedTraitsStyle = makeStyles({
  connectedTraitsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 300,
    maxWidth: 800,
  },
});

export default useConnectedTraitsStyle;
