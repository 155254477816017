import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import { Company, useUpdateCompanyMutation } from 'generated/graphql';
import { FC } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import EditCompanyNameForm from './EditCompanyNameForm/EditCompanyNameForm';

interface IEditCompanyName {
  company: Company;
}

const EditCompanyName: FC<IEditCompanyName> = ({ company }) => {
  const { handleMsgType } = useNotification();

  const [
    editCompanyMutation,
    editCompanyMutationResponse,
  ] = useUpdateCompanyMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleEditCompanyMutation = (companyName: string) => {
    editCompanyMutation({
      variables: {
        name: companyName,
        id: company.id,
      },
    });
  };

  if (editCompanyMutationResponse.loading) {
    return <CircularLoader />;
  }

  return (
    <EditCompanyNameForm
      onSubmit={handleEditCompanyMutation}
      company={company}
    />
  );
};

export default EditCompanyName;
