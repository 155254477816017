import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import { openModal } from 'graphql/modals';
import { useState } from 'react';
import { RadioButton, Tooltip } from '@spotted-zebra-uk/ui-components';
import styles from './ReportsAccess.module.scss';

const TOOLTIP_CONTENT =
  'How reports will be accessible via project team emails by default when candidates complete their assessments. Note: for multi-match projects, reports will only be accessible through links.';

const dataTestId = 'reports-access__radio-button';

export enum ReportsAccessTypes {
  LINKS = 'Links',
  ATTACHMENTS = 'Attachments',
}

const ReportsAccess = ({
  linksEnabled,
  companyName,
  companyId,
}: {
  linksEnabled: boolean;
  companyName: string;
  companyId: number;
}) => {
  const [areLinksEnabled, setAreLinksEnabled] = useState(linksEnabled);

  const handleChange = (value: boolean) => {
    setAreLinksEnabled(value);
  };

  const handleOpenModal = () => {
    openModal(ModalTypes.REPORTS_ACCESS, {
      id: companyId,
      name: companyName,
      accessType: areLinksEnabled
        ? ReportsAccessTypes.ATTACHMENTS
        : ReportsAccessTypes.LINKS,
      onSubmit: handleChange,
    });
  };

  return (
    <div className={styles.container} data-testid={`${dataTestId}-container`}>
      <div className={styles.label}>
        Reports accessible via
        <Tooltip content={TOOLTIP_CONTENT}>
          <Icon icon="info" id="tooltip-trigger" />
        </Tooltip>
      </div>
      <form className={styles.radioGroup}>
        <RadioButton
          name={ReportsAccessTypes.LINKS}
          id={ReportsAccessTypes.LINKS}
          checked={areLinksEnabled}
          data-testid={`${dataTestId}-${ReportsAccessTypes.LINKS}`}
          onChange={handleOpenModal}
          value={ReportsAccessTypes.LINKS}
        >
          {ReportsAccessTypes.LINKS}
        </RadioButton>
        <RadioButton
          name={ReportsAccessTypes.ATTACHMENTS}
          id={ReportsAccessTypes.ATTACHMENTS}
          checked={!areLinksEnabled}
          data-testid={`${dataTestId}-${ReportsAccessTypes.ATTACHMENTS}`}
          onChange={handleOpenModal}
          value={ReportsAccessTypes.ATTACHMENTS}
        >
          {ReportsAccessTypes.ATTACHMENTS}
        </RadioButton>
      </form>
    </div>
  );
};

export default ReportsAccess;
