import { FC, useCallback, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Snackbar from '../../../components/atoms/Snackbar/Snackbar';
import Modal from '../../../components/molecules/Modal/Modal';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  CompanyFindUsersDocument,
  useCompanyResendInviteMutation,
  useUserDeleteMutation,
  useUserUpdateMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import validate from '../../../helpers/validate';
import { TTextFromFieldValue } from '../../../interfaces/forms/form';
import { TEditUserModalProps } from '../../../interfaces/modal';
import { ISnackBar } from '../../../interfaces/snackBar';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';

interface IAddCompanyEmployeeFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface IAddCompanyEmployeeFormErrors {
  firstName: string;
  lastName: string;
  email: string;
  externalId: string;
}
const EditUserModal: FC<TEditUserModalProps> = ({ userInfo, companyId }) => {
  const { handleMsgType } = useNotification();

  const classes = useReportModalStyle();
  const initialValues = useCallback(
    () => ({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
    }),
    //eslint-disable-next-line
    []
  );
  const [values, setValues] = useState<IAddCompanyEmployeeFormValues>(
    initialValues
  );
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const [errors, setErrors] = useState<IAddCompanyEmployeeFormErrors>();

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_USER_MODAL);
  };

  const handleChange = (value: TTextFromFieldValue, name: string) => {
    setValues(prevValues => ({ ...prevValues, [name]: value }));
    if (errors) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, [name]: '' } : undefined
      );
    }
  };

  const [editUserMutation, { error }] = useUserUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'employee created successfully' });
      handleCloseModal();
    },
  });

  const [companyResendInvite] = useCompanyResendInviteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'employee invited successfully' });
      handleCloseModal();
    },
  });

  const [userDeleteMutation] = useUserDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'user deleted successfully' });
      handleCloseModal();
    },
    refetchQueries: [
      {
        query: CompanyFindUsersDocument,
        variables: {
          id: +companyId,
        },
      },
    ],
  });

  const handleDelete = () => {
    userDeleteMutation({
      variables: {
        id: userInfo.id,
      },
    });
  };

  const validateAddCompanyEmployeeForm = () => {
    return validate(values, {
      firstName: {
        nonEmptyString: true,
      },
      lastName: {
        nonEmptyString: true,
      },
      email: {
        email: {
          message: 'Invalid email.',
        },
      },
    });
  };
  const handleSave = async () => {
    const newErrors = validateAddCompanyEmployeeForm();
    if (!newErrors) {
      const result = await editUserMutation({
        variables: {
          id: userInfo.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
      });
      if (result) {
        resetForm();
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleResendInvite = () => {
    if (companyId)
      companyResendInvite({
        variables: {
          userId: userInfo.id,
          companyId: +companyId,
        },
      });
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors(undefined);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            Edit User
          </Typography>
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`first-name`}
            name="firstName"
            label="Given name"
            onChange={handleChange}
            value={values.firstName}
            hasError={!!errors?.firstName}
            errorText={errors?.firstName ? 'This is a required field.' : ''}
          />
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`last-name`}
            name="lastName"
            label="Family name"
            onChange={handleChange}
            value={values.lastName}
            hasError={!!errors?.lastName}
            errorText={errors?.lastName ? 'This is a required field.' : ''}
          />
        </Grid>
        <Grid item xs>
          <TextFormField
            id={`email`}
            name="email"
            label="Email"
            onChange={handleChange}
            value={values.email}
            hasError={!!errors?.email}
            errorText={errors?.email}
          />
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            // className={classes.button}
            onClick={handleDelete}
            variant="contained"
            color="primary"
          >
            Delete
          </Button>
          <Button
            // className={classes.button}
            onClick={handleResendInvite}
            variant="contained"
            color="primary"
          >
            Resend Invite
          </Button>
        </Grid>
        {error?.message && (
          <p style={{ color: 'red', marginBottom: '5px' }}>An error occured</p>
        )}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="default"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button color="primary" className={classes.btn} onClick={handleSave}>
            save
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </Modal>
  );
};

export default EditUserModal;
