import { FC } from 'react';
import SidebarNavigation from '../../components/organisms/SidebarNavigation/SidebarNavigation';
import { interviewGuidesSidebarConfig } from './InterviewGuidesNavigation.constants';

interface IInterviewGuidesNavigation {}

const InterviewGuidesNavigation: FC<IInterviewGuidesNavigation> = () => {
  return <SidebarNavigation routes={interviewGuidesSidebarConfig} />;
};

export default InterviewGuidesNavigation;
