import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../../../../../components/atoms/Button/Button';
import SwitchSelectFormField from '../../../../../../components/molecules/SwitchSelectFormField/SwitchSelectFormField';
import { ModalTypes } from '../../../../../../enums/modalTypes';
import { openModal } from '../../../../../../graphql/modals';
import { ICompanyRequirementsFormBuilderFormFieldValue } from '../../../../../../interfaces/forms/companyRequirementsFormBuilderForm';
import useCandidateInformationFieldRequirementsFormItemStyle from './CandidateInformationFieldRequirementsFormItemStyle';

interface ICompanyRequirementsFormBuilderFormItem {
  companyId: number;
  formFieldValue: ICompanyRequirementsFormBuilderFormFieldValue;
  orderIndex: number;
  formId: string;
  isUsed: boolean;
  onChangeFieldRequirement: (value: boolean, fieldId: number) => void;
}

const selectElementLabels = { 1: 'Optional', 0: 'Required' };

const CompanyRequirementsFormBuilderFormItem: FC<ICompanyRequirementsFormBuilderFormItem> = ({
  formFieldValue,
  orderIndex,
  formId,
  onChangeFieldRequirement,
  companyId,
  isUsed,
}) => {
  const classes = useCandidateInformationFieldRequirementsFormItemStyle();
  const { id, name, isOptional, isOwnedByCompany } = formFieldValue;

  const handleChangeFieldRequirement = (value: boolean, name: string) => {
    onChangeFieldRequirement(value, id);
  };

  const handleOpenCreateCandidateInfoFieldModal = () => {
    openModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL, {
      candidateInfoFieldId: id,
      companyId,
    });
  };

  return (
    <Draggable draggableId={`${id}`} index={orderIndex}>
      {dragableProvided => (
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.container}
          ref={dragableProvided.innerRef}
          {...dragableProvided.draggableProps}
          {...dragableProvided.dragHandleProps}
        >
          <Grid item xs={4}>
            <Typography>
              {isUsed ? `${orderIndex + 1} ` : ''}
              {name}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {isUsed && (
              <SwitchSelectFormField
                id={`${formId}-${id}-is-optional`}
                name="isOptional"
                value={isOptional}
                onChange={handleChangeFieldRequirement}
                labels={selectElementLabels}
                hasNullValue={false}
              />
            )}
          </Grid>
          <Grid
            item
            xs={3}
            className={classes.ownedBySelectedConpanyFieldWrapper}
          >
            {isOwnedByCompany && (
              <Typography>Owned by selected company</Typography>
            )}
          </Grid>
          <Grid item xs={2} className={classes.buttonWrapper}>
            <Button onClick={handleOpenCreateCandidateInfoFieldModal}>
              Edit
            </Button>
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
};

export default CompanyRequirementsFormBuilderFormItem;
