import { useParams } from 'react-router-dom';
import {
  Report,
  ReportConfig,
  useReportQuery,
  useWidgetsQuery,
  WidgetConfig,
} from '../../generated/graphql';
import { IReportWidget } from '../../interfaces/report';
import { TWidget, TWidgetOptions } from '../../interfaces/widget';
import ReportMain from './ReportMain/ReportMain';

export interface IReportPath {
  reportId: string;
}

const IReport = () => {
  const { reportId } = useParams<IReportPath>();
  const parsedReportId: number = parseInt(reportId, 10);

  const widgetsQueryResponse = useWidgetsQuery();

  const getReportByIdResponse = useReportQuery({
    variables: { id: parsedReportId },
  });
  const reportConfigs = getReportByIdResponse?.data?.report
    ?.reportConfigs as ReportConfig[];
  const widgetResponse = widgetsQueryResponse?.data?.Widgets as TWidget[];

  const newReport =
    reportConfigs &&
    (reportConfigs
      ?.map((item: ReportConfig, index: number) => ({
        dndId: index,
        reportConfigId: item.id,
        id: item?.widget.id,
        position: item && item?.position,
        usedWidget: true,
        newPageBefore: item.newPageBefore,
        name: item?.widget.name,
        type: item.widget.type,
        widgetOptions:
          item &&
          item?.widgetConfigs?.map((option: WidgetConfig) => ({
            defaultValue: option?.widgetOption?.defaultValue,
            fieldType: option?.widgetOption?.fieldType,
            question: option?.widgetOption?.question,
            id: option && option?.widgetOption?.id,
            isArchived: option?.widgetOption?.isArchived,
            settings: option.settings,
          })),
      }))
      .sort((a, b) => (a?.position < b?.position ? -1 : 1)) as IReportWidget[]);

  let newReportData: IReportWidget[] | undefined;
  if (newReport !== undefined) {
    newReportData = newReport?.map(item => ({
      reportConfigId: item.reportConfigId,
      id: item.id,
      dndId: item.dndId,
      newPageBefore: item.newPageBefore,
      position: item?.position,
      name: item.name,
      type: item.type,
      usedWidget: item.usedWidget,
      widgetOptions:
        item &&
        item?.widgetOptions?.map(option => ({
          defaultValue: option && option?.defaultValue,
          fieldType: option.fieldType,
          question: option && option?.question,
          id: option && option?.id,
          isArchived: option.isArchived,
          settings: option.settings,
        })),
    }));
  }

  let availableWidgetList: IReportWidget[] | undefined;
  if (widgetResponse !== undefined) {
    availableWidgetList = widgetResponse.map((item: TWidget, index) => ({
      dndId: newReport && newReport?.length + index,
      reportConfigId: item?.reportConfigs[0]?.id,
      id: item.id,
      usedWidget: false,
      position: newReport && newReport?.length + 1,
      newPageBefore: item?.reportConfigs[0]?.newPageBefore,
      name: item.name,
      type: item.type,
      widgetOptions: item?.widgetOptions?.map((option: TWidgetOptions) => ({
        defaultValue: option.defaultValue,
        fieldType: option.fieldType,
        question: option.question,
        id: option.id,
        isArchived: option.isArchived,
        settings: option.settings,
      })),
    }));
  }

  return (
    <ReportMain
      report={getReportByIdResponse?.data?.report as Report}
      reportId={parsedReportId}
      usedWidget={newReportData}
      availableWidget={availableWidgetList}
    />
  );
};

export default IReport;
