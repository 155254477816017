import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { companyRoutes } from '../../../constants/navigation';
import { CompaniesQuery } from '../../../generated/graphql';
import useCompaniesStyle from '../CompaniesStyle';

type TCompanies = CompaniesQuery['Companies'];

interface ICompaniesList {
  companies: TCompanies;
}

const CompaniesList: FC<ICompaniesList> = ({ companies }) => {
  const classes = useCompaniesStyle();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={4}
      className={classes.container}
    >
      <Grid item>
        <Link to={companyRoutes.createCompany.url()}>
          <Paper elevation={3} className={classes.createCompanyPaper}>
            <AddCircleIcon color="primary" fontSize="large" />
          </Paper>
        </Link>
      </Grid>
      {companies?.map(company => {
        return (
          <Grid key={company.id} item>
            <Link to={companyRoutes.editCompany.url(company.id)}>
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.companyLogoWrapper}>
                  {company?.logoUrl && (
                    <img
                      className={classes.companyLogo}
                      src={company.logoUrl}
                      alt="company logo test"
                    />
                  )}
                </div>
                <div className={classes.companyName}>{company.name}</div>
              </Paper>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CompaniesList;
