import { makeStyles } from '@material-ui/core/styles';

const useRetrieveTpCandidatesStyle = makeStyles(() => ({
  container: {
    margin: 0,
    width: '100%',
    padding: '30px 30px 30px 240px',
  },
}));

export default useRetrieveTpCandidatesStyle;
