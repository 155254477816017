import React, { FC, useState } from 'react';
import Button from '../../../../components/atoms/Button/Button';
import TextFormField from '../../../../components/molecules/TextFormField/TextFormField';
import useUserStyle from '../../UserStyle';

interface IFreeTextSearchUsers {
  setSearchText: (searchText: string) => void;
}

const FreeTextSearchUsers: FC<IFreeTextSearchUsers> = ({ setSearchText }) => {
  const [search, setSearch] = useState<string>('');
  const classes = useUserStyle();

  const handleChange = (value: string) => {
    setSearch(value);
  };

  const handleSearch = () => {
    setSearchText(search);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: 600,
        marginBottom: 30,
        marginTop: 30,
      }}
    >
      <TextFormField
        id="id"
        name="id"
        label="free text search"
        value={search}
        onChange={handleChange}
        className={classes.mr10}
      />
      <Button onClick={handleSearch}>Search</Button>
    </div>
  );
};

export default FreeTextSearchUsers;
