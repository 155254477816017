import { FC, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import CircularLoader from '../../../components/molecules/CircularLoader/CircularLoader';
import { useTraitsLazyQuery } from '../../../generated/graphql';
import TraitsHeader from './TraitsHeader/TraitsHeader';
import TraitsList from './TraitsList/TraitsList';
import useTraitsStyle from './TraitsStyle';

export interface ITraits {}

const Traits: FC<ITraits> = () => {
  const classes = useTraitsStyle();
  const [isArchivedVisible, setIsArchivedVisible] = useState(false);

  const [traitsQuery, traitsQueryResponse] = useTraitsLazyQuery();

  useEffect(() => {
    traitsQuery({
      variables: {
        isArchived: isArchivedVisible ? undefined : false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchivedVisible]);

  if (traitsQueryResponse.loading) {
    return <CircularLoader />;
  }

  if (traitsQueryResponse.data) {
    const traits = traitsQueryResponse.data.Traits;
    return (
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        className={classes.container}
      >
        <TraitsHeader
          isArchivedVisible={isArchivedVisible}
          setIsArchivedVisible={setIsArchivedVisible}
        />
        <TraitsList traits={traits || []} />
      </Grid>
    );
  }

  return null;
};

export default Traits;
