import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { appRoutes, reportsRoutes } from '../../constants/navigation';
import Report from '../../views/Report/Report';
import Reports from '../../views/Reports/ReportList/ReportList';
import Widget from '../../views/Widget/Widget';
import Widgets from '../../views/Widgets/Widgets';

interface IReportsSwitch {}

const ReportsSwitch: FC<IReportsSwitch> = () => {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.reports.path}
        render={() => <Redirect to={reportsRoutes.reports.path} />}
      />
      <Route path={reportsRoutes.report.path}>
        <Report />
      </Route>
      <Route path={reportsRoutes.reports.path}>
        <Reports />
      </Route>
      <Route path={reportsRoutes.widget.path}>
        <Widget />
      </Route>
      <Route path={reportsRoutes.widgets.path}>
        <Widgets />
      </Route>
    </Switch>
  );
};

export default ReportsSwitch;
