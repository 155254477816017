import { getAccessToken } from 'helpers/auth';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuthAppRedirect } from '@spotted-zebra-uk/ui-components';
import { useEffect } from 'react';

type LocationState = {
  from: Location;
};

export const Authentication = () => {
  const authToken = getAccessToken();

  if (authToken) {
    return <Redirect to="/" />;
  }

  return <AuthRedirectHandler />;
};

const AuthRedirectHandler = () => {
  const { i18n } = useTranslation();
  const { state, search, pathname } = useLocation<LocationState | undefined>();

  const authRedirectUrl = state?.from?.pathname
    ? `${state.from.pathname}${state.from.search || ''}`
    : '/';

  const redirectToAuthApp = useAuthAppRedirect({
    authAppBaseUrl: process.env.REACT_APP_AUTH_URL || '',
    pathname: pathname,
    search:
      authRedirectUrl?.includes('/inv') && state
        ? state.from.search.replace('?', '')
        : search, //Add search from state if it is an indirect invitation
    clientType: 'admin',
  });

  useEffect(() => {
    redirectToAuthApp(i18n.language, authRedirectUrl);
    //eslint-disable-next-line
  }, []);

  return null;
};
