import './styles.scss';
import Icon from 'components/atoms/Icon';
import { appRoutes } from 'constants/navigation';
import { ModalTypes } from 'enums/modalTypes';
import {
  CompanyMaybeUserEmployeeFindManyDocument,
  ProjectRequestFragmentDoc,
  ProjectRequestStatus,
  ProjectRequestUserType,
  useCompaniesQuery,
  useMeQuery,
  useProjectRequestCreateOneMutation,
  useProjectRequestUserCreateManyMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { FC, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationModalProps } from 'views/ModalView/NotificationModal';
import { Button, NotificationModalType } from '@spotted-zebra-uk/ui-components';
import ProjectRequestCreateForm from './ProjectRequestCreateForm';
import { ProjectRequestCreateFormValues } from './types';

const ProjectRequestCreateFormPure = memo(ProjectRequestCreateForm);

const ProjectRequestCreate: FC = () => {
  const history = useHistory();
  const userQuery = useMeQuery();
  const companiesQuery = useCompaniesQuery();
  const [projectRequestCreateOneMutation] = useProjectRequestCreateOneMutation({
    update: (cache, { data }) => {
      if (data?.ProjectRequestCreateOne) {
        const newProjectRequestRef = cache.writeFragment({
          id: `ProjectRequestModel:${data.ProjectRequestCreateOne.id}`,
          fragment: ProjectRequestFragmentDoc,
          data: data.ProjectRequestCreateOne,
          fragmentName: 'ProjectRequest',
        });

        // Adds new project request to the list on the '/project-requests' page.
        cache.modify({
          fields: {
            ProjectRequestFindMany(existingProjectRequests = []) {
              return [...existingProjectRequests, newProjectRequestRef];
            },
          },
        });
      }
    },
  });
  const [
    projectRequestUserCreateManyMutation,
  ] = useProjectRequestUserCreateManyMutation();

  const handleProjectRequestsButtonClick = () => {
    history.push(appRoutes.projectRequests.url());
  };

  const createProjectRequest = useCallback(
    async (values: ProjectRequestCreateFormValues) => {
      const companyId = values.company.value;
      const creatorId = userQuery.data?.me?.id;

      if (companyId && creatorId) {
        try {
          const projectRequestCreateOneMutationRes = await projectRequestCreateOneMutation(
            {
              variables: {
                status: ProjectRequestStatus.RequiresProject,
                projectName: values.projectName,
                companyId: parseInt(companyId as string),
                creatorId,
              },
            }
          );

          if (!projectRequestCreateOneMutationRes.data) {
            return;
          }

          const dto = values.contacts
            .filter(contact => contact.employee.value && contact.role.value)
            .map(contact => ({
              projectRequestId: projectRequestCreateOneMutationRes.data
                ?.ProjectRequestCreateOne.id as number,
              // TODO: Fix select element types
              email: contact.employee.value as string,
              type: contact.role.value as ProjectRequestUserType,
            }));

          if (dto.length) {
            await projectRequestUserCreateManyMutation({
              variables: {
                dto,
              },
              // This is required because projectRequestUserCreateManyMutation on the backend
              // creates users for provided employees emails.
              // Refetch of this query gets userIds for CompanyEmployees.
              // Check [src/views/ProjectRequests/ProjectRequestCreate/ContactsSubForm/index.tsx]
              refetchQueries: [
                {
                  query: CompanyMaybeUserEmployeeFindManyDocument,
                  variables: {
                    // TODO: Fix types in Select component.
                    companyId: parseInt(companyId as string),
                  },
                },
              ],
            });
          }

          const modalProps: NotificationModalProps = {
            onOkClick: () => {
              closeModal(ModalTypes.NOTIFICATION_MODAL);
              history.push(appRoutes.projectRequests.path);
            },
            message: 'Your Project Request has been successfully created ',
            type: NotificationModalType.INFO,
          };
          openModal(ModalTypes.NOTIFICATION_MODAL, modalProps);
        } catch (error) {
          console.log(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, userQuery.data?.me?.id]
  );

  const companiesOptions = useMemo(
    () =>
      companiesQuery.data?.Companies?.map(company => ({
        label: company.name,
        value: `${company.id}`,
      })).sort((companyA, companyB) =>
        companyA.label > companyB.label ? 1 : -1
      ) || [],
    [companiesQuery.data]
  );

  return (
    <div className="project-request-create">
      <div className="project-request-create__header">
        <Button
          onClick={handleProjectRequestsButtonClick}
          size="small"
          variant="text"
          className="project-request-create__back-button"
          leftIcon={
            <Icon
              icon="left_arrow"
              className="project-request-create__back-button-icon"
            />
          }
        >
          Project Requests
        </Button>
      </div>
      <div className="project-request-create__content">
        <ProjectRequestCreateFormPure
          companiesOptions={companiesOptions}
          onSubmit={createProjectRequest}
        />
      </div>
    </div>
  );
};

export default ProjectRequestCreate;
