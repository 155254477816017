import './styles.scss';
import { projectsRoutes } from 'constants/navigation';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateProjectView from 'views/Project/ProjectCreate';
import EditProjectView from 'views/Project/ProjectEdit';

const ProjectView: FC = () => (
  <div className="project">
    <Switch>
      <Route
        exact
        path={[
          projectsRoutes.createProject.path,
          projectsRoutes.createProjectWithProjectRequest.path,
        ]}
      >
        <CreateProjectView />
      </Route>
      <Route
        exact
        path={[
          projectsRoutes.editProjectView.path,
          projectsRoutes.editProjectViewWithProjectRequest.path,
        ]}
      >
        <EditProjectView />
      </Route>
    </Switch>
  </div>
);

export default ProjectView;
