import { ModalTypes } from 'enums/modalTypes';
import { TestSubType, TestType, Trait } from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import useTraitsListItemStyle from './TraitsItemStyle';

interface ITraitsListItem {
  trait: Trait;
}

const TraitsListItem: FC<ITraitsListItem> = ({ trait }) => {
  const classes = useTraitsListItemStyle();

  const handleOpenCreateTraitsModal = () => {
    openModal(ModalTypes.EDIT_TRAIT_MODAL, {
      trait,
    });
  };

  const getTraitTestType = (
    testType: TestType,
    subType?: TestSubType | null
  ) => {
    switch (testType) {
      case TestType.Cognitive:
        return subType;
      case TestType.Personality:
        return ' PERSONALITY |';
      case TestType.TalentReview:
        return ' TALENT_REVIEW | ';
    }
  };
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={2} className={classes.gridItemWrapper}>
        <Typography>{trait.id}</Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemWrapper}>
        <Typography>{trait.name}</Typography>
      </Grid>
      <Grid item xs={3} className={classes.gridItemWrapper}>
        <Typography>{trait.description}</Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemWrapper}>
        <Typography>
          {trait.testTypes.map(traitTestType =>
            getTraitTestType(traitTestType.testType, traitTestType.testSubType)
          )}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.gridItemWrapper}>
        <Button
          onClick={handleOpenCreateTraitsModal}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};

export default TraitsListItem;
