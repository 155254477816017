import { FC } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { TCheckboxFormFieldValue } from '../../../interfaces/forms/form';
import useCheckboxFormFieldStyle from './CheckboxFormFieldStyle';

interface ICheckboxFormField {
  id: string;
  name: string;
  label?: string;
  onChange: (value: TCheckboxFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  value: TCheckboxFormFieldValue;
  isDisabled?: boolean;
  dataRole?: string;
}

const CheckboxFormField: FC<ICheckboxFormField> = ({
  id,
  name,
  label,
  onChange,
  hasError,
  errorText,
  value,
  isDisabled,
  dataRole,
}) => {
  const classes = useCheckboxFormFieldStyle();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, name);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={hasError}
      className={classes.formControl}
    >
      <FormControlLabel
        control={
          <Checkbox
            id={id}
            disabled={isDisabled}
            checked={value}
            onChange={handleChange}
            data-role={dataRole}
          />
        }
        label={label}
      />
      {hasError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default CheckboxFormField;
