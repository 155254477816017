import { makeStyles } from '@material-ui/core/styles';

const useSelectWithQueryStyle = makeStyles(() => ({
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

export default useSelectWithQueryStyle;
