import { FC } from 'react';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import { IgStep } from '../../../../generated/graphql';
import { openModal } from '../../../../graphql/modals';
import useInterviewGuideDndStyle from '../../InterviewGuideDnD/InterviewGuideDndStyle';

interface IIgStepItem {
  igStep: IgStep;
  interviewGuideId: number;
}

const IgStepItem: FC<IIgStepItem> = ({ igStep, interviewGuideId }) => {
  const classes = useInterviewGuideDndStyle();

  const openIgStepConfigModal = () => {
    openModal(ModalTypes.IG_STEP_CONFIG_MODAL, {
      igStep,
      interviewGuideId,
    });
  };
  return (
    <>
      <div
        style={{
          border: 'solid 1px #757575',
          backgroundColor: '#fff',
          marginBottom: 10,
        }}
        className={classes.widgetOption2}
      >
        <p className={classes.text}>{igStep.name}</p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '420px',
            }}
          >
            <Button
              className={classes.viewBtn}
              color="default"
              onClick={() => openIgStepConfigModal()}
            >
              VIEW
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IgStepItem;
