import React, { FC, ReactNode } from 'react';
import styles from './BehaviourUploadInlineNotification.module.scss';
import {
  InlineNotification,
  NotificationType,
} from '@spotted-zebra-uk/ui-components';
import { ValidationStatus } from '../ValidationStatus.enum';
import SuccessValidationStatus from './ValidationStatusComponents/SuccessValidationStatus';
import WarningValidationStatus from './ValidationStatusComponents/WarningValidationStatus';
import FailedValidationStatus from './ValidationStatusComponents/FailedValidationStatus';
import NotStartedValidationStatus from './ValidationStatusComponents/NotStartedValidationStatus';

interface IBehaviourUploadInlineNotification {
  validationStatus: ValidationStatus;
  fileName: string;
  customError?: string;
}

const sheetTemplate =
  'https://dev2-static-content.s3.eu-west-2.amazonaws.com/Behaviour+Content+Upload+Template.csv';

const BehaviourUploadInlineNotification: FC<IBehaviourUploadInlineNotification> = ({
  validationStatus,
  fileName,
  customError,
}) => {
  const validationStatusToNotificationType: {
    [key in ValidationStatus]: NotificationType;
  } = {
    [ValidationStatus.WARNING]: NotificationType.WARNING,
    [ValidationStatus.SUCCESS]: NotificationType.INFORMATION,
    [ValidationStatus.FAILED]: NotificationType.ERROR,
    [ValidationStatus.NOT_STARTED]: NotificationType.INFORMATION,
  };

  const validationStatusToMessage: { [key in ValidationStatus]: ReactNode } = {
    [ValidationStatus.SUCCESS]: <SuccessValidationStatus fileName={fileName} />,
    [ValidationStatus.WARNING]: <WarningValidationStatus fileName={fileName} />,

    [ValidationStatus.FAILED]: (
      <FailedValidationStatus
        sheetTemplate={sheetTemplate}
        customError={customError}
      />
    ),
    [ValidationStatus.NOT_STARTED]: (
      <NotStartedValidationStatus sheetTemplate={sheetTemplate} />
    ),
  };
  return (
    <div className={styles.instruction_container}>
      <InlineNotification
        notificationType={validationStatusToNotificationType[validationStatus]}
        children={validationStatusToMessage[validationStatus]}
        className={styles.inline_notification}
      />
    </div>
  );
};

export default BehaviourUploadInlineNotification;
