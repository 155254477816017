import { isBefore } from 'date-fns';
import { ModalTypes } from 'enums/modalTypes';
import { Formik } from 'formik';
import {
  CompanyEmployeeModel,
  CompanyMaybeUserEmployeeModel,
  CompanyModel,
  useCompaniesQuery,
  useCompanyEmployeeCreateMutation,
  useCompanyEmployeeDeleteOneMutation,
  useCompanyEmployeeUpdateMutation,
  useCompanyMaybeUserEmployeeFindManyLazyQuery,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { sortBy } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Loader } from '@spotted-zebra-uk/ui-components';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { EmployeeModalMode } from '../CompanyEmployeeModal.enums';
import { EmployeeForm, IEmployeeFormValues, NO_EMPLOYEE_ID } from './Form';

const validationSchema = yup.object().shape({
  firstName: yup.string().required().min(2, 'is too short'),
  lastName: yup.string().required().min(2, 'is too short'),
  department: yup.string(),
  employeeId: yup.string().required().min(2, 'is too short'),
  email: yup.string().required().email('is not valid'),
  hrbp: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
      labelNote: yup.string(),
    })
    .test(
      'hrbp',
      { value: 'selected user must be updated' },
      value => !value.value?.startsWith(NO_EMPLOYEE_ID)
    ),
  endDate: yup.date(),
  startDate: yup
    .date()
    .required()
    .test(
      'startDate',
      'need to be earlier than the End date',
      (value, { parent }) =>
        parent.endDate && value ? isBefore(value, parent.endDate) : true
    ),
});

const getFormInitialValues = (
  allowedCompanies: CompanyModel[],
  companyEmployees: CompanyMaybeUserEmployeeModel[],
  employee?: CompanyEmployeeModel
) => {
  const employeeCompany =
    employee &&
    allowedCompanies.find(company => company.id === employee.companyId);
  const selectedCompany = employeeCompany
    ? { label: employeeCompany.name, value: `${employeeCompany.id}` }
    : {
        label: allowedCompanies[0]?.name || '',
        value: `${allowedCompanies[0]?.id || ''}`,
      };

  const htbp = companyEmployees.find(
    e => e.employeeId === employee?.hrManagerEmployeeId
  );

  return {
    employeeId: employee?.employeeId || '',
    companyId: selectedCompany || { label: '', value: '' },
    firstName: employee?.firstName || '',
    lastName: employee?.lastName || '',
    email: employee?.email || '',
    department: employee?.department || '',
    startDate: employee?.startDate || '',
    endDate: employee?.endDate || '',
    hrbp: htbp
      ? {
          label: `${htbp.firstName} ${htbp.lastName}`,
          value: htbp.employeeId || '',
          labelNote: htbp.email,
        }
      : { label: '', value: '', labelNote: '' },
    gender: employee?.customFields?.gender || '',
    jobTitle: employee?.jobTitle || '',
    division: employee?.customFields?.division || '',
    function: employee?.customFields?.function || '',
    groupFunction: employee?.customFields?.fucnctionGroup || '',
    office: employee?.customFields?.office || '',
    officeCountry: employee?.customFields?.officeCountry || '',
    level: employee?.customFields?.organisationLevel || '',
  };
};

interface IEmployeeFormWrapper {
  employee?: CompanyEmployeeModel;
  mode: EmployeeModalMode;
  onEmployeeCreated?: (employee: CompanyEmployeeModel) => void;
}

const EmployeeFormWrapper: FC<IEmployeeFormWrapper> = ({
  employee,
  mode,
  onEmployeeCreated,
}) => {
  const { handleMsgType } = useNotification();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const companiesQueryResponse = useCompaniesQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [
    companyMaybeUserEmployeeFindManyQuery,
    companyMaybeUserEmployeeFindManyQueryResult,
  ] = useCompanyMaybeUserEmployeeFindManyLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (employee?.companyId && employee.hrManagerEmployeeId) {
      companyMaybeUserEmployeeFindManyQuery({
        variables: {
          companyId: employee?.companyId,
        },
      });
    }
  }, [
    companyMaybeUserEmployeeFindManyQuery,
    employee?.companyId,
    employee?.hrManagerEmployeeId,
  ]);

  const [companyEmployeeCreate] = useCompanyEmployeeCreateMutation({
    onCompleted: data => {
      onEmployeeCreated?.(data.CompanyEmployeeCreate);
    },
  });

  const [companyEmployeeUpdate] = useCompanyEmployeeUpdateMutation();

  const [companyEmployeeDeleteOne] = useCompanyEmployeeDeleteOneMutation({
    variables: { id: employee?.id || 0 },
  });

  const handleCloseModal = () => {
    closeModal(ModalTypes.COMPANY_EMPLOYEE_MODAL);
  };

  const handleSubmitForm = async (values: IEmployeeFormValues) => {
    try {
      setIsSubmitting(true);
      switch (mode) {
        case EmployeeModalMode.DELETE:
          await companyEmployeeDeleteOne();
          break;
        case EmployeeModalMode.UPDATE:
          await companyEmployeeUpdate({
            variables: {
              id: employee?.id || 0,
              employeeId: values.employeeId,
              companyId: Number(values.companyId.value),
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              department: values.department,
              endDate: values.endDate
                ? new Date(values.endDate).toISOString()
                : null,
              startDate: values.startDate
                ? new Date(values.startDate).toISOString()
                : null,
              isArchived: false,
              jobTitle: values.jobTitle,
              // TODO: Fix types in Select component.
              hrManagerEmployeeId: values.hrbp.value as string,
              customFields: {
                division: values.division,
                function: values.function,
                functionGroup: values.groupFunction,
                gender: values.gender,
                office: values.office,
                officeCountry: values.officeCountry,
                organisationLevel: values.level,
              },
            },
          });
          break;
        case EmployeeModalMode.CREATE:
          await companyEmployeeCreate({
            variables: {
              companyId: Number(values.companyId.value),
              email: values.email,
              employeeId: values.employeeId,
              firstName: values.firstName,
              lastName: values.lastName,
              department: values.department,
              endDate: values.endDate
                ? new Date(values.endDate).toISOString()
                : null,
              startDate: values.startDate
                ? new Date(values.startDate).toISOString()
                : null,
              isArchived: false,
              jobTitle: values.jobTitle,
              hrManagerEmployeeId: values.hrbp.value as string,
              customFields: {
                division: values.division,
                function: values.function,
                functionGroup: values.groupFunction,
                gender: values.gender,
                office: values.office,
                officeCountry: values.officeCountry,
                organisationLevel: values.level,
              },
            },
          });
          break;
        default:
          break;
      }
      await companyMaybeUserEmployeeFindManyQuery({
        variables: {
          companyId: Number(values.companyId.value),
        },
      });
      setTimeout(() => {
        handleCloseModal();
        setIsSubmitting(false);
      }, 500);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const companies = useMemo(
    () => companiesQueryResponse.data?.Companies || [],
    [companiesQueryResponse.data?.Companies]
  );

  const companiesOptions = useMemo(
    () =>
      sortBy(companies, ['name']).map(({ id, name }) => ({
        value: `${id}`,
        label: name,
      })),
    [companies]
  );

  const initialValues = getFormInitialValues(
    companies,
    companyMaybeUserEmployeeFindManyQueryResult.data
      ?.CompanyMaybeUserEmployeeFindMany || [],
    employee
  );

  if (
    companyMaybeUserEmployeeFindManyQueryResult.loading ||
    isSubmitting ||
    companiesQueryResponse.loading
  ) {
    return (
      <div className="employee-form-loader-wrapper">
        <Loader variant="bubbles" />
      </div>
    );
  }

  return (
    <Formik<IEmployeeFormValues>
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={validationSchema}
    >
      <EmployeeForm mode={mode} companiesOptions={companiesOptions} />
    </Formik>
  );
};

export default EmployeeFormWrapper;
