import Button from 'components/atoms/Button/Button';
import ConfirmationModal from 'components/organisms/ConfirmationModal/ConfirmationModal';
import { format } from 'date-fns';
import { ModalTypes } from 'enums/modalTypes';
import {
  StageCandidateFindManyDocument,
  TestCandidateFindManyByCandidateDocument,
  TestStatus,
  useTestCandidateResetOneMutation,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC, useState } from 'react';
import useUserTableRowStyle from 'views/User/UserMain/UserTable/UserTableRow/UserTableRowStyle';
import { TableCell, TableRow } from '@material-ui/core';
import { IModalStatus } from '../../../UserDetails.interface';
import { ITestResultRow } from '../UserResets.interface';

const TestResultRow: FC<ITestResultRow> = ({
  testCandidate,
  projectName,
  userInfo,
  displaySnackbar,
}) => {
  const classes = useUserTableRowStyle();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [modalStatus, setModalStatus] = useState<IModalStatus>({
    isOpen: false,
    msg: '',
    type: '',
  });
  const assessmentId = testCandidate.tpAssessmentId;
  const { type, subType, tpProjectId } = testCandidate.test;

  const isDefaultTimeModifier = testCandidate.timeModifierPercentage === 0;

  const onResetTest = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: true,
      type: testCandidate.test.type,
    });
  };

  const [testCandidateResetOneMutation] = useTestCandidateResetOneMutation({
    onError: error => {
      setErrorMsg(error.message);
    },
    refetchQueries: [
      {
        query: StageCandidateFindManyDocument,
        variables: { candidateId: userInfo?.id },
      },
      {
        query: TestCandidateFindManyByCandidateDocument,
        variables: { candidateId: Number(userInfo?.id) },
      },
    ],
    onCompleted: () => {
      displaySnackbar('test reset successful');
      setErrorMsg('');
      closeModal();
    },
  });

  const handleResetTest = () => {
    const variables = {
      id: testCandidate.id,
    };
    testCandidateResetOneMutation({ variables });
  };

  const closeModal = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: false,
    });
    setErrorMsg('');
  };

  const handleMitigateCircumstance = (isDefault: boolean) => {
    openModal(ModalTypes.MITIGATE_CIRCUMSTANCE_MODAL, {
      testCandidate,
      isDefault,
      candidateId: userInfo?.id || 0,
    });
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {type === 'PERSONALITY' ? type : subType}
      </TableCell>
      <TableCell className={classes.tableCell}>{tpProjectId}</TableCell>
      <TableCell className={classes.tableCell}>{assessmentId}</TableCell>
      <TableCell className={classes.tableCell}>
        {testCandidate.status}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {testCandidate.testResult?.updatedAt
          ? format(
              new Date(testCandidate.testResult.updatedAt),
              'dd/MM/yyyy HH:mm:ss'
            )
          : ''}
      </TableCell>
      <TableCell className={classes.tableCell}></TableCell>
      <TableCell>
        {tpProjectId || type === 'PERSONALITY' ? (
          ''
        ) : (
          <div
            style={{
              width: '100px',
              textAlign: 'center',
              margin: '0 auto',
              fontSize: '16px',
              fontWeight: '500',
              borderRadius: '10px',
              background: isDefaultTimeModifier ? '#3f51b5' : 'green',
              padding: '10px',
              color: '#fff',
              lineHeight: '20px',
              cursor: 'pointer',
            }}
            onClick={() => handleMitigateCircumstance(isDefaultTimeModifier)}
          >
            Mitigating circumstance
          </div>
        )}
      </TableCell>
      <TableCell align="right">
        <div>
          <Button
            className={classes.btn}
            isDisabled={testCandidate.status === TestStatus.NotStarted}
            onClick={onResetTest}
          >
            RESET TEST
          </Button>
        </div>
      </TableCell>
      <ConfirmationModal
        open={modalStatus.isOpen}
        errorMsg={errorMsg}
        message={`Please confirm you wish to delete the current results for this candidate for project ${projectName} - ${type}`}
        onSave={handleResetTest}
        onClose={closeModal}
        saveBtnText="RESET"
      />
    </TableRow>
  );
};

export default TestResultRow;
