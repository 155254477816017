import { Formik, useField } from 'formik';
import {
  useGetProjectByIdQuery,
  useGetProjectCobrandingQuery,
  useSetProjectCobrandingMutation,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  Button,
  EditFormWrapper,
  FormikCheckboxFormField,
  TNotification,
  Tooltip,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './ProjectAuthenticationSettings.module.scss';
import {
  AuthSettingsFormProps,
  AuthSettingsFormValues,
  AuthSettingsPreviewProps,
  ProjectAuthenticationSettingsProps,
} from './ProjectAuthenticationSettings.types';

export function ProjectAuthenticationSettings({
  projectId,
}: ProjectAuthenticationSettingsProps) {
  const [cobrandingEnabled, setCobrandingEnabled] = useState(false);
  const [formActive, setFormActive] = useState(false);
  const { handleMsgType } = useNotification();

  const { data: projectData } = useGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  });

  const subId = projectData?.project?.subId || '';

  const { data: projectCobrandingData } = useGetProjectCobrandingQuery({
    variables: {
      subId: subId,
    },
  });

  useEffect(() => {
    const cobrandingIsEnabled = Boolean(
      projectCobrandingData?.getProjectCobranding.companyName
    );

    setCobrandingEnabled(cobrandingIsEnabled);
  }, [projectCobrandingData]);

  const [setProjectCobranding] = useSetProjectCobrandingMutation();

  function handleSaveAuthSettings(values: AuthSettingsFormValues) {
    setProjectCobranding({
      variables: {
        projectId: projectId,
        cobrandingEnabled: values.cobrandingEnabled,
      },
      onCompleted: () => {
        setCobrandingEnabled(values.cobrandingEnabled);
        handleSuccess();
        setFormActive(false);
      },
      onError: () => {
        handleError();
        setFormActive(false);
      },
    });
  }

  function handleSuccess() {
    handleMsgType({
      type: TNotification.success,
      message: 'Successfully updated authentication',
    });
  }

  function handleError() {
    handleMsgType({
      type: TNotification.error,
      title: 'Authentication could not be updated',
      message: 'Something went wrong. Please try again.',
    });
  }

  return formActive ? (
    <AuthSettingsForm
      cobrandingEnabled={cobrandingEnabled}
      onCancel={() => setFormActive(false)}
      onSubmit={handleSaveAuthSettings}
    />
  ) : (
    <AuthSettingsPreview
      cobrandingEnabled={cobrandingEnabled}
      activateForm={() => setFormActive(true)}
    />
  );
}

function AuthSettingsPreview({
  cobrandingEnabled,
  activateForm,
}: AuthSettingsPreviewProps) {
  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__sub-title">Authentication</div>
        </div>

        <div className="project-header__right">
          <Button onClick={activateForm} size="medium">
            Edit
          </Button>
        </div>
      </div>

      <div className="project-info">
        <div className="project-info__item">
          <Tooltip content="Display the company and project name on main sign-in screen.">
            <div className="project-info__label">Co-branding</div>
          </Tooltip>
          <div className="project-info__value">
            {cobrandingEnabled ? 'On' : 'Off'}
          </div>
        </div>
      </div>
    </div>
  );
}

function AuthSettingsForm({
  onSubmit,
  onCancel,
  cobrandingEnabled,
}: AuthSettingsFormProps) {
  function handleSubmit(values: AuthSettingsFormValues) {
    onSubmit(values);
  }
  return (
    <EditFormWrapper
      formId="project-authentication-settings-form"
      title="Edit authentication"
      hasResetAllButton={false}
      onCancelClick={onCancel}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          cobrandingEnabled: cobrandingEnabled,
        }}
      >
        {({ handleSubmit }) => {
          return (
            <form
              id="project-authentication-settings-form"
              onSubmit={handleSubmit}
              className={styles.formFieldsWrapper}
            >
              <FormikCheckboxFormField
                id="cobrandingEnabled"
                name="cobrandingEnabled"
                label="Co-branding"
                useFormikField={useField}
              />
            </form>
          );
        }}
      </Formik>
    </EditFormWrapper>
  );
}
