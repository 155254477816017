import { createUploadLink } from 'apollo-upload-client';
import { getAccessToken, logoutCleanup } from 'helpers/auth';
import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  HttpLink,
  Operation,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import cache from './cache';
import { requestTokenRefresh } from './refreshToken';

export const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
        if (
          err.extensions?.response?.statusCode === 401 &&
          operation.operationName !== 'RequestTokenRefresh'
        ) {
          return fromPromise(requestTokenRefresh(client))
            .filter(value => Boolean(value))
            .flatMap(() => {
              return forward(operation);
            });
        }

        if (err.message === 'Forbidden') {
          logoutCleanup();
        }
      }
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }

    return undefined;
  }
);

const authLink = new ApolloLink((operation, forward) => {
  const token = getAccessToken();
  operation.setContext({
    headers: {
      Authorization: token && `Bearer ${token}`,
      'X-Client': 'admin',
    },
  });

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_BASE_URL as string,
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_MONOLITH_API_URL,
  headers: {
    'keep-alive': 'true',
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

const isUploadOperation = (operation: Operation) =>
  !!operation.getContext().isUpload;

const terminatingLink = split(isUploadOperation, uploadLink, httpLink);

const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, terminatingLink]),
});

export default client;
