import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import CompanySelectFormField from '../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  Report,
  ReportOwnerType,
  ReportSubType,
  ReportType,
  useReportCopyMutation,
  useReportCreateMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import { addReportToCache } from '../../../graphql/report';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { TReportsModalProps } from '../../../interfaces/modal';
import { useReportModalStyle } from './ReportsModalStyle';
import ReportSubTypeFormField from './ReportSubTypeFormField/ReportSubTypeFormField';
import ReportTypeFormField from './ReportTypeFormField/ReportTypeFormField';

interface IAddReportPayload {
  reportName: string;
  ownerId: TSelectFormFieldValue;
  company: string;
  type: TSelectFormFieldValue;
  subType?: TSelectFormFieldValue;
}
interface IReportError {
  reportName: string;
  company: string;
  type: string;
}

const ReportsModal: FC<TReportsModalProps> = props => {
  const { handleMsgType } = useNotification();

  const classes = useReportModalStyle();
  const [createDuplicateMutation] = useReportCopyMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const report = data?.report as Report;
      if (report) {
        addReportToCache(cache, report);
      }
    },
    onCompleted: () => {
      closeModal(ModalTypes.REPORTS_PAGE_MODAL);
    },
  });

  const [createReportMutation] = useReportCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const report = data?.report as Report;
      if (report) {
        addReportToCache(cache, report);
      }
    },
    onCompleted: () => {
      closeModal(ModalTypes.REPORTS_PAGE_MODAL);
    },
  });

  const { name, type, subType } = props;

  const [reportDetails, setReportDetails] = useState<IAddReportPayload>({
    reportName: name,
    ownerId: '',
    company: '',
    type: type,
    subType: subType,
  });
  const [errors, setErrors] = useState<IReportError>({
    reportName: '',
    company: '',
    type: '',
  });
  const handleCloseModal = () => {
    closeModal(ModalTypes.REPORTS_PAGE_MODAL);
  };

  const isEmptyProps = _.isEmpty(props);

  const getReportName = (value: string, name: string) => {
    setReportDetails(prev => ({ ...prev, reportName: value }));
    setErrors(prevErrors =>
      prevErrors ? { ...prevErrors, [name]: '' } : prevErrors
    );
  };

  const getCompanyFormSelection = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setReportDetails(prev => ({ ...prev, ownerId: value, company: name }));
  };

  const getTypeValue = (value: TSelectFormFieldValue, name: string) => {
    setReportDetails(prev => ({ ...prev, [name]: value }));
  };

  const reportFormValidation = (newValues: IAddReportPayload) => {
    return validate(newValues, {
      reportName: {
        nonEmptyString: true,
      },
      company: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
      type: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
    });
  };

  const handleSubmitReport = () => {
    const reportErrors = reportFormValidation(reportDetails);
    if (!_.isEmpty(reportErrors)) {
      return setErrors(reportErrors);
    }
    const variables = {
      name: reportDetails.reportName,
      type: reportDetails.type as ReportType,
      subType: reportDetails.subType as ReportSubType,
      ownerId: Number(reportDetails.ownerId),
      ownerType: ReportOwnerType.Company,
    };
    const duplicateVariables = {
      ...variables,
      copyReportId: Number(props.copyReportId),
    };
    if (isEmptyProps) {
      createReportMutation({ variables });
    } else {
      createDuplicateMutation({ variables: duplicateVariables });
    }
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            {`${isEmptyProps ? 'Create report' : 'Duplicate report'}`}
          </Typography>
        </Grid>
        {!isEmptyProps && (
          <Grid>
            <Typography className={classes.textLabel}>Source report</Typography>
            <Typography className={classes.textValue}>{name}</Typography>
          </Grid>
        )}
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="reportName"
            name="reportName"
            value={reportDetails.reportName}
            label="Report Name"
            onChange={getReportName}
            hasError={!!errors?.reportName}
            errorText={errors?.reportName}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <CompanySelectFormField
            id="companyFormField"
            name="company"
            label="Company"
            value={reportDetails.ownerId}
            onChange={getCompanyFormSelection}
            hasError={!!errors?.company}
            errorText={errors?.company}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <ReportTypeFormField
            id="typeFormField"
            name="type"
            label="Type"
            value={reportDetails.type}
            onChange={getTypeValue}
            hasError={!!errors?.type}
            errorText={errors?.type}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <ReportSubTypeFormField
            id="subTypeFormField"
            name="subType"
            label="Sub type"
            value={reportDetails.subType}
            onChange={getTypeValue}
            selectedReportType={
              reportDetails.type ? (reportDetails.type as ReportType) : null
            }
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="default"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmitReport}
          >{`Create${!isEmptyProps ? ' copy' : ''}`}</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ReportsModal;
