import { FC, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IgInterviewGuide,
  useIgInterviewGuidesQuery,
} from '../../../generated/graphql';
import { openModal } from '../../../graphql/modals';
import { TTextFromFieldValue } from '../../../interfaces/forms/form';
import ReportHeader from '../../Reports/ReportList/ReportHeader/ReportHeader';
import InterviewGuideTable from './InterviewGuideTable/InterviewGuideTable';

// import { headerData } from './ReportList.constants';

const headerData = ['Name', 'Company', 'Updated Date'];
const InterviewGuides: FC = () => {
  const { data, loading, error } = useIgInterviewGuidesQuery();

  const [search, setSearch] = useState<string>('');
  const [isArchivedVisible, setIsArchivedVisible] = useState<boolean>(false);
  const handleChangeSearch = (value: TTextFromFieldValue) => {
    setSearch(value);
  };

  const toggleArchivedProjectsVisibility = (value: boolean) => {
    setIsArchivedVisible(value);
  };

  const handleAddInterviewGuideModal = () => {
    openModal(ModalTypes.ADD_INTERVIEW_GUIDE_MODAL, {});
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (data?.IgInterviewGuides) {
    const { IgInterviewGuides } = data;
    let parsedInterviewGuides = [...(IgInterviewGuides as IgInterviewGuide[])];

    parsedInterviewGuides = parsedInterviewGuides.filter(interviewGuide => {
      if (!isArchivedVisible && interviewGuide.isArchived) return false;
      if (search) {
        return [interviewGuide.name].some(name =>
          name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      }
      return true;
    });

    return (
      <Grid container>
        <ReportHeader
          isArchivedVisible={isArchivedVisible}
          toggleArchivedProjectsVisibility={toggleArchivedProjectsVisibility}
          search={search}
          setSearch={handleChangeSearch}
          handleClick={handleAddInterviewGuideModal}
          headerTitle="Interview Guides List"
          buttonText="Add Interview Guide"
        />
        <InterviewGuideTable
          headerData={headerData}
          tableBodyData={parsedInterviewGuides}
        />
      </Grid>
    );
  }

  return null;
};

export default InterviewGuides;
