import {
  ProjectCollaborator,
  ActorRole,
  ProjectFragment,
} from 'generated/graphql';
import {
  formatProjectDate,
  formatCamelCase,
  collaboratorsByRole,
} from 'helpers/project';
import {
  InlineNotification,
  NotificationType,
  Button,
  useLocales,
  Tooltip,
} from '@spotted-zebra-uk/ui-components';
import { FC } from 'react';

interface IProjectInfo {
  project: ProjectFragment;
  collaborators?: Array<ProjectCollaborator> | null;
  onEdit: () => void;
}

const ProjectCollaboratorItem = ({
  label,
  collaborators,
}: {
  label: string;
  collaborators: Array<ProjectCollaborator>;
}) => {
  return collaborators.length ? (
    <div className="project-info__item">
      <div className="project-info__label">{label}</div>
      <div className="project-info__value">
        {collaborators.map((collaborator, index) => (
          <Tooltip key={collaborator.id} content={collaborator.email}>
            <span className="project-info__tooltip-content">
              {collaborator.firstName} {collaborator.lastName}
              {index === collaborators.length - 1 ? '' : ', '}
            </span>
          </Tooltip>
        ))}
      </div>
    </div>
  ) : null;
};

export const ProjectOverviewInfo: FC<IProjectInfo> = ({
  project,
  collaborators,
  onEdit,
}) => {
  const unassigned = collaborators?.filter(
    collaborator => !collaborator.actorRole
  );
  const byRole = collaboratorsByRole(collaborators || []);

  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__title">Project overview</div>
        </div>

        <div className="project-header__right">
          <Button onClick={onEdit} size="medium">
            Edit
          </Button>
        </div>
      </div>

      <div className="project-info project-info__indent">
        <div className="project-info__item">
          <div className="project-info__label">Created</div>
          <div className="project-info__value">
            {formatProjectDate(project.createdAt)}
          </div>
          <div className="project-info__label">Edited</div>
          <div className="project-info__value">
            {formatProjectDate(project.updatedAt)}
          </div>
        </div>

        <h3 className="project-info__title">General</h3>

        <div className="project-info__item">
          <div className="project-info__label">Company</div>
          <div className="project-info__value">{project.company?.name}</div>
        </div>

        <div className="project-info__item">
          <div className="project-info__label">Product solution</div>
          <div className="project-info__value">
            {formatCamelCase(project.productSolution)}
          </div>
        </div>

        <div className="project-info__item">
          <div className="project-info__label">Product module type</div>
          <div className="project-info__value">
            {formatCamelCase(project.moduleType)}
          </div>
        </div>

        <h3 className="project-info__title">Project team</h3>

        {collaborators && !collaborators.length ? (
          <InlineNotification
            notificationType={NotificationType.WARNING}
            className="project-info__warning"
          >
            <p>
              Add users to the project team to make sure they can access the
              project.
            </p>
          </InlineNotification>
        ) : null}

        {unassigned?.length ? (
          <InlineNotification
            title="Missing Information"
            notificationType={NotificationType.WARNING}
            className="project-info__warning"
          >
            <p>
              Edit the project overview to tag project roles for these project
              team members:
            </p>
            <p className="project-info__emphasis">
              <>
                {unassigned.map((collaborator, index) => (
                  <Tooltip key={collaborator.id} content={collaborator.email}>
                    <span className="project-info__tooltip-content">
                      {collaborator.firstName} {collaborator.lastName}
                      {index === unassigned.length - 1 ? '' : ', '}
                    </span>
                  </Tooltip>
                ))}
              </>
            </p>
          </InlineNotification>
        ) : null}

        <ProjectCollaboratorItem
          label="Hiring manager"
          collaborators={byRole[ActorRole.HiringManager] || []}
        />
        <ProjectCollaboratorItem
          label="Recruiter"
          collaborators={
            byRole[ActorRole.RecruiterOrTalentAcquisitionPartner] || []
          }
        />
        <ProjectCollaboratorItem
          label="Interviewer"
          collaborators={byRole[ActorRole.Interviewer] || []}
        />
        <ProjectCollaboratorItem
          label="Reskilling team member"
          collaborators={byRole[ActorRole.ReskillingTeamMember] || []}
        />
        <ProjectCollaboratorItem
          label="Line manager"
          collaborators={byRole[ActorRole.LineManager] || []}
        />
        <ProjectCollaboratorItem
          label="Early careers team member"
          collaborators={byRole[ActorRole.EarlyCareerTeamMember] || []}
        />
        <ProjectCollaboratorItem
          label="Head of People/Talent"
          collaborators={byRole[ActorRole.HeadOfPeopleOrTalent] || []}
        />
      </div>
    </div>
  );
};

export const ProjectLanguageInfo: FC<IProjectInfo> = ({ project, onEdit }) => {
  const { getSupportedLocaleMetadata, SupportedLocale } = useLocales();
  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__sub-title">Project languages</div>
        </div>

        <div className="project-header__right">
          <Button onClick={onEdit} size="medium">
            Edit
          </Button>
        </div>
      </div>

      <div className="project-info">
        <div className="project-info__item">
          <div className="project-info__label">Selected</div>
          <div className="project-info__value">
            {project.locales
              .map(
                locale =>
                  getSupportedLocaleMetadata(SupportedLocale[locale]).name
                    .english
              )
              .join(', ')}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProjectReportsInfo: FC = () => {
  return (
    <div>
      <div className="project-header">
        <div className="project-header__left">
          <div className="project-header__sub-title">Reports</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverviewInfo;
