import { appRoutes } from '../../constants/navigation';

export const navbarConfig = [
  {
    title: 'Projects',
    path: appRoutes.projects.path,
  },
  {
    title: 'Project Requests',
    path: appRoutes.projectRequests.path,
  },
  {
    title: 'Companies',
    path: appRoutes.companies.path,
  },
  {
    title: 'Users',
    path: appRoutes.users.path,
  },
  {
    title: 'Results',
    path: appRoutes.results.path,
  },
  {
    title: 'Form manager',
    path: appRoutes.formManager.path,
  },
  {
    title: 'Stage Actions',
    path: appRoutes.stageActions.path,
  },
  {
    title: 'Content',
    path: appRoutes.content.path,
  },
  {
    title: 'Reports',
    path: appRoutes.reports.path,
  },
  {
    title: 'Api Keys',
    path: appRoutes.apiKeys.path,
  },
  {
    title: 'Interview guides',
    path: appRoutes.interviewGuides.path,
  },
];
