import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import CircularLoader from '../../../components/molecules/CircularLoader/CircularLoader';
import { useValidateContentLazyQuery } from '../../../generated/graphql';
import { StaticContentContext } from '../StaticContentContext/StaticContentContext';
import ValidateHeader from './ValidateHeader/ValidateHeader';
import ValidateList from './ValidateList/ValidateList';
import useValidateStyle from './ValidateStyle';

export interface IValidate {}

const Validate: FC<IValidate> = () => {
  const { filters } = useContext(StaticContentContext);
  const classes = useValidateStyle();
  const [
    validateContentQuery,
    validateContentResponse,
  ] = useValidateContentLazyQuery();

  useEffect(() => {
    const { softSkills, traits, page, take } = filters;
    validateContentQuery({
      variables: {
        softSkillIds: softSkills,
        traitIds: traits,
        page: page ? page : 1,
        take: take ? take : 50,
      },
    });
  }, [filters, validateContentQuery]);

  if (validateContentResponse.loading) {
    return <CircularLoader />;
  }

  if (validateContentResponse.error) {
    return <Typography className={classes.container}>Error</Typography>;
  }

  if (validateContentResponse.data) {
    const validateContent = validateContentResponse.data.validateContent;
    if (validateContent) {
      const count = validateContent.count;

      if (validateContent.content && count) {
        return (
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className={classes.container}
          >
            <ValidateHeader />
            <ValidateList content={validateContent.content} count={count} />
          </Grid>
        );
      }
    }
  }

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
      className={classes.container}
    >
      <ValidateHeader />
      <Typography>No data to display</Typography>
    </Grid>
  );
};

export default Validate;
