import { makeStyles } from '@material-ui/core/styles';

const useAddTraitModalStyle = makeStyles({
  modalPaper: {
    minWidth: 600,
    minHeight: 300,
  },
});

export default useAddTraitModalStyle;
