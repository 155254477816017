import {
  StageFindManyDocument,
  StageFindManyQuery,
  StageFindManyQueryVariables,
  useRemoveOneStageMutation,
} from 'generated/graphql';
import { FC } from 'react';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

interface IDeleteStage {
  projectId: number;
  id: number;
  order: number;
}

const DeleteStage: FC<IDeleteStage> = props => {
  const { handleMsgType } = useNotification();

  // User should be able to delete stage.
  const [deleteStageMutation] = useRemoveOneStageMutation({
    update(cache, { data }) {
      // Stage should be removed from chache.
      const isCompleted = !!data?.RemoveOneStage;
      if (isCompleted) {
        const cacheData = cache.readQuery<
          StageFindManyQuery,
          StageFindManyQueryVariables
        >({
          query: StageFindManyDocument,
          variables: {
            projectId: props.projectId,
          },
        });
        if (cacheData?.Stages) {
          cache.writeQuery({
            query: StageFindManyDocument,
            variables: {
              projectId: props.projectId,
            },
            data: {
              Stages: cacheData.Stages.filter(
                stage => stage.id !== props.id
              ).map(stage => {
                // Order of stages should be updated.
                if (stage.order > props.order) {
                  return {
                    ...stage,
                    order: stage.order - 1,
                  };
                }

                return stage;
              }),
            },
          });
        }
      }
    },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const handleClick = () => {
    deleteStageMutation({
      variables: {
        stageId: props.id,
      },
    });
  };

  return (
    <Button onClick={handleClick} size="medium" variant="secondary">
      Remove
    </Button>
  );
};

export default DeleteStage;
