import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { headerData } from './UserTable.constants';
import UserTableRow from './UserTableRow/UserTableRow';
import useUserTableStyle from './UserTableStyle';

interface ISearchOption {
  subId?: Maybe<string>;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}
interface IUserTable {
  userData: ISearchOption[];
}
const UserTable: FC<IUserTable> = ({ userData }) => {
  const classes = useUserTableStyle();
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell className={classes.tableHeader} key={`a${index}`}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {userData?.map(user => (
            <UserTableRow
              key={user.id}
              id={user.id}
              subId={user?.subId || ''}
              firstName={user.firstName}
              lastName={user.lastName}
              email={user.email}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
