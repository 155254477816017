import { FC } from 'react';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { TAlertTypes } from '../../../interfaces/snackBar';

interface IAlert {
  snackbarMsg?: string;
  onClose: () => void;
  severity?: TAlertTypes;
  children?: string;
}
const Alert: FC<IAlert> = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface ISnackbar {
  open: boolean;
  alertType: TAlertTypes;
  snackbarMsg: string;
  handleClose: () => void;
}

const Snackbar: FC<ISnackbar> = ({
  handleClose,
  open,
  snackbarMsg,
  alertType,
}) => {
  const classes = useStyles();

  const renderAlert = (alertType: TAlertTypes) => ({
    [alertType]: (handleClose: () => void, snackbarMsg: string) => (
      <Alert onClose={handleClose} severity={alertType}>
        {snackbarMsg}
      </Alert>
    ),
  });

  return (
    <div className={classes.root}>
      <MuiSnackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {renderAlert(alertType)[alertType](handleClose, snackbarMsg)}
      </MuiSnackbar>
    </div>
  );
};

export default Snackbar;
