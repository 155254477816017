import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ModalTypes } from '../../../enums/modalTypes';
import { Report, useReportsQuery } from '../../../generated/graphql';
import { openModal } from '../../../graphql/modals';
import { TTextFromFieldValue } from '../../../interfaces/forms/form';
import ReportHeader from './ReportHeader/ReportHeader';
import { headerData } from './ReportList.constants';
import useReportsStyle from './ReportsStyle';
import ReportsTable from './ReportsTable/ReportsTable';

const Reports: FC = () => {
  const classes = useReportsStyle();
  const reportsQueryResponse = useReportsQuery();
  const [search, setSearch] = useState<string>('');
  const [isArchivedVisible, setIsArchivedVisible] = useState<boolean>(false);
  const handleChangeSearch = (value: TTextFromFieldValue) => {
    setSearch(value);
  };

  const toggleArchivedProjectsVisibility = (value: boolean) => {
    setIsArchivedVisible(value);
  };

  const handleAddReportModal = () => {
    openModal(ModalTypes.REPORTS_PAGE_MODAL, {});
  };

  if (reportsQueryResponse?.data) {
    const Reports = reportsQueryResponse?.data.Reports as Report[];
    let parsedReports = [...Reports];
    parsedReports = parsedReports.filter(report => {
      if (!isArchivedVisible && report.isArchived) return false;
      if (search) {
        return [
          report.name,
          report?.company?.name,
          report.type,
          report.subType,
        ].some(name =>
          name?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      }
      return true;
    });

    return (
      <Grid container className={classes.container}>
        <ReportHeader
          isArchivedVisible={isArchivedVisible}
          toggleArchivedProjectsVisibility={toggleArchivedProjectsVisibility}
          search={search}
          setSearch={handleChangeSearch}
          handleClick={handleAddReportModal}
          headerTitle="Report list"
          buttonText="ADD REPORT"
        />
        <ReportsTable headerData={headerData} tableBodyData={parsedReports} />
      </Grid>
    );
  }

  return null;
};

export default Reports;
