import React, { FC } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { Grid, Typography } from '@material-ui/core';
import { IgStep } from '../../../../generated/graphql';
import useInterviewGuideDndStyle from '../../InterviewGuideDnD/InterviewGuideDndStyle';
import IgStepItem from '../IgStepItem/IgStepItem';

interface IIgStepsDnD {
  igSteps: IgStep[];
  interviewGuideId: number;
  igArchivedSteps: IgStep[];
  reOrderIgSteps: (list: IgStep[]) => void;
}
const IgStepsDnD: FC<IIgStepsDnD> = ({
  igSteps,
  interviewGuideId,
  igArchivedSteps,
  reOrderIgSteps,
}) => {
  const classes = useInterviewGuideDndStyle();

  const reorder = (
    list: IgStep[],
    startIndex: number,
    endIndex: number
  ): IgStep[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source && result.destination) {
      const temp = reorder(
        igSteps,
        result.source.index,
        result.destination?.index
      );
      reOrderIgSteps(temp);
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.container}>
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.title}>
              Ig Steps
            </Typography>
          </Grid>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <div
                className={classes.droppableContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {igSteps.map((item: IgStep, index: number) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(providedDraggable: DraggableProvided) => (
                        <div>
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                          >
                            {item && (
                              <IgStepItem
                                igStep={item as IgStep}
                                interviewGuideId={interviewGuideId}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      {/* archived igSteps*/}
      <div>
        <Grid item xs={3}>
          <Typography variant="h6" className={classes.title}>
            Archived Steps
          </Typography>
        </Grid>
        <div>
          {igArchivedSteps?.map(
            (item: IgStep, index) =>
              item && (
                <IgStepItem
                  key={item.id}
                  igStep={item as IgStep}
                  interviewGuideId={interviewGuideId}
                />
              )
          )}
        </div>
      </div>
    </>
  );
};

export default IgStepsDnD;
