import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import {
  FormikMultiselectFormField,
  IFormikMultiselectFormField,
  TMultiselectOption,
} from '@spotted-zebra-uk/ui-components';
import { useField } from 'formik';

export interface IFormikMultiselectFormFieldWithQuery<TQuery>
  extends Omit<IFormikMultiselectFormField, 'options'> {
  query: DocumentNode;
  queryOptions?: QueryHookOptions<TQuery, OperationVariables>;
  getOptionsFromResponse: (response: TQuery) => TMultiselectOption[];
}

export const FormikMultiselectFormFieldWithQuery = <TQuery,>({
  query,
  queryOptions,
  getOptionsFromResponse,
  ...selectElementProps
}: IFormikMultiselectFormFieldWithQuery<TQuery>) => {
  const queryResult = useQuery(query, {
    onError: () => {},
    ...queryOptions,
  });

  const options = queryResult.data
    ? getOptionsFromResponse(queryResult.data)
    : [];

  return (
    <FormikMultiselectFormField
      {...selectElementProps}
      options={options}
      isDisabled={Boolean(queryResult.error || queryResult.loading)}
      useFormikField={useField}
    />
  );
};
