import Icon from 'components/atoms/Icon';
import { projectsRoutes } from 'constants/navigation';
import { ModalTypes } from 'enums/modalTypes';
import {
  ProjectRequestFragment,
  ProjectRequestUserModelFragment,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@spotted-zebra-uk/ui-components';
import { ProjectRequestApprovalsPreview } from './ProjectRequestApprovalsPreview';

interface IProjectAndApprovalActions {
  projectRequest: ProjectRequestFragment;
  projectRequestUsers: ProjectRequestUserModelFragment[];
}

const ProjectAndApprovalActions: FC<IProjectAndApprovalActions> = ({
  projectRequest,
  projectRequestUsers,
}) => {
  const history = useHistory();

  const openRequestApprovalModal = () => {
    openModal(ModalTypes.PROJECT_REQUEST_APPROVAL_MODAL, {
      projectRequestId: projectRequest.id,
      projectRequestCompanyId: projectRequest.companyId,
    });
  };

  if (!projectRequest.projectId) {
    return (
      <div className="project-and-approval-actions">
        <Button
          onClick={() =>
            history.push(
              projectsRoutes.createProjectWithProjectRequest.url(
                projectRequest.id
              )
            )
          }
        >
          Create Project
        </Button>
      </div>
    );
  }

  return (
    <div className="project-and-approval-actions">
      <Button
        onClick={() => {
          if (projectRequest.projectId) {
            history.push(
              projectsRoutes.editProjectViewWithProjectRequest.url(
                projectRequest.projectId,
                projectRequest.id
              )
            );
          }
        }}
        rightIcon={
          <Icon
            icon="pencil"
            className="project-and-approval-actions__pencil-icon"
          />
        }
      >
        Edit Project
      </Button>
      <Button onClick={openRequestApprovalModal}>Request Approval</Button>
      {projectRequest.approvalRequestDate ? (
        <ProjectRequestApprovalsPreview
          projectRequest={projectRequest}
          projectRequestUsers={projectRequestUsers}
        />
      ) : null}
    </div>
  );
};

export default ProjectAndApprovalActions;
