import Button from 'components/atoms/Button/Button';
import TextFormFieldWithActionIcon from 'components/organisms/TextFormFieldWithActionIcon/TextFormFieldWithActionIcon';
import { classificationOptions } from 'constants/classification';
import { roleLevelOptions } from 'constants/roleLevel';
import { ModalTypes } from 'enums/modalTypes';
import {
  InterviewQuestionRoleLevel,
  useSaveInterviewQuestionContentMutation,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { TFormFieldValue } from 'interfaces/forms/form';
import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useInterviewQuestionContentListItemStyle from './InterviewQuestionContentListItemStyle';

interface IInterviewQuestionContentListItem {
  contentPiece: InterviewQuestionRoleLevel;
}

interface IInterviewQuestionContentFieldValues {
  summary: string;
  feedback: string;
  reasonForQuestion: string;
  mainQuestion: string;
  subQuestions: string[];
}

interface IInterviewQuestionContentFieldDisabledValues {
  summary: boolean;
  feedback: boolean;
  reasonForQuestion: boolean;
  mainQuestion: boolean;
  subQuestions: boolean;
  saveButton: boolean;
}

const InterviewQuestionContentListItem: FC<IInterviewQuestionContentListItem> = ({
  contentPiece,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useInterviewQuestionContentListItemStyle();
  const [values, setValues] = useState<IInterviewQuestionContentFieldValues>({
    summary: contentPiece.summary ? contentPiece.summary : '',
    feedback: contentPiece.feedback ? contentPiece.feedback : '',
    reasonForQuestion: contentPiece.reasonForQuestion
      ? contentPiece.reasonForQuestion
      : '',
    mainQuestion: contentPiece.mainQuestion ? contentPiece.mainQuestion : '',
    subQuestions: contentPiece.subQuestions ? contentPiece.subQuestions : [],
  });
  const [isDisabled, setIsDisabled] = useState<
    IInterviewQuestionContentFieldDisabledValues
  >({
    summary: false,
    feedback: false,
    reasonForQuestion: false,
    mainQuestion: false,
    subQuestions: false,
    saveButton: true,
  });
  const [
    saveInterviewQuestionContentMutation,
  ] = useSaveInterviewQuestionContentMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setIsDisabled({
        summary: false,
        feedback: false,
        reasonForQuestion: false,
        mainQuestion: false,
        subQuestions: false,
        saveButton: true,
      });
    },
  });

  const handleChangeSummary = (value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, summary: value as string }));
    checkIfEdited({ ...values, summary: value as string });
  };

  const handleChangeFeedback = (value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, feedback: value as string }));
    checkIfEdited({ ...values, feedback: value as string });
  };

  const handleChangeReasonForQuestion = (value: TFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      reasonForQuestion: value as string,
    }));
    checkIfEdited({ ...values, reasonForQuestion: value as string });
  };

  const handleChangeMainQuestion = (value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, mainQuestion: value as string }));
    checkIfEdited({ ...values, mainQuestion: value as string });
  };

  const handleChangeSubQuestions = (value: TFormFieldValue) => {
    const strings: string[] =
      typeof value === 'string' ? value.split(';') : (value as string[]);

    setValues(prevValues => ({
      ...prevValues,
      subQuestions: strings,
    }));
    checkIfEdited({ ...values, subQuestions: strings });
  };

  const handleSubmit = () => {
    setIsDisabled({
      summary: true,
      feedback: true,
      reasonForQuestion: true,
      mainQuestion: true,
      subQuestions: true,
      saveButton: true,
    });
    saveInterviewQuestionContent(values);
  };

  const saveInterviewQuestionContent = (
    values: IInterviewQuestionContentFieldValues
  ) => {
    const id = contentPiece.id;
    const summary = values.summary ? values.summary : contentPiece.summary;
    const feedback = values.feedback ? values.feedback : contentPiece.feedback;
    const reasonForQuestion = values.reasonForQuestion
      ? values.reasonForQuestion
      : contentPiece.reasonForQuestion;
    const mainQuestion = values.mainQuestion
      ? values.mainQuestion
      : contentPiece.mainQuestion;
    const subQuestions = values.subQuestions
      ? values.subQuestions
      : contentPiece.subQuestions;

    saveInterviewQuestionContentMutation({
      variables: {
        id,
        summary: summary as string,
        feedback: feedback as string,
        reasonForQuestion: reasonForQuestion as string,
        mainQuestion: mainQuestion as string,
        subQuestions: subQuestions as string[],
      },
    });
  };

  // Disables button if no edit is made
  const checkIfEdited = (
    values: IInterviewQuestionContentFieldValues
  ): void => {
    if (
      values.summary === '' ||
      values.feedback === '' ||
      values.reasonForQuestion === '' ||
      values.mainQuestion === ''
    ) {
      if (!isDisabled.saveButton)
        setIsDisabled(prevValues => ({ ...prevValues, saveButton: true }));
      return;
    }
    if (
      contentPiece.summary === values.summary &&
      contentPiece.feedback === values.feedback &&
      contentPiece.reasonForQuestion === values.reasonForQuestion &&
      contentPiece.mainQuestion === values.mainQuestion &&
      !checkIfSubQuestionsEdited(values.subQuestions)
    ) {
      if (!isDisabled.saveButton)
        setIsDisabled(prevValues => ({ ...prevValues, saveButton: true }));
      return;
    }

    if (isDisabled.saveButton)
      setIsDisabled(prevValues => ({ ...prevValues, saveButton: false }));
  };

  const checkIfSubQuestionsEdited = (values: string[]) => {
    let isEdited = false;
    if (
      contentPiece.subQuestions &&
      contentPiece.subQuestions.length === values.length
    ) {
      for (let i = 0; i < values.length; i++) {
        if (contentPiece.subQuestions[i] !== values[i]) isEdited = true;
      }
    } else {
      isEdited = true;
    }
    return isEdited;
  };

  const handleOnClickSummary = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-summary-${contentPiece.id}`,
      name: 'summary',
      label: 'Summary',
      onChange: handleChangeSummary,
      initialValue: values.summary,
    });
  };

  const handleOnClickFeedback = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-feedback-${contentPiece.id}`,
      name: 'feedback',
      label: 'Feedback',
      onChange: handleChangeFeedback,
      initialValue: values.feedback,
    });
  };

  const handleOnClickReasonForQuestion = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-reason-for-question-${contentPiece.id}`,
      name: 'reasonForQuestion',
      label: 'Reason For Question',
      onChange: handleChangeReasonForQuestion,
      initialValue: values.reasonForQuestion,
    });
  };

  const handleOnClickMainQuestion = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-main-question-${contentPiece.id}`,
      name: 'mainQuestion',
      label: 'Main Question',
      onChange: handleChangeMainQuestion,
      initialValue: values.mainQuestion,
    });
  };

  const handleOnClickSubQuestions = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-sub-questions-${contentPiece.id}`,
      name: 'subQuestions',
      label: 'Sub Questions',
      onChange: handleChangeSubQuestions,
      initialValue: values.subQuestions.join(';'),
    });
  };

  const key = 'iqcli';
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.softSkill?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.trait?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>
          {
            classificationOptions.filter(
              classification =>
                classification.value === contentPiece.classification
            )[0].label
          }
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>
          {
            roleLevelOptions.filter(
              roleLevel => roleLevel.value === contentPiece.roleLevel
            )[0].label
          }
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <TextFormFieldWithActionIcon
          id={`${key}-summary-${contentPiece.id}`}
          name="summary"
          label="Summary"
          onChange={handleChangeSummary}
          value={values.summary}
          isDisabled={isDisabled.summary}
          onClick={handleOnClickSummary}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFormFieldWithActionIcon
          id={`${key}-feedback-${contentPiece.id}`}
          name="feedback"
          label="Feedback"
          onChange={handleChangeFeedback}
          value={values.feedback ? values.feedback : ''}
          isDisabled={isDisabled.feedback}
          onClick={handleOnClickFeedback}
        />
      </Grid>
      <Grid item xs={2}>
        <TextFormFieldWithActionIcon
          id={`${key}-reasonForQuestion-${contentPiece.id}`}
          name="reasonForQuestion"
          label="ReasonForQuestion"
          onChange={handleChangeReasonForQuestion}
          value={values.reasonForQuestion}
          isDisabled={isDisabled.reasonForQuestion}
          onClick={handleOnClickReasonForQuestion}
        />
      </Grid>
      <Grid item xs={2}>
        <TextFormFieldWithActionIcon
          id={`${key}-mainQuestion-${contentPiece.id}`}
          name="mainQuestion"
          label="MainQuestion"
          onChange={handleChangeMainQuestion}
          value={values.mainQuestion}
          isDisabled={isDisabled.mainQuestion}
          onClick={handleOnClickMainQuestion}
        />
      </Grid>
      <Grid item xs={1}>
        <TextFormFieldWithActionIcon
          id={`${key}-subQuestions-${contentPiece.id}`}
          name="subQuestions"
          label="SubQuestions"
          onChange={handleChangeSubQuestions}
          value={values.subQuestions.join(';')}
          isDisabled={isDisabled.subQuestions}
          onClick={handleOnClickSubQuestions}
        />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={handleSubmit} isDisabled={isDisabled.saveButton}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default InterviewQuestionContentListItem;
