import { appRoutes, projectRequestsRoutes } from 'constants/navigation';
import { ProjectRequestsViewContextProvider } from 'contexts/ProjectRequestContext';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectRequest from './ProjectRequest';
import ProjectRequestCreate from './ProjectRequestCreate';
import ProjectRequestsView from './ProjectRequestsView';

interface ProjectRequestsProps {}

const ProjectRequests: FC<ProjectRequestsProps> = () => (
  <ProjectRequestsViewContextProvider>
    <Switch>
      <Route exact path={appRoutes.projectRequests.path}>
        <ProjectRequestsView />
      </Route>
      <Route exact path={projectRequestsRoutes.createProjectRequest.path}>
        <ProjectRequestCreate />
      </Route>
      <Route path={projectRequestsRoutes.projectRequest.path}>
        <ProjectRequest />
      </Route>
    </Switch>
  </ProjectRequestsViewContextProvider>
);

export default ProjectRequests;
