import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../components/atoms/Button/Button';
import TextFormFieldWithActionIcon from '../../../../../components/organisms/TextFormFieldWithActionIcon/TextFormFieldWithActionIcon';
import { classificationOptions } from '../../../../../constants/classification';
import { roleLevelOptions } from '../../../../../constants/roleLevel';
import { ModalTypes } from '../../../../../enums/modalTypes';
import {
  TraitRoleLevelContent,
  useSaveTraitContentMutation,
} from '../../../../../generated/graphql';
import { openModal } from '../../../../../graphql/modals';
import { TFormFieldValue } from '../../../../../interfaces/forms/form';
import useTraitContentListItemStyle from './TraitContentListItemStyle';

interface ITraitContentListItem {
  contentPiece: TraitRoleLevelContent;
}

interface ITraitContentFieldValues {
  summary: string;
  content: string;
  developmentContent: string;
}

interface ITraitContentFieldDisabledValues {
  summary: boolean;
  content: boolean;
  developmentContent: boolean;
  saveButton: boolean;
}

const TraitContentListItem: FC<ITraitContentListItem> = ({ contentPiece }) => {
  const { handleMsgType } = useNotification();

  const classes = useTraitContentListItemStyle();
  const [values, setValues] = useState<ITraitContentFieldValues>({
    summary: contentPiece.summary ? contentPiece.summary : '',
    content: contentPiece.content ? contentPiece.content : '',
    developmentContent: contentPiece.developmentContent
      ? contentPiece.developmentContent
      : '',
  });
  const [isDisabled, setIsDisabled] = useState<
    ITraitContentFieldDisabledValues
  >({
    summary: false,
    content: false,
    developmentContent: false,
    saveButton: true,
  });
  const [saveTraitContentMutation] = useSaveTraitContentMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setIsDisabled({
        summary: false,
        content: false,
        developmentContent: false,
        saveButton: true,
      });
    },
  });

  const handleChangeSummary = (value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, summary: value as string }));
    checkIfEdited({ ...values, summary: value as string });
  };

  const handleChangeContent = (value: TFormFieldValue) => {
    setValues(prevValues => ({ ...prevValues, content: value as string }));
    checkIfEdited({ ...values, content: value as string });
  };

  const handleChangeDevelopmentContent = (value: TFormFieldValue) => {
    setValues(prevValues => ({
      ...prevValues,
      developmentContent: value as string,
    }));
    checkIfEdited({ ...values, developmentContent: value as string });
  };

  const handleSubmit = () => {
    setIsDisabled({
      summary: true,
      content: true,
      developmentContent: true,
      saveButton: true,
    });
    saveTraitContent(values);
  };

  const saveTraitContent = (values: ITraitContentFieldValues) => {
    const id = contentPiece.id as number;
    const summary = values.summary ? values.summary : contentPiece.summary;
    const content = values.content ? values.content : contentPiece.content;
    const developmentContent = values.developmentContent
      ? values.developmentContent
      : contentPiece.developmentContent;
    saveTraitContentMutation({
      variables: {
        id,
        summary,
        content,
        developmentContent,
      },
    });
  };

  // Disables button if no edit is made
  const checkIfEdited = (values: ITraitContentFieldValues): void => {
    if (
      values.summary === '' ||
      values.content === '' ||
      values.developmentContent === ''
    ) {
      if (!isDisabled.saveButton)
        setIsDisabled(prevValues => ({ ...prevValues, saveButton: true }));
      return;
    }
    if (
      contentPiece.summary === values.summary &&
      contentPiece.content === values.content &&
      contentPiece.developmentContent === values.developmentContent
    ) {
      if (!isDisabled.saveButton)
        setIsDisabled(prevValues => ({ ...prevValues, saveButton: true }));
      return;
    }

    if (isDisabled.saveButton)
      setIsDisabled(prevValues => ({ ...prevValues, saveButton: false }));
  };

  const handleOnClickSummary = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-summary-${contentPiece.id}`,
      name: 'summary',
      label: 'Summary',
      onChange: handleChangeSummary,
      initialValue: values.summary,
    });
  };

  const handleOnClickContent = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-content-${contentPiece.id}`,
      name: 'content',
      label: 'Content',
      onChange: handleChangeContent,
      initialValue: values.content,
    });
  };

  const handleOnClickDevelopmentContent = () => {
    openModal(ModalTypes.TEXT_FIELD_MODAL, {
      id: `${key}-development-content-${contentPiece.id}`,
      name: 'developmentContent',
      label: 'Development feedback',
      onChange: handleChangeDevelopmentContent,
      initialValue: values.developmentContent,
    });
  };

  const key = 'tcli';
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.softSkill?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>{contentPiece.trait?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>
          {
            classificationOptions.filter(
              classification =>
                classification.value === contentPiece.classification
            )[0].label
          }
        </Typography>
      </Grid>
      <Grid item xs={1} className={classes.gridItemWrapper}>
        <Typography>
          {
            roleLevelOptions.filter(
              roleLevel => roleLevel.value === contentPiece.roleLevel
            )[0].label
          }
        </Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-summary-${contentPiece.id}`}
          name="summary"
          label="Summary"
          onChange={handleChangeSummary}
          value={values.summary}
          isDisabled={isDisabled.summary}
          onClick={handleOnClickSummary}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItemTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-content-${contentPiece.id}`}
          name="content"
          label="Content"
          onChange={handleChangeContent}
          value={values.content}
          isDisabled={isDisabled.content}
          onClick={handleOnClickContent}
        />
      </Grid>
      <Grid item xs={2} className={classes.gridItemTextArea}>
        <TextFormFieldWithActionIcon
          id={`${key}-development-content-${contentPiece.id}`}
          name="developmentContent"
          label="Development feedback"
          onChange={handleChangeDevelopmentContent}
          value={values.developmentContent}
          isDisabled={isDisabled.developmentContent}
          onClick={handleOnClickDevelopmentContent}
        />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={handleSubmit} isDisabled={isDisabled.saveButton}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default TraitContentListItem;
