import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import Button from 'components/atoms/Button/Button';
import { SoftSkillFragment } from 'generated/graphql';

export interface ISoftSkillFormHeaderPresentational {
  softSkill?: SoftSkillFragment;
  isEditMode: boolean;
  handleArchive: () => void;
  handleDelete: () => void;
}

const SoftSkillFormHeaderPresentational: FC<ISoftSkillFormHeaderPresentational> = ({
  softSkill,
  isEditMode,
  handleArchive,
  handleDelete,
}) => {
  return (
    <Grid container item>
      {isEditMode ? (
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={10}
        >
          <Grid item>
            <Button onClick={handleArchive}>
              {softSkill?.isArchived ? 'Unarchive' : 'Archive'}
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="h6">Edit soft skill</Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleDelete}>Delete</Button>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h6">Add soft skill</Typography>
      )}
    </Grid>
  );
};

export default SoftSkillFormHeaderPresentational;
