import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

interface IConnectedTraitsListHeader {
  isEditModeActive: boolean;
}
const ConnectedTraitsListHeader: FC<IConnectedTraitsListHeader> = ({
  isEditModeActive,
}) => {
  return (
    <>
      {isEditModeActive ? (
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={4}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Weight</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Orientation</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={2}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Weight</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">Orientation</Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      )}
    </>
  );
};

export default ConnectedTraitsListHeader;
