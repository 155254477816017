import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

interface ICompanyRequirementsArchivedFieldsList {
  archivedFields: { id: number; name: string }[];
}

const CompanyRequirementsArchivedFieldsList: FC<ICompanyRequirementsArchivedFieldsList> = ({
  archivedFields,
}) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h6">Archived</Typography>
      </Grid>
      {archivedFields.map(field => (
        <Grid item key={field.id}>
          <Typography>{field.name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default CompanyRequirementsArchivedFieldsList;
