import Snackbar from 'components/atoms/Snackbar/Snackbar';
import {
  ProjectModuleType,
  TestSubType,
  TestType,
  useGetProjectByIdQuery,
  useTestCandidateFindManyByCandidateQuery,
} from 'generated/graphql';
import { ISnackBar, TAlertTypes } from 'interfaces/snackBar';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import ActionsResultTable from './ActionsResultTable/ActionsResultTable';
import StageReset from './StageReset/StageReset';
import StageResult from './StageResult/StageResult';
import { IFilteredTestCandidate, IUserReset } from './UserResets.interface';

const UserReset: FC<IUserReset> = ({
  userInfo,
  stageId,
  stageName,
  stageStatus,
  projectId,
  lastDateOfCandidateAction,
  scores,
  stageCandidateId,
  stageCandidateSubId,
  doneBy,
  doneFor,
  rssAssessmentCandidates,
  formCandidates,
}) => {
  const { handleMsgType } = useNotification();

  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
    alertType: 'success',
  });

  const getProjectByIdResponse = useGetProjectByIdQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      id: projectId,
    },
  });

  const TestCandidateFindManyByCandidateResponse = useTestCandidateFindManyByCandidateQuery(
    {
      variables: {
        candidateId: Number(userInfo?.id) || 0,
      },
      fetchPolicy: 'network-only',
    }
  );

  const filteredTestCandidate: IFilteredTestCandidate[] =
    TestCandidateFindManyByCandidateResponse.data?.testResult
      ?.filter(item => {
        return item.test?.stageId === stageId;
      })
      .map(item => ({
        id: item.id,
        tpAssessmentId: item.assessmentId,
        status: item.status,
        testId: item.testId,
        test: {
          tpProjectId: item.test?.tpProjectId || '',
          type: item.test?.type || TestType.Cognitive,
          subType: item.test?.subType || TestSubType.Inductive,
          stageId: item.test?.stageId || 0,
        },
        testCandidateId: item?.testResult?.testCandidateId,
        testResult: {
          updatedAt: item.testResult?.updatedAt,
          scores: item.testResult?.scores.map(item => ({
            id: item.id,
            score: {
              zScore: item.score.zScore,
              percentile: item.score.percentile,
              stenScore: item.score.stenScore,
              grade: item.score.grade,
            },
          })),
        },
        timeModifierPercentage: item.timeModifierPercentage,
      })) || [];

  const displaySnackbar = (msg: string, alertType: TAlertTypes = 'success') => {
    setSnackbar({ show: true, msg, alertType });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  return (
    <>
      <StageResult
        companyName={getProjectByIdResponse.data?.project?.company?.name}
        companyId={getProjectByIdResponse.data?.project?.company?.id}
        projectName={getProjectByIdResponse.data?.project?.name}
        projectId={getProjectByIdResponse.data?.project?.id}
        stageName={stageName}
        stageId={stageId}
        stageCandidateId={stageCandidateId}
        stageCandidateSubId={stageCandidateSubId}
        stageStatus={stageStatus}
        lastDateOfCandidateAction={lastDateOfCandidateAction}
        scores={scores}
      />
      <ActionsResultTable
        projectName={getProjectByIdResponse.data?.project?.name}
        userInfo={userInfo}
        stageId={stageId}
        stageStatus={stageStatus}
        filteredTestCandidate={filteredTestCandidate}
        displaySnackbar={displaySnackbar}
        rssAssessmentCandidates={rssAssessmentCandidates}
        stageCandidateId={stageCandidateId}
        formCandidates={formCandidates}
      />
      <StageReset
        userInfo={userInfo}
        companyName={getProjectByIdResponse.data?.project?.company?.name}
        projectName={getProjectByIdResponse.data?.project?.name}
        stageCandidateSubId={stageCandidateSubId}
        stageId={stageId}
        projectId={projectId}
        stageStatus={stageStatus}
        displaySnackbar={displaySnackbar}
        stageCandidateId={stageCandidateId}
        projectModuleType={
          getProjectByIdResponse.data?.project?.moduleType ||
          ProjectModuleType.Hiring
        }
        doneBy={doneBy}
        doneFor={doneFor}
      />
      {snackbar.show && (
        <Snackbar
          open={snackbar.show}
          alertType={snackbar?.alertType || 'success'}
          snackbarMsg={snackbar.msg}
          handleClose={handleCloseSnackbar}
        />
      )}
    </>
  );
};

export default UserReset;
