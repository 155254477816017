import { FC, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';

interface IValidateHeader {}

const ValidateHeader: FC<IValidateHeader> = () => {
  const { filters, updateFilters } = useContext(StaticContentContext);
  const openStaticContentFiltersModal = () => {
    openModal(ModalTypes.STATIC_CONTENT_FILTERS_MODAL, {
      isSoftSkillFilterIncluded: true,
      isTraitFilterIncluded: true,
      filters: filters,
      onSubmit: updateFilters,
    });
  };

  const openValidateContentModal = () => {
    openModal(ModalTypes.VALIDATE_CONTENT_MODAL, {});
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6}>
        <Button onClick={openStaticContentFiltersModal}>Filter/Sort</Button>
      </Grid>
      <Grid item xs={6}>
        <Button onClick={openValidateContentModal}>Check content</Button>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Soft Skill</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Trait</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Soft skill content</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Success profile content</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Trait content</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h6">Interview question content</Typography>
      </Grid>
    </Grid>
  );
};

export default ValidateHeader;
