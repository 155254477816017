import { contentRoutes } from '../../constants/navigation';

export const contentSidebarConfig = [
  {
    title: 'Soft skills',
    path: contentRoutes.softSkills.path,
  },
  {
    title: 'Traits',
    path: contentRoutes.traits.path,
  },
  {
    title: 'Soft skill content',
    path: contentRoutes.softSkillContent.path,
  },
  {
    title: 'Success profile content',
    path: contentRoutes.softSkillSuccessProfileContent.path,
  },
  {
    title: 'Trait content',
    path: contentRoutes.traitContent.path,
  },
  {
    title: 'Interview question content',
    path: contentRoutes.interviewQuestionContent.path,
  },
  {
    title: 'Validate',
    path: contentRoutes.validate.path,
  },
  {
    title: 'Technical skills',
    path: contentRoutes.technicalSkills.path,
  },
  {
    title: 'Behaviour content',
    path: contentRoutes.behaviourContent.path,
  },
];
