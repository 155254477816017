import _ from 'lodash';
import { FC, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { FormManagerType, FormType } from '../../../../../enums/forms/form';
import {
  FORM_BUILDER_UPDATE_MUTATION,
  GET_FORM_BUILDER_QUERY,
} from '../../../../../graphql/form/formBuilder';
import { FieldsFragments } from '../../../../../graphql/form/fragments';
import { ICandidateInformationFieldRequirementsFormSubmitValues } from '../../../../../interfaces/forms/companyRequirementsFormBuilderForm';
import {
  IFormBuilderUpdateMutationInput,
  IFormBuilderUpdateMutationResponse,
  TFieldGQL,
  TFormFieldGQL,
} from '../../../../../interfaces/forms/formGQL';
import CompanyRequirementsArchivedFieldsList from './CompanyRequirementsArchivedFieldsList/CompanyRequirementsArchivedFieldsList';
import CompanyRequirementsFormBuilderForm from './CompanyRequirementsFormBuilderForm/CompanyRequirementsFormBuilderForm';

interface ICompanyRequirementsFormBuilder {
  formOwnedFields: TFormFieldGQL[];
  availableFields: TFieldGQL[];
  companyId: number;
  archivedFields: {
    id: number;
    name: string;
  }[];
  companyRequirementsFormId: number;
  formFilter: FormType;
}

const CompanyRequirementsFormBuilder: FC<ICompanyRequirementsFormBuilder> = ({
  formOwnedFields,
  availableFields,
  archivedFields,
  companyId,
  companyRequirementsFormId,
  formFilter,
}) => {
  const { handleMsgType } = useNotification();

  const client = useApolloClient();
  const [companyRequirementsFormBuilderUpdate] = useMutation<
    IFormBuilderUpdateMutationResponse,
    IFormBuilderUpdateMutationInput
  >(FORM_BUILDER_UPDATE_MUTATION, {
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update(cache, { data }) {
      if (data) {
        cache.writeQuery({
          query: GET_FORM_BUILDER_QUERY,
          variables: {
            formType: formFilter,
            formManagerType:
              formFilter === FormType.TR_FORM
                ? FormManagerType.TR
                : FormManagerType.CI,
            formOwnerId: companyId,
          },
          data: {
            formBuilder: data.formBuilder,
          },
        });
      }
    },
  });

  const formInitialValues = useMemo(
    () => ({
      fieldValues: {
        ...formOwnedFields.reduce((acc, curr) => {
          // Admin should be able to see if selected company is owner of field.
          // TFormFieldGQL type does not contain information about fieldOwner.
          const cachedField = client.readFragment<TFieldGQL>({
            id: `${curr.field.__typename}:${curr.field.id}`,
            fragment: FieldsFragments.field(curr.field),
          });

          return {
            ...acc,
            [curr.field.id]: {
              id: curr.field.id,
              name: curr.field.name,
              isOwnedByCompany: cachedField
                ? cachedField.fieldOwnerId === companyId
                : false,
              isOptional: curr.isOptional,
            },
          };
        }, {}),
        ...availableFields.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: {
              id: curr.id,
              name: curr.name,
              isOwnedByCompany: curr.fieldOwnerId === companyId,
              isOptional: true,
            },
          };
        }, {}),
      },
      order: {
        used: [...formOwnedFields]
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map(formOwnedField => formOwnedField.field.id),
        notUsed: availableFields.map(availableField => availableField.id),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formOwnedFields, availableFields, companyId]
  );

  const handleUpdateCompanyRequirementsForm = (
    formValues: ICandidateInformationFieldRequirementsFormSubmitValues
  ) => {
    // Fields which was in "not used" column and moved to
    // "used" column, will be added to company requirements form.
    const addedFields = _.difference(
      formValues.order.used,
      formInitialValues.order.used
    ).map(candidateInfoFieldId => {
      const orderIndex = formValues.order.used.findIndex(
        id => id === candidateInfoFieldId
      );
      return {
        displayOrder: orderIndex,
        isOptional: formValues.fieldValues[candidateInfoFieldId].isOptional,
        fieldId: candidateInfoFieldId,
      };
    });

    // Fields which was already in "used" column and position (order) or requirement
    // status of that filed is changed, will be updated.
    const updatedFields = formValues.order.used
      .map((candidateInfoFieldId, orderIndex) => ({
        fieldId: candidateInfoFieldId,
        isOptional: formValues.fieldValues[candidateInfoFieldId].isOptional,
        displayOrder: orderIndex,
      }))
      .filter(
        updatedField =>
          !addedFields.find(
            addedField => addedField.fieldId === updatedField.fieldId
          )
      );

    // Fields which was moved from "used" column to "not used" column
    // will be removed from company requirements form.
    const removedFields = _.difference(
      formValues.order.notUsed,
      formInitialValues.order.notUsed
    ).map(candidateInfoFieldId => ({
      fieldId: candidateInfoFieldId,
    }));

    companyRequirementsFormBuilderUpdate({
      variables: {
        id: companyRequirementsFormId,
        addFields: addedFields,
        updateFields: updatedFields,
        removeFields: removedFields,
      },
    });
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <CompanyRequirementsFormBuilderForm
        initialValues={formInitialValues}
        onSubmit={handleUpdateCompanyRequirementsForm}
        companyId={companyId}
      />
      <CompanyRequirementsArchivedFieldsList archivedFields={archivedFields} />
    </Grid>
  );
};

export default CompanyRequirementsFormBuilder;
