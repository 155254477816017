import React, { FC, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IgStep,
  useIgStepFindManyQuery,
  useIgStepUpdateMutation,
} from '../../../generated/graphql';
import { openModal } from '../../../graphql/modals';
import IgStepsDnD from './IgStepsDnD/IgStepsDnD';
import { useIgStepsStyle } from './IgStepsStyle';

interface IInterviewGuideSteps {
  interviewGuideId: number;
}
const InterviewGuideSteps: FC<IInterviewGuideSteps> = ({
  interviewGuideId,
}) => {
  const classes = useIgStepsStyle();
  const { handleMsgType } = useNotification();

  const [igSteps, setIgSteps] = useState<IgStep[]>([]);
  const [IgStepUpdateMutation] = useIgStepUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const igStepsFindManyQueryResponse = useIgStepFindManyQuery({
    variables: {
      interviewGuideId,
      isArchived: false,
    },
    onCompleted: data => {
      setIgSteps(data.IgStepFindMany as IgStep[]);
    },
  });
  const igStepFindManyArchivedQueryResponse = useIgStepFindManyQuery({
    variables: {
      interviewGuideId,
      isArchived: true,
    },
  });
  const handleAddIgStep = () => {
    if (igStepsFindManyQueryResponse.data) {
      const igSteps = igStepsFindManyQueryResponse.data
        .IgStepFindMany as IgStep[];
      openModal(ModalTypes.IG_STEP_CONFIG_MODAL, {
        lastIgStepOrder:
          igSteps.length > 0 ? igSteps[igSteps.length - 1].order : null,
        interviewGuideId,
      });
    }
  };
  const sortIgSteps = (igSteps: IgStep[]) => {
    if (igSteps.length > 0)
      return [...igSteps].sort((a: IgStep, b: IgStep) => {
        if (a?.order && b?.order) {
          return a.order < b.order ? -1 : 1;
        } else return 0;
      });
    else return [];
  };

  const reOrderIgSteps = (list: IgStep[]) => {
    const updatedReOrderedIgSteps = list.map((item, index) => {
      const variables = {
        id: item.id,
        order: index + 1,
        interviewGuideId,
      };
      IgStepUpdateMutation({ variables });
      return { ...item, order: index + 1 };
    });
    setIgSteps(updatedReOrderedIgSteps);
  };
  if (igStepsFindManyQueryResponse.loading) {
    return <CircularProgress />;
  }
  if (igStepsFindManyQueryResponse.error) {
    return <div>{igStepsFindManyQueryResponse.error.message}</div>;
  }

  if (igSteps) {
    const sortedIgSteps = sortIgSteps(igSteps);
    return (
      <div className={classes.container}>
        <Button
          color="primary"
          className={classes.btn}
          onClick={handleAddIgStep}
        >
          Add Step
        </Button>
        <IgStepsDnD
          igSteps={sortedIgSteps}
          interviewGuideId={interviewGuideId}
          igArchivedSteps={
            igStepFindManyArchivedQueryResponse.data?.IgStepFindMany as IgStep[]
          }
          reOrderIgSteps={reOrderIgSteps}
        />
      </div>
    );
  }
  return null;
};

export default InterviewGuideSteps;
