import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { FormManagerType, FormType } from '../../../generated/graphql';
import { IFormOwner } from '../../../interfaces/formReport';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import FormManagerDownloadCSV from './FormManagerDownloadCSV/FormManagerDownloadCSV';
import FormAssociatesSelectFormFieldWrapper from './FormManagerSelectFormFields/FormAssociatesSelectFormFieldWrapper/FormAssociatesSelectFormFieldWrapper';
import FormManagerTypesSelectFormField from './FormManagerSelectFormFields/FormManagerTypesSelectFormField/FormManagerTypesSelectFormField';
import FormOwnersSelectFormFieldWrapper from './FormManagerSelectFormFields/FormOwnersSelectFormFieldWrapper/FormOwnersSelectFormFieldWrapper';
import FormTypesSelectFormFieldWrapper from './FormManagerSelectFormFields/FormTypesSelectFormFieldWrapper/FormTypesSelectFormFieldWrapper';
import useFormManagerStyle from './FormManagerStyle';

export interface IFormManagerValues {
  formManagerType?: FormManagerType;
  formType?: FormType;
  formOwner?: IFormOwner;
  formAssociate?: number;
}

interface IFormManager {}

const FormManager: FC<IFormManager> = () => {
  const classes = useFormManagerStyle();
  const [values, setValues] = useState<IFormManagerValues>();

  const handleFormManagerTypeChange = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setValues({
      [name]: value,
      formType: undefined,
      formOwner: undefined,
      formAssociate: undefined,
    });
  };

  const handleFormTypeChange = (value: TSelectFormFieldValue, name: string) => {
    setValues(prevValues => ({
      formManagerType: prevValues?.formManagerType,
      [name]: value,
      formOwner: undefined,
      formAssociate: undefined,
    }));
  };

  const handleFormOwnerChange = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setValues(prevValues => ({
      formManagerType: prevValues?.formManagerType,
      formType: prevValues?.formType,
      [name]: value,
      formAssociate: undefined,
    }));
  };

  const handleFormAssociateChange = (
    value: TSelectFormFieldValue,
    name: string
  ) => {
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      className={classes.container}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className={classes.container}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={2}>
            <FormManagerTypesSelectFormField
              id="formManagerTypes-id"
              name="formManagerType"
              value={values?.formManagerType}
              label="Form Manager Types"
              onChange={handleFormManagerTypeChange}
            />
          </Grid>
          <Grid item xs={2}>
            <FormTypesSelectFormFieldWrapper
              id="formType-id"
              name="formType"
              value={values?.formType}
              label="Form Type"
              selectedFormManagerType={values?.formManagerType}
              onChange={handleFormTypeChange}
            />
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={2}>
              <FormOwnersSelectFormFieldWrapper
                id="formOwner-id"
                name="formOwner"
                value={values?.formOwner?.name}
                selectedFormManagerType={values?.formManagerType}
                selectedFormType={values?.formType}
                label="Form Owner"
                onChange={handleFormOwnerChange}
              />
            </Grid>
            <Grid item xs={2}>
              <FormAssociatesSelectFormFieldWrapper
                id="formAssociates-id"
                name="formAssociate"
                value={values?.formAssociate}
                label="Form Associates"
                selectedFormType={values?.formType}
                formOwner={values?.formOwner}
                selectedFormManagerType={values?.formManagerType}
                onChange={handleFormAssociateChange}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            spacing={2}
          >
            <FormManagerDownloadCSV values={values} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormManager;
