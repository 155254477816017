import _ from 'lodash';
import { FC } from 'react';
import { InputBaseComponentProps, Typography } from '@material-ui/core';
import { useTraitsQuery } from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import SelectFormField from '../SelectFormField';

import type { TraitsQuery } from '../../../../generated/graphql';
export interface ITraitSelectFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label?: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  isDisabled?: boolean;
  inputProps?: InputBaseComponentProps;
  traitsToBeExcluded?: number[];
}

const getTraitOptions = (
  traits: TraitsQuery['Traits'],
  traitsToBeExcluded?: number[]
) => {
  if (!traits) {
    return [];
  }

  const filteredTraits = traitsToBeExcluded
    ? traits.filter(trait => !traitsToBeExcluded.includes(trait.id))
    : traits;

  return _.sortBy(filteredTraits, 'name').map(trait => ({
    label: trait.name,
    value: trait.id,
  }));
};

const TraitSelectFormField: FC<ITraitSelectFormField> = ({
  traitsToBeExcluded,
  ...selectElementProps
}) => {
  const traitsQueryResponse = useTraitsQuery({
    variables: {
      isArchived: false,
    },
  });

  if (traitsQueryResponse.error) {
    return <Typography>Error</Typography>;
  }

  if (traitsQueryResponse.loading) {
    return <Typography>Loading traits..</Typography>;
  }

  if (traitsQueryResponse.data) {
    const traitsOptions = getTraitOptions(
      traitsQueryResponse.data.Traits,
      traitsToBeExcluded
    );

    return <SelectFormField {...selectElementProps} options={traitsOptions} />;
  }

  return null;
};

export default TraitSelectFormField;
