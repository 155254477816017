import { FC } from 'react';
import SidebarNavigation from '../../components/organisms/SidebarNavigation/SidebarNavigation';
import { apiKeysSidebarConfig } from './ApiKeysNavigation.constants';

interface IApiKeysNavigation {}

const ApiKeysNavigation: FC<IApiKeysNavigation> = () => {
  return <SidebarNavigation routes={apiKeysSidebarConfig} />;
};

export default ApiKeysNavigation;
