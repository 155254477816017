import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { SoftSkill } from '../../../../generated/graphql';
import SoftSkillsListItem from './SoftSkillsListItem/SoftSkillsListItem';
import useSoftSkillsListStyle from './SoftSkillsListStyle';

interface ISoftSkillsList {
  softSkills: SoftSkill[];
}

const SoftSkillsList: FC<ISoftSkillsList> = ({ softSkills }) => {
  const classes = useSoftSkillsListStyle();
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={1} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Soft Skill ID</Typography>
        </Grid>
        <Grid item xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Name</Typography>
        </Grid>
        <Grid item xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Description</Typography>
        </Grid>
        <Grid item xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Category</Typography>
        </Grid>
        <Grid item xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Owned by Company</Typography>
        </Grid>
      </Grid>

      {softSkills.map(softSkill => (
        <SoftSkillsListItem softSkill={softSkill} key={softSkill.id} />
      ))}
    </Grid>
  );
};

export default SoftSkillsList;
