import { appRoutes } from 'constants/navigation';
import { useGetProjectByIdQuery } from 'generated/graphql';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export type LocationState = {
  from?: Location;
  projectId?: number;
  projectJobRoleSkillsProfileId?: number;
};

export const useGetProjectFromLocation = () => {
  const location = useLocation<LocationState>();
  const { handleMsgType } = useNotification();

  const projectPage = location.state?.from?.pathname.includes(
    appRoutes.projects.path
  );

  const projectId = location.state?.projectId;
  const projectQueryResponse = useGetProjectByIdQuery({
    variables: { id: projectId as number },
    skip: !projectId,
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  return {
    projectPath: projectPage ? location.state?.from?.pathname : undefined,
    projectQueryResponse: projectPage ? projectQueryResponse : undefined,
  };
};
