import { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ContentValidatiorPieceModel } from '../../../../generated/graphql';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';
import ValidateListItem from './ValidateListItem/ValidateListItem';

interface IValidateList {
  content: ContentValidatiorPieceModel[];
  count: number;
}

const ValidateList: FC<IValidateList> = ({ content, count }) => {
  const rowsPerPage = 50;
  const { filters, updateFilters } = useContext(StaticContentContext);
  const [page, setPage] = useState(1);

  const handlePageChange = (_event: unknown, value: number) => {
    setPage(value);
    updateFilters({ ...filters, page: value, take: rowsPerPage });
  };

  useEffect(() => {
    setPage(filters.page ? filters.page : 1);
  }, [filters]);

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {content.map(row => {
        return (
          <ValidateListItem
            row={row}
            key={`${row.softSkillId}-${row.traitId}`}
          />
        );
      })}
      <Grid item>
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
};

export default ValidateList;
