import {
  StageCsvOptions,
  useStageCreateCsvReportMutation,
} from 'generated/graphql';
import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { usePollingBlobFindByNameQuery } from './StageCSV.helper';
import StageCSVFormErrorMessage from './StageCSVFormErrorMessage/StageCSVFormErrorMessage';
import StageCSVForm from './StageCSVFrom/StageCSVForm';
import { IStageCSVFormValues } from './StageCSVFrom/StageCSVForm.interface';
import useStageCSVFormStyle from './StageCSVFrom/StageCSVFormStyle';

// in miliseconds.
const POLLING_INTERVAL = 3 * 1000; // 3 seconds
const POLLING_TIMEOUT_INTERVAL = 5 * 60 * 1000; // 5 minutes

const StageCSV: FC = () => {
  const { handleMsgType } = useNotification();
  const classes = useStageCSVFormStyle();
  const {
    startPolling,
    isPolling,
    errorMessage,
  } = usePollingBlobFindByNameQuery({
    pollingInterval: POLLING_INTERVAL,
    pollingTimeoutInterval: POLLING_TIMEOUT_INTERVAL,
  });

  const [
    stageCreateCsvReportMutation,
    { error },
  ] = useStageCreateCsvReportMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      startPolling({
        name: data.StageCreateCsvReport.blobName,
        getOption: 'OR_NULL',
      });
    },
  });

  const handleCreateCSV = (values: IStageCSVFormValues) => {
    let variables;
    if (values && values?.options !== StageCsvOptions.Custom) {
      variables = {
        stageIds: values.stageIds,
        options: values?.options,
      };
    } else {
      variables = {
        stageIds: values.stageIds,
        options: values?.options,
        measurementConstructsToDisplay: values.measurementConstructsToDisplay,
        scoreTypesToDisplay: values.scoreTypesToDisplay,
        basicScoreTypesToDisplay: values.BasicScoreType,
        softSkillSort: values.SpSoftSkillSort || undefined,
      };
    }
    stageCreateCsvReportMutation({ variables });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      className={classes.container}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        className={classes.container}
      >
        <StageCSVForm onSubmit={handleCreateCSV} isLoading={isPolling} />
        {errorMessage ? (
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            spacing={2}
          >
            <Grid className={classes.buttonContainer} item xs={4}>
              <Typography color="error">{errorMessage}</Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid item>
          <StageCSVFormErrorMessage error={error} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StageCSV;
