import { jobRolesRoutes } from 'constants/navigation';
import {
  ProjectJobRoleFragment,
  useStageFindManyQuery,
} from 'generated/graphql';
import ENVELOP from 'icons/envelop.svg';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  TabListLegacy as TabList,
  TabPanelLegacy as TabPanel,
} from '@spotted-zebra-uk/ui-components';
import { AddJobRoleModal } from '../AddJobRoleModal/AddJobRoleModal';
import BenchmarkGroup from '../BenchmarkGroup/BenchmarkGroup';
import ProjectJobRoleRow from '../JobRoleRow/ProjectJobRoleRow';
import ProjectJobRolesOverviewSkillsList from '../ProjectJobRolesOverviewSkillsList/ProjectJobRolesOverviewSkillsList';
import { TSkillOverview } from '../ProjectJobRolesOverviewSkillsList/ProjectJobRolesOverviewSkillsList.types';
import styles from './ProjectJobRolesOverviewPresentational.module.scss';

enum SkillsTabs {
  SOFT_SKILLS = 'SOFT_SKILLS',
  TECHNICAL_SKILLS = 'TECHNICAL_SKILLS',
}

interface IProjectJobRolesOverviewPresentational {
  softSkills: TSkillOverview[];
  technicalSkills: TSkillOverview[];
  projectJobRoles: ProjectJobRoleFragment[];
  projectId: number;
  companyId: number;
}

const ProjectJobRolesOverviewPresentational: FC<IProjectJobRolesOverviewPresentational> = ({
  softSkills,
  technicalSkills,
  projectId,
  companyId,
  projectJobRoles,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: stagesData } = useStageFindManyQuery({
    variables: { projectId },
  });

  function openModal() {
    setModalOpen(true);
  }
  function closeModal() {
    setModalOpen(false);
  }

  const tabsOptions = [
    {
      title: 'Soft skills',
      value: SkillsTabs.SOFT_SKILLS,
      additionalInfo: softSkills.length,
    },
    {
      title: 'Technical skills',
      value: SkillsTabs.TECHNICAL_SKILLS,
      additionalInfo: technicalSkills.length,
    },
  ];

  const anySkillsExist = softSkills?.length || technicalSkills?.length;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div>
            <div className={styles.headerTitle}>Job roles and skills</div>
          </div>
        </div>

        <div className={styles.header}>
          <div className={styles.subheaderTitle}>Job roles</div>
          <div className={styles.subheaderActions}>
            <Button
              size="medium"
              variant="secondary"
              onClick={openModal}
              data-testid="add-job-role-btn"
            >
              Add
            </Button>
            <Link
              to={location => ({
                pathname: jobRolesRoutes.createJobRole.url(),
                state: { from: location, projectId },
              })}
            >
              <Button size="medium" data-role="create-job-role">
                Create
              </Button>
            </Link>
          </div>
        </div>
        <BenchmarkGroup
          projectJobRoles={projectJobRoles}
          stagesData={stagesData}
        />
        <div className={styles.jobRolesList} data-testid="job-roles-list">
          {projectJobRoles.map(projectJobRole => (
            <ProjectJobRoleRow
              key={projectJobRole.id}
              projectJobRole={projectJobRole}
              projectId={projectId}
            />
          ))}
        </div>

        <div className={styles.header}>
          <div className={styles.subheaderTitle}>Skills to assess</div>
        </div>

        {!anySkillsExist && (
          <div className={styles.containerEmpty}>
            <img src={ENVELOP} alt="Empty" />
            <p className={styles.containerEmptyText}>
              No skills in project skills profile yet
            </p>
          </div>
        )}

        {Boolean(anySkillsExist) && (
          <TabList initialValue={SkillsTabs.SOFT_SKILLS} options={tabsOptions}>
            <div className={styles.tabsPanel}>
              <TabPanel value={SkillsTabs.SOFT_SKILLS}>
                <ProjectJobRolesOverviewSkillsList
                  skills={softSkills}
                  emptyViewText="No soft skills"
                />
              </TabPanel>
              <TabPanel value={SkillsTabs.TECHNICAL_SKILLS}>
                <ProjectJobRolesOverviewSkillsList
                  skills={technicalSkills}
                  emptyViewText="No technical skills"
                />
              </TabPanel>
            </div>
          </TabList>
        )}
      </div>
      <AddJobRoleModal
        open={modalOpen}
        onClose={closeModal}
        projectId={projectId}
        companyId={companyId}
        excludedJobRoles={projectJobRoles}
      />
    </>
  );
};

export default ProjectJobRolesOverviewPresentational;
