import { contentRoutes, technicalSkillsRoutes } from 'constants/navigation';
import { Route, Switch } from 'react-router-dom';
import AddTechnicalSkillsView from './AddTechnicalSkills/AddTechnicalSkills.view';
import styles from './styles.module.scss';
import TechnicalSkillsHeader from './TechnicalSkillsHeader/TechnicalSkillsHeader';
import TechnicalSkillsList from './TechnicalSkillsList/TechnicalSkillsList';

const TechnicalSkills = () => {
  return (
    <div className={styles.container}>
      <Switch>
        <Route exact path={contentRoutes.technicalSkills.path}>
          <TechnicalSkillsHeader
            isArchivedVisible={false}
            setIsArchivedVisible={() => {
              console.log('archived');
            }}
          />
          <TechnicalSkillsList />
        </Route>
        <Route path={technicalSkillsRoutes.addNew.path}>
          <AddTechnicalSkillsView />
        </Route>
      </Switch>
    </div>
  );
};

export default TechnicalSkills;
