import { makeStyles } from '@material-ui/core/styles';

const useDeleteCompanyStyle = makeStyles(() => ({
  button: {
    width: 150,
    height: 48,
  },
}));

export default useDeleteCompanyStyle;
