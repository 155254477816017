import { FC } from 'react';
import EditCompanyNavigation from '../../../navigation/EditCompanyNavigation/EditCompanyNavigation';
import EditCompanySwitch from '../../../navigation/EditCompanyNavigation/EditCompanySwitch';

interface ICompanyView {}

const CompanyView: FC<ICompanyView> = () => {
  return (
    <>
      <EditCompanyNavigation />
      <div style={{ paddingLeft: 241 }}>
        <EditCompanySwitch />
      </div>
    </>
  );
};

export default CompanyView;
