import {
  CompaniesDocument,
  CompaniesQuery,
  CompanyFragment,
  ProjectCompanyFragment,
} from 'generated/graphql';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { sortBy } from 'lodash';
import { FC } from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import {
  FormikSelectWithQuery,
  IFormikSelectWithQuery,
} from '../FormikSelectWithQuery';
import SelectWithQuery from '../SelectWithQuery/SelectWithQuery';

interface ICompanySelectFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label?: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string | undefined;
  isDisabled?: boolean;
  inputProps?: InputBaseComponentProps;
  className?: string;
}

const CompanySelectFormField: FC<ICompanySelectFormField> = props => {
  const getOptionsFromResponse = (response: CompaniesQuery) => {
    if (response.Companies) {
      return [...response.Companies].map(company => ({
        label: company.name,
        value: company.id,
      }));
    }
    return [];
  };

  return (
    <SelectWithQuery
      query={CompaniesDocument}
      getOptionsFromResponse={getOptionsFromResponse}
      selectElementProps={props}
    />
  );
};

export default CompanySelectFormField;

interface ICompanyFormikSelectFormField
  extends Omit<
    IFormikSelectWithQuery<CompaniesQuery>,
    'query' | 'queryOptions' | 'getOptionsFromResponse'
  > {}

export const getValueFromCompany = (
  company: CompanyFragment | ProjectCompanyFragment
) => ({
  label: company.name,
  value: `${company.id}`,
});

export const CompanyFormikSelectFormField: FC<ICompanyFormikSelectFormField> = props => {
  const getOptionsFromResponse = (response: CompaniesQuery) => {
    if (response.Companies) {
      return sortBy(
        response.Companies.map(getValueFromCompany),
        companyOption => companyOption.label.toLocaleLowerCase()
      );
    }
    return [];
  };

  return (
    <FormikSelectWithQuery<CompaniesQuery>
      query={CompaniesDocument}
      getOptionsFromResponse={getOptionsFromResponse}
      hasClearIndicator={false}
      {...props}
    />
  );
};
