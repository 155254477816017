import { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { SoftSkillRoleLevelContent } from '../../../../generated/graphql';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';
import SoftSkillContentListItem from './SoftSkillContentListItem/SoftSkillContentListItem';

export interface ISoftSkillContentList {
  softSkillContent: SoftSkillRoleLevelContent[];
  count: number; // Number of content pieces in the database
}

const SoftSkillContentList: FC<ISoftSkillContentList> = ({
  softSkillContent,
  count,
}) => {
  const rowsPerPage = 50;
  const { filters, updateFilters } = useContext(StaticContentContext);
  const [page, setPage] = useState(1);

  const handlePageChange = (_event: unknown, value: number) => {
    setPage(value);
    updateFilters({ ...filters, page: value, take: rowsPerPage });
  };

  useEffect(() => {
    setPage(filters.page ? filters.page : 1);
  }, [filters]);

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
    >
      {softSkillContent.map(row => {
        return (
          <SoftSkillContentListItem
            contentPiece={row}
            key={`${row.softSkill?.name}-${row.roleLevel}-${row.grade}`}
          />
        );
      })}
      <Grid item>
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
};

export default SoftSkillContentList;
