import { FC } from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
  ISelectFormFieldOption,
  TSelectFormFieldValue,
} from '../../../interfaces/forms/form';

export interface ISelectFormField {
  id: string;
  name: string;
  label?: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean | undefined;
  errorText?: string | undefined;
  value: TSelectFormFieldValue;
  className?: string;
  isDisabled?: boolean;
  options: ISelectFormFieldOption[];
  option?: ISelectFormFieldOption[];
  hasNullValue?: boolean;
  noSort?: boolean;
  inputProps?: InputBaseComponentProps;
}

const SelectFormField: FC<ISelectFormField> = ({
  id,
  name,
  label,
  onChange,
  onBlur,
  hasError = false,
  errorText = '',
  value,
  isDisabled = false,
  options,
  className = '',
  hasNullValue = true,
  inputProps,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as TSelectFormFieldValue;
    onChange(newValue, name);
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value, name);
    }
  };
  let sortedOptions: ISelectFormFieldOption[] = [];

  if (options) {
    sortedOptions = [...options].sort((a, b) =>
      a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
    );
  }

  return (
    <FormControl variant="outlined" fullWidth error={hasError}>
      {label ? (
        <InputLabel
          style={{ backgroundColor: '#fff', padding: '0 5px' }}
          shrink={true}
        >
          {label}
        </InputLabel>
      ) : null}
      <Select
        native
        value={value}
        onChange={handleChange}
        disabled={isDisabled}
        label={label}
        className={className}
        inputProps={{
          ...inputProps,
          id,
          name,
          onBlur: handleBlur,
        }}
      >
        {hasNullValue ? <option value=""> </option> : null}
        {sortedOptions.map(option => (
          <option
            key={option.value || null}
            value={option.value || 0}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </Select>
      {hasError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default SelectFormField;
