import { FC } from 'react';
import SidebarNavigation from '../../components/organisms/SidebarNavigation/SidebarNavigation';
import { candidateSidebarConfig } from './CandidateInfoNavigation.constants';

interface ICandidateInfoNavigation {}

const CandidateInfoNavigation: FC<ICandidateInfoNavigation> = () => {
  return <SidebarNavigation routes={candidateSidebarConfig} />;
};

export default CandidateInfoNavigation;
