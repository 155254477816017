import React, { FC, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import {
  IgCategory,
  IgInterviewGuideCategory,
} from '../../../generated/graphql';
import InterviewGuideSteps from '../IgSteps/IgSteps';
import InterviewGuideDnD from '../InterviewGuideDnD/InterviewGuideDnD';

interface IInterviewGuideContainer {
  categories: IgCategory[];
  interviewGuideCategories: IgInterviewGuideCategory[];
  interviewGuideId: number;
  reOrderInterviewGuideCategories: (list: IgInterviewGuideCategory[]) => void;
}

const InterviewGuideContainer: FC<IInterviewGuideContainer> = ({
  categories,
  interviewGuideCategories,
  interviewGuideId,
  reOrderInterviewGuideCategories,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Categories" />
          <Tab label="Steps" />
          <Tab label="Content" />
        </Tabs>
      </Box>
      {value === 0 && (
        <InterviewGuideDnD
          categories={categories}
          interviewGuideCategories={interviewGuideCategories}
          interviewGuideId={interviewGuideId}
          reOrderInterviewGuideCategories={reOrderInterviewGuideCategories}
        />
      )}
      {value === 1 && (
        <InterviewGuideSteps interviewGuideId={interviewGuideId} />
      )}
      {value === 2 && (
        <div>
          <label>Content</label>
        </div>
      )}
    </Box>
  );
};

export default InterviewGuideContainer;
