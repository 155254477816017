import { makeStyles } from '@material-ui/core/styles';

const useSoftSkillsListItemStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
  gridItemWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  disabled: {},
}));

export default useSoftSkillsListItemStyle;
