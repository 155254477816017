import { ModalTypes } from 'enums/modalTypes';
import {
  useWidgetOptionDeleteMutation,
  useWidgetOptionUpdateIsArchivedMutation,
  WidgetDocument,
  WidgetOption,
} from 'generated/graphql';
import { openModal } from 'graphql/modals';
import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import SwitchFormField from '../../../components/molecules/SwitchFormField/SwitchFormField';
import useWidgetStyles from '../WidgetStyles';

interface IWidgetOptions {
  widgetOptions: WidgetOption[];
  id: number;
}

const WidgetOptions: FC<IWidgetOptions> = ({ widgetOptions, id }) => {
  const { handleMsgType } = useNotification();
  const classes = useWidgetStyles();
  const [isArchivedVisible, setIsArchivedVisible] = useState<boolean>(true);

  const [
    widgetOptionUpdateIsArchived,
  ] = useWidgetOptionUpdateIsArchivedMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const [deleteWidgetOptionMutation] = useWidgetOptionDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: WidgetDocument,
        variables: { id },
      },
    ],
  });

  const toggleArchivedProjectsVisibility = (value: boolean) => {
    setIsArchivedVisible(value);
  };

  const handleAddOption = () => {
    openModal(ModalTypes.ADD_WIDGET_OPTION_MODAL, { widgetId: id });
  };

  const handleUpdateWidgetOption = (widgetOptionItem: WidgetOption) => {
    openModal(ModalTypes.ADD_WIDGET_OPTION_MODAL, {
      widgetId: id,
      widgetOptionItem,
    });
  };

  const handleWidgetOptionArchive = (id?: number, isArchived?: boolean) => {
    if (id) {
      if (!isArchived) {
        widgetOptionUpdateIsArchived({
          variables: {
            id,
            isArchived: true,
          },
        });
      } else {
        widgetOptionUpdateIsArchived({
          variables: {
            id,
            isArchived: false,
          },
        });
      }
    }
  };

  const handleWidgetOptionDelete = (id?: number) => {
    if (id) deleteWidgetOptionMutation({ variables: { id } });
  };

  const hasArchivedWidgets = widgetOptions?.some(
    (item: WidgetOption) => item.isArchived === true
  );

  return (
    <>
      <Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          style={{ marginBottom: '15px' }}
        >
          <Grid item xs={3}>
            <Typography variant="h6" className={classes.title}>
              Widgets options
            </Typography>
          </Grid>
          <Grid item>
            <SwitchFormField
              id="toggle-displey-archived-projects"
              name="isArchivedVisible"
              color="primary"
              value={isArchivedVisible}
              label="Display Archived"
              onChange={toggleArchivedProjectsVisibility}
            />
          </Grid>
          <Grid item>
            <Button color="default" onClick={handleAddOption}>
              ADD OPTION
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {widgetOptions?.map((item: WidgetOption) => {
        if (!item.isArchived) {
          return (
            <Grid key={item.id} className={classes.mb10}>
              <div className={classes.widgetOption}>
                <p>{item.question}</p>
                <div className={classes.btnGroup}>
                  <Button
                    color="default"
                    className={classes.firstBtn}
                    onClick={() =>
                      handleWidgetOptionArchive(item?.id, item?.isArchived)
                    }
                  >
                    ARCHIVE
                  </Button>
                  <Button
                    className={classes.btn}
                    color="default"
                    onClick={() => handleUpdateWidgetOption(item)}
                  >
                    EDIT
                  </Button>
                </div>
              </div>
            </Grid>
          );
        }
        return null;
      })}
      {isArchivedVisible && (
        <Grid>
          {hasArchivedWidgets && (
            <Grid item xs={3}>
              <Typography variant="h6" className={classes.title}>
                Archived
              </Typography>
            </Grid>
          )}
          {widgetOptions?.map((item: WidgetOption) => {
            if (item.isArchived === true) {
              return (
                <Grid key={item.id} className={classes.mb10}>
                  <div className={classes.widgetOption}>
                    <p>{item.question}</p>
                    <div className={classes.btnGroup}>
                      <Button
                        color="default"
                        className={classes.firstBtn}
                        onClick={() =>
                          handleWidgetOptionArchive(item?.id, item?.isArchived)
                        }
                      >
                        UNARCHIVE
                      </Button>
                      <Button
                        className={classes.btn}
                        color="default"
                        onClick={() => handleWidgetOptionDelete(item?.id)}
                      >
                        DELETE
                      </Button>
                    </div>
                  </div>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      )}
    </>
  );
};

export default WidgetOptions;
