import { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import CircularLoader from '../../../components/molecules/CircularLoader/CircularLoader';
import { FIELDS_QUERY } from '../../../graphql/form/field';
import {
  IFieldsQueryInput,
  IFieldsQueryResponse,
} from '../../../interfaces/forms/formGQL';
import CandidateInfoFieldsHeader from './CandidateInfoFieldsHeader/CandidateInfoFieldsHeader';
import CandidateInfoFieldsList from './CandidateInfoFieldsList/CandidateInfoFieldsList';

interface ICandidateInfoFields {}

const CandidateInfoFields: FC<ICandidateInfoFields> = () => {
  const [isArchivedVisible, setIsArchivedVisible] = useState(false);

  const [
    candidateInfoFieldsQuery,
    candidateInfoFieldsQueryResponse,
  ] = useLazyQuery<IFieldsQueryResponse, IFieldsQueryInput>(FIELDS_QUERY);

  useEffect(() => {
    candidateInfoFieldsQuery({
      variables: {
        isArchived: isArchivedVisible ? undefined : false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchivedVisible]);

  if (candidateInfoFieldsQueryResponse.loading) {
    return <CircularLoader />;
  }

  if (candidateInfoFieldsQueryResponse.data) {
    const candidateInformationFields =
      candidateInfoFieldsQueryResponse.data.fields;

    return (
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <CandidateInfoFieldsHeader
          isArchivedVisible={isArchivedVisible}
          setIsArchivedVisible={setIsArchivedVisible}
        />
        <CandidateInfoFieldsList
          candidateInfoFileds={candidateInformationFields}
        />
      </Grid>
    );
  }

  return null;
};

export default CandidateInfoFields;
