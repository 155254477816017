import { appRoutes, usersRoutes } from '../../constants/navigation';

export const usersSidebarConfig = [
  {
    title: 'Search User',
    path: appRoutes.users.path,
  },
  {
    title: 'Retrieve tp details',
    path: usersRoutes.retrieveTpDetails.path,
  },
];
