import Button from 'components/atoms/Button/Button';
import {
  RssAssessmentCandidateFragment,
  RssAssessmentCandidateStatus,
  RssAssessmentType,
  useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation,
} from 'generated/graphql';
import { FC, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

const ACTION_STATUS_COMPLETED = 'COMPLETED';
const ACTION_STATUS_NOT_STARTED = 'NOT_STARTED';
const ACTION_STATUS_IN_PROGRESS = 'IN_PROGRESS';

const useStyle = makeStyles(() => ({
  tableCell: {
    fontSize: '16px',
  },
  tableActionsCell: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

const getActionStatus = (
  rssSoftSkillAssessmentCandidateStatus?: RssAssessmentCandidateStatus,
  rssTechnicalSkillAssessmentCandidateStatus?: RssAssessmentCandidateStatus
) => {
  if (!rssSoftSkillAssessmentCandidateStatus) {
    return rssTechnicalSkillAssessmentCandidateStatus ===
      RssAssessmentCandidateStatus.Submitted
      ? ACTION_STATUS_COMPLETED
      : ACTION_STATUS_NOT_STARTED;
  }

  if (!rssTechnicalSkillAssessmentCandidateStatus) {
    return rssSoftSkillAssessmentCandidateStatus ===
      RssAssessmentCandidateStatus.Submitted
      ? ACTION_STATUS_COMPLETED
      : ACTION_STATUS_NOT_STARTED;
  }

  if (
    rssSoftSkillAssessmentCandidateStatus ===
      RssAssessmentCandidateStatus.Submitted &&
    rssTechnicalSkillAssessmentCandidateStatus ===
      RssAssessmentCandidateStatus.Submitted
  ) {
    return ACTION_STATUS_COMPLETED;
  }

  if (
    rssSoftSkillAssessmentCandidateStatus ===
      RssAssessmentCandidateStatus.NotStarted &&
    rssTechnicalSkillAssessmentCandidateStatus ===
      RssAssessmentCandidateStatus.NotStarted
  ) {
    return ACTION_STATUS_NOT_STARTED;
  }

  return ACTION_STATUS_IN_PROGRESS;
};

interface IRssAssessmentActionResultRow {
  rssAssessmentCandidates: RssAssessmentCandidateFragment[];
  stageCandidateId: number;
}

const RssAssessmentActionResultRow: FC<IRssAssessmentActionResultRow> = ({
  rssAssessmentCandidates,
  stageCandidateId,
}) => {
  const classes = useStyle();
  const { handleMsgType } = useNotification();
  const [isSubmitting, setSubmitting] = useState(false);

  const rssSoftSkillAssessmentCandidate = rssAssessmentCandidates.find(
    rssAssessmentCandidate =>
      rssAssessmentCandidate.rssAssessment.type === RssAssessmentType.SoftSkill
  );

  const rssTechnicalSkillAssessmentCandidate = rssAssessmentCandidates.find(
    rssAssessmentCandidate =>
      rssAssessmentCandidate.rssAssessment.type ===
      RssAssessmentType.TechnicalSkill
  );

  const status = getActionStatus(
    rssSoftSkillAssessmentCandidate?.status,
    rssTechnicalSkillAssessmentCandidate?.status
  );

  const [
    rssAssessmentCandidateReOpenMutation,
  ] = useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation({
    variables: {
      stageCandidateId,
    },
  });

  const handleResetTest = () => {
    setSubmitting(true);
    try {
      rssAssessmentCandidateReOpenMutation();
      handleMsgType({
        type: TNotification.success,
        message: 'Role skills self-assessment reopened!',
      });
    } catch (error) {
      handleMsgType({
        type: TNotification.error,
        message:
          'Ops, some error is occurred during reopening skills self-assessment, please try again',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>SKILL SELF-ASSESSMENT</TableCell>
      <TableCell />
      <TableCell />
      <TableCell className={classes.tableCell}>{status}</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell className={classes.tableActionsCell}>
        <Button
          isDisabled={status === ACTION_STATUS_NOT_STARTED || isSubmitting}
          onClick={handleResetTest}
        >
          {isSubmitting ? 'Loading' : 'REOPEN'}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default RssAssessmentActionResultRow;
