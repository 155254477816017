import { FC, useState } from 'react';
import Button from '../../../../../../../../components/atoms/Button/Button';
import TextFormField from '../../../../../../../../components/molecules/TextFormField/TextFormField';
import validate from '../../../../../../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../../../../../../interfaces/forms/form';
import { TAlertTypes } from '../../../../../../../../interfaces/snackBar';
import useEditCompanySignatureStyles from '../../useEditCompanySignatureStyles';

interface IEditCompanySignatureForm {
  id: number;
  signature: string;
  signatureEmail: string;
  getEditSignatureData: (
    id: number,
    signature: string,
    signatureEmail: string
  ) => void;
  toggleEditConfirmationMode: () => void;
  resetShowConfirmEdit: () => void;
  showConfirmationDialog: (id: number, signature: string) => void;
  confirmEditMode: boolean;
  defaultId: number;
  displaySnackbar: (msg?: string, alertType?: TAlertTypes) => void;
  companySignatureCount: number;
}

interface ICompanyInfo {
  signature: string;
  signatureEmail: string;
}

interface IAddCompanySignatureError {
  signature: string[];
  signatureEmail: string[];
}

const EditCompanySignatureForm: FC<IEditCompanySignatureForm> = ({
  id,
  signature,
  signatureEmail,
  getEditSignatureData,
  resetShowConfirmEdit,
  toggleEditConfirmationMode,
  showConfirmationDialog,
  confirmEditMode,
  defaultId,
  displaySnackbar,
  companySignatureCount,
}) => {
  const classes = useEditCompanySignatureStyles();
  const initialCompanySignatureInfo = {
    signature,
    signatureEmail,
  };
  const initialCompanySignatureInfoError = {
    signature: [],
    signatureEmail: [],
  };
  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>(
    initialCompanySignatureInfo
  );
  const [errors, setErrors] = useState<IAddCompanySignatureError>(
    initialCompanySignatureInfoError
  );

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setCompanyInfo(prev => ({ ...prev, [name]: value }));
    if (errors) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, [name]: [] } : prevErrors
      );
    }
  };

  const handleConfirmEditSignature = () => {
    toggleEditConfirmationMode();
  };

  const handleConfirmDeleteSignature = () => {
    if (companySignatureCount > 1 && defaultId === id) {
      displaySnackbar(
        'You cannot delete the default signature except the last one',
        'error'
      );
    } else {
      showConfirmationDialog(id, companyInfo.signature);
    }
  };

  const editCompanySignatureFormValidation = () =>
    validate(companyInfo, {
      signature: {
        nonEmptyString: true,
      },
      signatureEmail: {
        email: true,
      },
    });

  const handleEditSignature = () => {
    const newErrors = editCompanySignatureFormValidation();
    if (newErrors) {
      setErrors(newErrors);
    } else {
      getEditSignatureData(
        id,
        companyInfo.signature,
        companyInfo.signatureEmail
      );
    }
  };

  const handleCancelEditSignature = () => {
    resetShowConfirmEdit();
    setCompanyInfo(initialCompanySignatureInfo);
    setErrors(initialCompanySignatureInfoError);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 40,
      }}
    >
      <div style={{ marginRight: 20, width: '100%' }}>
        <TextFormField
          id="signature"
          name="signature"
          label="Signature"
          value={companyInfo.signature}
          onChange={handleChange}
          hasError={!!errors?.signature?.length}
          errorText={errors?.signature?.join('')}
          isDisabled={!confirmEditMode}
        />
      </div>
      <div style={{ margin: '0 20', width: '100%' }}>
        <TextFormField
          id="signatureEmail"
          name="signatureEmail"
          label="Email"
          value={companyInfo.signatureEmail}
          onChange={handleChange}
          hasError={!!errors?.signatureEmail?.length}
          errorText={errors?.signatureEmail?.join('')}
          isDisabled={!confirmEditMode}
        />
      </div>
      <div style={{ display: 'flex', marginLeft: 20 }}>
        {!confirmEditMode ? (
          <div style={{ display: 'flex' }}>
            <Button color="primary" onClick={handleConfirmEditSignature}>
              Edit
            </Button>
            <Button
              color="secondary"
              className={classes.btn}
              onClick={handleConfirmDeleteSignature}
            >
              Delete
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <Button color="primary" onClick={handleEditSignature}>
              Save
            </Button>
            <Button
              color="default"
              className={classes.btn}
              onClick={handleCancelEditSignature}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCompanySignatureForm;
