import { appRoutes, companyRoutes, projectsRoutes } from 'constants/navigation';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ApiKeys from 'views/ApiKeys/ApiKeys';
import CandidateInfo from 'views/CandidateInfo/CandidateInfo';
import Companies from 'views/Companies/Companies';
import Company from 'views/Company/Company';
import InterviewGuides from 'views/InterviewGuides/InterviewGuides';
import JobRoles from 'views/JobRoles';
import ProjectView from 'views/Project';
import ProjectRequests from 'views/ProjectRequests';
import Projects from 'views/Projects/Projects';
import Reports from 'views/Reports/Reports';
import Results from 'views/Results/Results';
import StaticContent from 'views/StaticContent/StaticContent';
import User from 'views/User/User';
import StageActionsView from '../views/StageActions/StageActions';

interface IRootSwitch {}

const RootSwitch: FC<IRootSwitch> = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.projects.path}>
        <Projects />
      </Route>
      <Route path={appRoutes.projectRequests.path}>
        <ProjectRequests />
      </Route>
      <Route path={projectsRoutes.editProjectView.path}>
        <ProjectView />
      </Route>
      <Route path={appRoutes.results.path}>
        <Results />
      </Route>
      <Route exact path={appRoutes.companies.path}>
        <Companies />
      </Route>
      <Route path={companyRoutes.editCompany.path}>
        <Company />
      </Route>
      <Route path={appRoutes.formManager.path}>
        <CandidateInfo />
      </Route>
      <Route path={appRoutes.stageActions.path}>
        <StageActionsView />
      </Route>
      <Route path={appRoutes.content.path}>
        <StaticContent />
      </Route>
      <Route path={appRoutes.reports.path}>
        <Reports />
      </Route>
      <Route path={appRoutes.users.path}>
        <User />
      </Route>
      <Route path={appRoutes.apiKeys.path}>
        <ApiKeys />
      </Route>
      <Route path={appRoutes.interviewGuides.path}>
        <InterviewGuides />
      </Route>
      <Route path={appRoutes.jobRoles.path}>
        <JobRoles />
      </Route>
      <Redirect exact from="/" to={appRoutes.projects.path} />
    </Switch>
  );
};

export default RootSwitch;
