import {
  ActorRole,
  SmStageModelFragment,
  StageFindManyDocument,
  StageFindManyQuery,
  StageFindManyQueryVariables,
  useStageEditDetailsMutation,
} from 'generated/graphql';
import { formatISO } from 'helpers/date';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { getSendEmailSettings } from '../helpers';
import EditStageForm, { IEditStageFormValues } from './EditStageForm';
import StageActions from './StageActions';
import StageHeader from './StageHeader';

interface IEditStage {
  stage: SmStageModelFragment;
  stagesLength: number;
  projectId: number;
  companyId: number;
}

const EditStage: FC<IEditStage> = ({
  stage,
  projectId,
  companyId,
  stagesLength,
}) => {
  const { handleMsgType } = useNotification();

  const [isEditing, setIsEditing] = useState(false);

  const [
    stageEditDetailsMutation,
    stageEditDetailsResponse,
  ] = useStageEditDetailsMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setIsEditing(false);
    },
    update: (cache, { data }) => {
      if (data) {
        const cachedStages = cache.readQuery<
          StageFindManyQuery,
          StageFindManyQueryVariables
        >({
          query: StageFindManyDocument,
          variables: {
            projectId,
          },
        });

        if (cachedStages?.Stages) {
          cache.writeQuery<StageFindManyQuery, StageFindManyQueryVariables>({
            query: StageFindManyDocument,
            variables: {
              projectId,
            },
            data: {
              Stages: cachedStages.Stages.map(stage =>
                stage.id === data.Stage.id ? data.Stage : stage
              ),
            },
          });
        }
      }
    },
  });
  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleCancelForm = () => {
    setIsEditing(false);
  };

  const handleSaveForm = (values: IEditStageFormValues) => {
    const { emailManagerReport, emailProjectTeam } = getSendEmailSettings(
      values.stageMode.value
    );

    const emailProjectTeamRoles: ActorRole[] = values.stageProjectRoles.map(
      projectRole => projectRole.value!
    );
    stageEditDetailsMutation({
      variables: {
        id: stage.id,
        name: values.stageName,
        startTime: formatISO(values.stageStartTime),
        endTime:
          values.stageIsEndTimeNull || !values.stageEndTime
            ? null
            : formatISO(values.stageEndTime),
        emailCandidateReport: values.stageEmailCandidateReport,
        emailManagerReport,
        emailProjectTeam,
        emailProjectTeamRoles,
        emailSzAdmin: values.stageEmailSzAdmin,
        renderCandidateReport: false,
        hasCalibration: values.stageHasCalibration,
        createInterviewGuide: values.stageCreateInterviewGuide,
      },
    });
  };

  return (
    <div className="edit-stage">
      <div className="edit-stage__title">Stages</div>
      {!isEditing ? (
        <StageHeader
          stagesLength={stagesLength}
          stageId={stage.id}
          order={stage.order}
          onStartEditingForm={handleStartEditing}
          projectId={projectId}
        />
      ) : null}
      <EditStageForm
        stage={stage}
        isEditing={isEditing}
        onCancel={handleCancelForm}
        onSave={handleSaveForm}
        projectId={projectId}
        companyId={companyId}
        isLoading={stageEditDetailsResponse.loading}
      />
      <StageActions stageId={stage.id} projectId={projectId} />
    </div>
  );
};

export default EditStage;
