import {
  CompanyFindUsersDocument,
  CompanyFindUsersQuery,
} from 'generated/graphql';
import { FC } from 'react';
import {
  FormikMultiselectFormFieldWithQuery,
  IFormikMultiselectFormFieldWithQuery,
} from './FormikMultiselectFormFieldWithQuery';

interface ICompanyFindUsersFormikMultiselectFormField
  extends Omit<
    IFormikMultiselectFormFieldWithQuery<CompanyFindUsersQuery>,
    'query' | 'queryOptions' | 'getOptionsFromResponse'
  > {
  companyId?: number;
  getOptionsFromResponse?: (
    response: CompanyFindUsersQuery
  ) => Array<{
    label: string;
    value: string;
    labelDescription?: string;
    labelNote?: string;
  }>;
}

export const CompanyFindUsersFormikMultiselectFormField: FC<ICompanyFindUsersFormikMultiselectFormField> = ({
  companyId,
  getOptionsFromResponse,
  ...selectElementProps
}) => {
  const defaultGetOptionsFromResponse = (response: CompanyFindUsersQuery) => {
    if (response.companyUsers) {
      return [...response.companyUsers].map(companyUser => ({
        label: `${companyUser?.firstName} ${companyUser?.lastName}`,
        value: companyUser?.id ? `${companyUser?.id}` : '',
      }));
    }
    return [];
  };

  return (
    <FormikMultiselectFormFieldWithQuery<CompanyFindUsersQuery>
      query={CompanyFindUsersDocument}
      getOptionsFromResponse={
        getOptionsFromResponse || defaultGetOptionsFromResponse
      }
      queryOptions={{
        skip: !companyId,
        variables: { id: companyId },
      }}
      {...selectElementProps}
    />
  );
};
