import React, { FC } from 'react';
import styles from '../BehaviourUploadInlineNotification.module.scss';
interface ISuccessValidationStatus {
  fileName: string;
}

const WarningValidationStatus: FC<ISuccessValidationStatus> = ({
  fileName,
}) => {
  return (
    <div className={styles.info_container}>
      <div className={styles.info_title}>
        ‘{fileName}‘ has passed validation
      </div>
      <div className={styles.info_paragraph}>
        Confirming upload of this content will affect the following soft skills
        and traits.
      </div>
      <div className={styles.info_paragraph}>
        Please be aware that while the content has passed validation, there were
        some minor issues identified (e.g. duplicate content). Behaviour content
        with these minor issues have been skipped over for the upload.
      </div>
    </div>
  );
};

export default WarningValidationStatus;
