import { makeStyles } from '@material-ui/core/styles';

const useCandidateInfoFieldModalStyle = makeStyles({
  modalPaper: {
    minWidth: 600,
    minHeight: 500,
    maxHeight: 600,
    overflowY: 'scroll',
  },
});

export default useCandidateInfoFieldModalStyle;
