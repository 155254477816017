import { makeStyles } from '@material-ui/core/styles';

const useCandidateInfoFieldFormStyle = makeStyles(() => ({
  container: {
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useCandidateInfoFieldFormStyle;
