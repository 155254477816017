import Decimal from 'decimal.js';
import { FC, useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../../components/atoms/Button/Button';
import {
  BulkUpdateSoftSkillTraitArgsSingle,
  FindSoftSkillTraitsBySsIdDocument,
  Orientation,
  SoftSkillFragmentDoc,
  SoftSkillTrait,
  useSoftSkillBulkUpdateTraitsMutation,
} from '../../../../../../generated/graphql';
import { MemoizedConnectedTraitsEditListItem } from './ConnectedTraitsEditListItem/ConnectedTraitsEditListItem';

export interface IConnectedTraitsEditList {
  softSkillTraits: SoftSkillTrait[];
  softSkillId: number;
  className?: string;
}

export interface IConnectedTraitFormValues {
  id: number;
  traitName: string;
  traitId: number;
  softSkillId: number;
  weight: number;
  orientation: string;
  isAdded: boolean;
}

const ConnectedTraitsEditList: FC<IConnectedTraitsEditList> = ({
  softSkillTraits,
  className,
  softSkillId,
}) => {
  const { handleMsgType } = useNotification();
  const defaultValues: IConnectedTraitFormValues[] = softSkillTraits.map(
    softSkillTrait => ({
      id: softSkillTrait.id as number,
      traitName: softSkillTrait.traitName as string,
      traitId: softSkillTrait.traitId as number,
      softSkillId: softSkillId as number,
      weight: softSkillTrait.weight,
      orientation: softSkillTrait.orientation.toString(),
      isAdded: false,
    })
  );

  const [allTraits, setAllTraits] = useState<IConnectedTraitFormValues[]>(
    defaultValues
  );
  const numOfAddedTraits = useRef(0);

  const [
    SoftSkillBulkUpdateTraitMutation,
    SoftSkillBulkUpdateTraitMutationResponse,
  ] = useSoftSkillBulkUpdateTraitsMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });

      setError('An error occured. Close, refresh and check the values.');
    },
    update(cache, { data }) {
      if (data?.softSkill) {
        const { softSkill } = data;

        if (softSkill) {
          cache.writeFragment({
            id: cache.identify(softSkill),
            data: softSkill,
            fragment: SoftSkillFragmentDoc,
            fragmentName: 'SoftSkill',
          });

          cache.writeQuery({
            query: FindSoftSkillTraitsBySsIdDocument,
            variables: {
              id: data.softSkill.id,
            },
            data: {
              softSkillTraits: softSkill.softSkillTraits,
            },
          });
        }
      }
    },
  });

  const [deletedTraits, setDeletedTraits] = useState<
    IConnectedTraitFormValues[]
  >([]);

  const [error, setError] = useState<string>('');

  const updateTraits = (updatedTrait: IConnectedTraitFormValues) => {
    setAllTraits(
      allTraits.map(value =>
        value.id === updatedTrait.id
          ? {
              ...value,
              traitId: updatedTrait.traitId,
              weight: updatedTrait.weight,
              orientation: updatedTrait.orientation,
            }
          : value
      )
    );
  };

  const deleteTraits = (deletedTrait: IConnectedTraitFormValues) => {
    setAllTraits(allTraits.filter(value => value.id !== deletedTrait.id));

    // If the trait isn't aritificial, set it to be deleted from the database
    if (!deletedTrait.isAdded) {
      setDeletedTraits(prevValues => [...prevValues, deletedTrait]);
    }
  };

  const addTrait = () => {
    numOfAddedTraits.current = numOfAddedTraits.current + 1;
    /** Create an artificial trait with negative id so it's different from valid traits
     * and add it to the allTraits array. The id for added traits isn't being used
     * anywhere, so it's okay for it to be negative.
     *
     */
    setAllTraits(prevValues => [
      ...prevValues,
      {
        id: -(numOfAddedTraits.current + 1),
        traitName: '',
        traitId: -1,
        softSkillId: softSkillId,
        weight: 0,
        orientation: '0',
        isAdded: true,
      },
    ]);
  };

  const handleSave = () => {
    if (!validateWeights()) {
      setError('Weights must add up to 1');
      return;
    }
    if (!validateTraits()) {
      setError('All added traits must have a trait selected');
      return;
    }

    setError('');

    const softSkillTraitsInput: BulkUpdateSoftSkillTraitArgsSingle[] = [];
    // Create input for bulk update
    allTraits.forEach(trait => {
      trait.isAdded
        ? softSkillTraitsInput.push({
            softSkillId: softSkillId,
            traitId: Number(trait.traitId),
            weight: Number(trait.weight),
            orientation:
              Number(trait.orientation) === 0
                ? Orientation.Inverted
                : Orientation.Standard,
            isAdded: true,
          })
        : softSkillTraitsInput.push({
            softSkillId: softSkillId,
            traitId: Number(trait.traitId),
            weight: Number(trait.weight),
            orientation:
              Number(trait.orientation) === 0
                ? Orientation.Inverted
                : Orientation.Standard,
            isUpdated: true,
          });
    });

    deletedTraits.forEach(trait => {
      softSkillTraitsInput.push({
        softSkillId: softSkillId,
        traitId: Number(trait.traitId),
        weight: Number(trait.weight),
        orientation:
          Number(trait.orientation) === 0
            ? Orientation.Inverted
            : Orientation.Standard,
        isDeleted: true,
      });
    });

    // Run the bulk update
    SoftSkillBulkUpdateTraitMutation({
      variables: {
        softSkillTraits: softSkillTraitsInput,
      },
    });
  };

  const validateTraits = () => {
    let isValid = true;
    allTraits.forEach(trait => {
      if (trait.traitId === -1) isValid = false;
    });
    return isValid;
  };

  const validateWeights = () => {
    let sum = allTraits.reduce(function (total: number, value) {
      return total + Number(new Decimal(value.weight));
    }, 0);
    return sum === 1;
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={className}
      spacing={2}
    >
      {allTraits.map(trait => (
        <MemoizedConnectedTraitsEditListItem
          softSkillTrait={trait}
          key={trait.id}
          updateTraits={updateTraits}
          deleteTraits={deleteTraits}
          traitsToBeExcluded={allTraits.map(trait => trait.traitId)}
        />
      ))}
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <Button onClick={addTrait}>Add trait</Button>
        </Grid>
        <Grid item>
          <Button onClick={handleSave}>Save</Button>
        </Grid>
        <Grid item>
          {SoftSkillBulkUpdateTraitMutationResponse.loading ? (
            <Typography>Saving..</Typography>
          ) : null}
        </Grid>
      </Grid>
      {error ? (
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography color="error">{error}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ConnectedTraitsEditList;
