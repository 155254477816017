import { FC } from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../atoms/Button/Button';
import useHeaderStyle from './EditHeaderStyle';

interface IHeader {
  isArchived: boolean;
  title: string;
  handleArchive: () => void;
  handleDelete: () => void;
}

const EditHeader: FC<IHeader> = ({
  isArchived,
  title,
  handleDelete,
  handleArchive,
}) => {
  const classes = useHeaderStyle();

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        style={{ marginBottom: '35px' }}
        className={classes.w100}
      >
        <p>{title}</p>
        {isArchived ? (
          <div>
            <Button
              color="default"
              className={classes.firstBtn}
              onClick={handleArchive}
            >
              UNARCHIVE
            </Button>
            <Button
              className={classes.btn}
              color="default"
              onClick={handleDelete}
            >
              DELETE
            </Button>
          </div>
        ) : (
          <Button color="default" onClick={handleArchive}>
            ARCHIVE
          </Button>
        )}
      </Grid>
    </>
  );
};

export default EditHeader;
