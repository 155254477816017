import { FC } from 'react';
import ApiKeysNavigation from '../../navigation/ApiKeysNavigation/ApiKeysNavigation';
import ApiKeysSwitch from '../../navigation/ApiKeysNavigation/ApiKeysSwitch';

interface IApiKeys {}

const ApiKeys: FC<IApiKeys> = () => {
  return (
    <>
      <ApiKeysNavigation />
      <ApiKeysSwitch />
    </>
  );
};

export default ApiKeys;
