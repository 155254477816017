import '@spotted-zebra-uk/ui-components/dist/sz-theme/_globalStyles.scss';
import { Route, Router, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import {
  useNotification,
  useAccessibilityKeyboardDetection,
} from '@spotted-zebra-uk/ui-components';
import { NavigationProvider } from '@teambit/base-react.navigation.link';
import { reactRouterAdapter } from '@teambit/ui-foundation.ui.navigation.react-router-adapter';
import PrivateRoute from './components/molecules/PrivateRoute/PrivateRoute';
import history from './config/history';
import client from './graphql/client';
import ModalView from './views/ModalView/ModalView';
import Root from './views/Root/Root';
import AuthenticationWrapper from './components/organisms/AuthenticationWrapper/AuthenticationWrapper';
import { Authentication } from './views/Authentication/Authentication';

function App() {
  const { notificationContainer } = useNotification();
  useAccessibilityKeyboardDetection();

  return (
    <>
      {notificationContainer()}
      <ApolloProvider client={client}>
        <AuthenticationWrapper>
          <Router history={history}>
            <NavigationProvider implementation={reactRouterAdapter}>
              <Switch>
                <Route path="/auth">
                  <Authentication />
                </Route>
                <PrivateRoute path="/" component={Root} />
              </Switch>
            </NavigationProvider>
          </Router>
        </AuthenticationWrapper>
        <ModalView />
        <section id="sz-drawer"></section>
      </ApolloProvider>
    </>
  );
}

export default App;
