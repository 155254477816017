import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import TextFormField from 'components/molecules/TextFormField/TextFormField';
import { ModalTypes } from 'enums/modalTypes';
import { UserFragment } from 'generated/graphql';
import { openModal } from 'graphql/modals';
import useEditCompanyEmployeeFormStyle from './EditCompanyEmployeeFormStyle';

interface IEditCompanyEmployeeForm {
  onCancel?: () => void;
  isLoading?: boolean;
  companyEmployee: UserFragment;
  onSave: (
    firstName: string,
    lastName: string,
    email: string,
    externalId: string
  ) => Promise<boolean>;
}

interface ICompany {
  companyId: string;
}
const EditCompanyEmployeeForm: FC<IEditCompanyEmployeeForm> = ({
  isLoading = false,
  companyEmployee,
  ...props
}) => {
  const classes = useEditCompanyEmployeeFormStyle();
  const { companyId } = useParams<ICompany>();
  const handleStartEditing = () => {
    openModal(ModalTypes.EDIT_USER_MODAL, {
      userInfo: companyEmployee,
      companyId,
    });
  };

  const key = `project-manager-${companyEmployee.id}`;

  if (isLoading) {
    return <CircularLoader customStyle={classes.loaderWrapper} />;
  }

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs>
        <TextFormField
          id={`${key}-first-name`}
          name="firstName"
          label="Given name"
          value={companyEmployee.firstName}
          isDisabled={true}
        />
      </Grid>
      <Grid item xs={2}>
        <TextFormField
          id={`${key}-last-name`}
          name="lastName"
          label="Family name"
          value={companyEmployee.lastName}
          isDisabled={true}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          id={`${key}-email`}
          name="email"
          label="Email"
          value={companyEmployee.email}
          isDisabled={true}
        />
      </Grid>

      <Grid className={classes.buttonWrapper} item>
        <Button
          className={classes.button}
          onClick={handleStartEditing}
          variant="contained"
          color="primary"
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditCompanyEmployeeForm;
