import { FC } from 'react';
import { Button, TextInputField } from '@spotted-zebra-uk/ui-components';
import DeleteStage from './DeleteStage';
import CandidateListButton from './DirectInvitation';
import IndirectInvitationButton from './IndirectInvitationButton';
import OrderStages from './OrderStages';

interface IStageActions {
  stagesLength: number;
  stageId: number;
  order: number;
  onStartEditingForm: () => void;
  projectId: number;
}

const StageActions: FC<IStageActions> = ({
  stagesLength,
  stageId,
  order,
  projectId,
  onStartEditingForm,
}) => {
  return (
    <div className="stage-header">
      <div className="stage-header__left">
        <TextInputField
          label="Stage ID"
          id="stageId"
          name="stageId"
          value={`${stageId}`}
          onChange={() => {}}
          disabled
        />
        <OrderStages
          stageId={stageId}
          stagesLength={stagesLength}
          stageOrderIndex={order}
          projectId={projectId}
        />
      </div>
      <div className="stage-header__right">
        <IndirectInvitationButton projectId={projectId} stageId={stageId} />
        <CandidateListButton projectId={projectId} stageId={stageId} />
        <DeleteStage projectId={projectId} id={stageId} order={order} />
        <Button onClick={onStartEditingForm} size="medium">
          Edit
        </Button>
      </div>
    </div>
  );
};

export default StageActions;
