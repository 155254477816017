import { makeStyles } from '@material-ui/core/styles';

const useCandidateInfoFieldsHeaderStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
}));

export default useCandidateInfoFieldsHeaderStyle;
