import './styles.scss';
import Icon from 'components/atoms/Icon';
import { projectRequestsRoutes } from 'constants/navigation';
import { ProjectRequestsViewContext } from 'contexts/ProjectRequestContext';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Switch } from '@spotted-zebra-uk/ui-components';
import ProjectRequestsList from './ProjectRequestsList';

const ProjectRequestsViewHeader: FC = () => {
  const history = useHistory();
  const { setDisplayArchivedFilter, displayArchivedFilter } = useContext(
    ProjectRequestsViewContext
  );

  const handleCreateProjectRequestButtonClick = () => {
    history.push(projectRequestsRoutes.createProjectRequest.url());
  };

  const handleDisplayArchivedSwitchToggle = (value: boolean) => {
    setDisplayArchivedFilter(value);
  };

  return (
    <div className="project-requests-view-header">
      <div className="project-requests-view-header__left">
        <h2 className="project-requests-view-header__text">Project Requests</h2>
      </div>
      <div className="project-requests-view-header__right">
        <Switch
          id="display-archived"
          label="Display Archived"
          value={displayArchivedFilter}
          onToggle={handleDisplayArchivedSwitchToggle}
        />
        <Button
          onClick={handleCreateProjectRequestButtonClick}
          className="project-requests-view-header__create-button"
          rightIcon={<Icon icon="add" />}
        >
          Create Project Request
        </Button>
      </div>
    </div>
  );
};

const ProjectRequestsView: FC = () => {
  return (
    <div className="project-requests-view">
      <ProjectRequestsViewHeader />
      <ProjectRequestsList />
    </div>
  );
};

export default ProjectRequestsView;
