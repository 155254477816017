import { FC } from 'react';
import SidebarNavigation from '../../components/organisms/SidebarNavigation/SidebarNavigation';
import { usersSidebarConfig } from './UserNavigation.constants';

interface IUserNavigation {}

const UserNavigation: FC<IUserNavigation> = () => {
  return <SidebarNavigation routes={usersSidebarConfig} />;
};

export default UserNavigation;
