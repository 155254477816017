import { makeStyles } from '@material-ui/core/styles';

export const useIgStepsStyle = makeStyles({
  btn: {
    width: 150,
    alignSelf: 'flex-end',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});
