import { makeStyles } from '@material-ui/core/styles';

const useReportsTableRowStyle = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  duplicateBtn: {
    marginRight: '10px',
    width: 96,
  },
  tableHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: '16px',
  },
  btn: {
    width: 96,
  },
  archivedText: {
    color: '#f50057',
  },
  btnCell: {
    width: '195px',
  },
}));

export default useReportsTableRowStyle;
