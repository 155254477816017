import { jobRolesRoutes } from 'constants/navigation';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateJobRole from 'views/CreateJobRole';
import EditJobRole from 'views/EditJobRole';

const JobRoles: FC = () => {
  return (
    <Switch>
      <Route exact path={jobRolesRoutes.createJobRole.path}>
        <CreateJobRole />
      </Route>
      <Route path={jobRolesRoutes.editJobRole.path}>
        <EditJobRole />
      </Route>
    </Switch>
  );
};

export default JobRoles;
