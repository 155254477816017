import SidebarNavigation from 'components/organisms/SidebarNavigation/SidebarNavigation';
import { appRoutes, stageActionsRoutes } from 'constants/navigation';
import { Route, Switch } from 'react-router-dom';
import CodingAssessmentView from './CodingAssessments/CodingAssessments';
import styles from './StageActions.module.scss';

// sidebar navig
const sidebarRoutes = [
  {
    title: 'Coding Assessments',
    path: stageActionsRoutes.codingAssessments.path,
  },
];

function StageActionsNavigation() {
  return <SidebarNavigation routes={sidebarRoutes} />;
}

// sidebar switch
function StageActionsSwitch() {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.stageActions.path}
        render={() => <CodingAssessmentView />}
      />
      <Route path={stageActionsRoutes.codingAssessments.path}>
        <CodingAssessmentView />
      </Route>
    </Switch>
  );
}

function StageActionsView() {
  return (
    <>
      <StageActionsNavigation />
      <div className={styles.container}>
        <StageActionsSwitch />
      </div>
    </>
  );
}

export default StageActionsView;
