import { FormCandidateModelFragment } from 'generated/graphql';
import { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
  tableCell: {
    fontSize: '16px',
  },
  tableActionsCell: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

interface IRssAssessmentActionResultRow {
  formCandidate: FormCandidateModelFragment;
  stageCandidateId: number;
}

const FormResultRow: FC<IRssAssessmentActionResultRow> = ({
  formCandidate,
}) => {
  const classes = useStyle();

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>RS_FORM</TableCell>
      <TableCell />
      <TableCell />
      <TableCell className={classes.tableCell}>
        {formCandidate.status}
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell></TableCell>
    </TableRow>
  );
};

export default FormResultRow;
