import { makeStyles } from '@material-ui/core/styles';

const useTextFieldModalStyle = makeStyles({
  modalPaper: {
    minWidth: 500,
    minHeight: 150,
    display: 'flex',
    justifyContent: 'center',
  },
  actions: {
    marginTop: 15,
    display: 'flex',
    gap: 10,
  },
});

export default useTextFieldModalStyle;
