import { FC } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import SwitchFormField from '../../../../components/molecules/SwitchFormField/SwitchFormField';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import useCandidateFieldsHeaderStyle from './CandidateInfoFieldsHeaderStyle';

interface ICandidateInfoFieldsHeader {
  isArchivedVisible: boolean;
  setIsArchivedVisible: (isArchivedVisible: boolean) => void;
}

const CandidateInfoFieldsHeader: FC<ICandidateInfoFieldsHeader> = ({
  isArchivedVisible,
  setIsArchivedVisible,
}) => {
  const classes = useCandidateFieldsHeaderStyle();

  const toggleArchivedProjectsVisibility = () => {
    setIsArchivedVisible(!isArchivedVisible);
  };

  const handleOpenCreateCandidateInfoFieldModal = () => {
    openModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL, {});
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6}>
        <Typography variant="h5">Candidate information fields</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        xs={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <SwitchFormField
            id="toggle-displey-archived-form-fields"
            name="isArchivedVisible"
            color="primary"
            value={isArchivedVisible}
            label="display / hide archived fields"
            onChange={toggleArchivedProjectsVisibility}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleOpenCreateCandidateInfoFieldModal}
            className={classes.button}
            variant="contained"
            color="default"
          >
            Add new
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CandidateInfoFieldsHeader;
