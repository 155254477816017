import { makeStyles } from '@material-ui/core/styles';

const useReportStyles = makeStyles(theme => ({
  saveBtn: {
    marginRight: 10,
  },
  container: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '22px',
  },
  button: {
    fontSize: '16px',
  },
  deleteBtn: {
    fontSize: '16px',
    backgroundColor: '#f50057',
    marginLeft: '10px',
    color: '#fff',
  },
}));
export default useReportStyles;
