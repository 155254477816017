import { FC, useMemo } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  useProjectFindManyByCompanyQuery,
  useStageFindManyQuery,
} from '../../../../generated/graphql';
import {
  TMultiSelectFormFieldValue,
  TSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

export interface IProjectStagesSelectFormField {
  id: string;
  name: string;
  label: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  value: TMultiSelectFormFieldValue;
  isDisabled?: boolean;
  companyId: number;
}

interface IOptions {
  label: string;
  value: number;
}

const ProjectStagesSelectFormField: FC<IProjectStagesSelectFormField> = ({
  id,
  name,
  label,
  onChange,
  value,
  isDisabled,
  companyId,
}) => {
  const { handleMsgType } = useNotification();

  const { data: projectsData } = useProjectFindManyByCompanyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const projectIds = useMemo(
    () => projectsData?.projects.map(project => project.id) || [],
    [projectsData]
  );

  const { data: stagesData } = useStageFindManyQuery({
    skip: !projectIds.length,
    variables: {
      projectIds,
    },
  });

  const stagesSorted: IOptions[] = useMemo(() => {
    return (
      stagesData?.Stages?.map(stage => {
        const project = projectsData?.projects.find(
          (item: { id: number }) => item.id === stage.projectId
        );
        return {
          label: `${project?.name} - ${stage.name}`,
          value: stage.id,
        };
      }).sort((a, b) => (a.label < b.label ? -1 : 1)) || []
    );
    //eslint-disable-next-line
  }, [stagesData]);

  return (
    <MultiselectFormField
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      options={stagesSorted}
      isDisabled={isDisabled}
    />
  );
};

export default ProjectStagesSelectFormField;
