import { makeStyles, Theme } from '@material-ui/core/styles';

const useCandidateInformationFieldRequirementsFormItemStyle = makeStyles(
  (theme: Theme) => ({
    container: {
      borderRadius: '7px',
      border: '1px solid black',
      backgroundColor: 'white',
      margin: 3,
      width: 'calc(100% - 10px)',
      padding: 0,
    },
    ownedBySelectedConpanyFieldWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  })
);

export default useCandidateInformationFieldRequirementsFormItemStyle;
