import { FC } from 'react';
import { useParams } from 'react-router-dom';
import SidebarNavigation from '../../components/organisms/SidebarNavigation/SidebarNavigation';
import { companyRoutes, editCompanyRoutes } from '../../constants/navigation';
import { ICompanyPath } from '../../interfaces/routes';

interface IEditCompanyNavigation {}

export const getEditCompanySidebarConfig = (companyId: number) => [
  {
    title: 'Edit company',
    path: companyRoutes.editCompany.url(companyId),
  },
  {
    title: 'Edit company api keys',
    path: editCompanyRoutes.editCompanyApiKeys.url(companyId),
  },
  {
    title: 'Edit Company roles',
    path: editCompanyRoutes.editCompanyRoles.url(companyId),
  },
];

const EditCompanyNavigation: FC<IEditCompanyNavigation> = () => {
  const { companyId } = useParams<ICompanyPath>();

  const editCompanySidebarConfig = getEditCompanySidebarConfig(+companyId);

  return <SidebarNavigation routes={editCompanySidebarConfig} />;
};

export default EditCompanyNavigation;
