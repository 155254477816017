import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { companyRoutes } from '../../constants/navigation';
import CompanyView from '../../views/Company/CompanyView/CompanyView';
import CreateCompany from '../../views/Company/CreateCompany/CreateCompany';

interface ICompanySwitch {}

const CompanySwitch: FC<ICompanySwitch> = () => {
  return (
    <Switch>
      <Route exact path={companyRoutes.createCompany.path}>
        <CreateCompany />
      </Route>
      <Route path={companyRoutes.editCompany.path}>
        <CompanyView />
      </Route>
    </Switch>
  );
};

export default CompanySwitch;
