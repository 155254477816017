import { FC } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  IgCategory,
  IgInterviewGuideCategory,
} from '../../../generated/graphql';
import CategoriesTableRow from './CategoriesTableRow/CategoriesTableRow';
import useCategoriesTableStyle from './CategoriesTableStyle';

interface ICategoriesTable {
  headerData: string[];
  tableBodyData: IgCategory[] | undefined;
}

const CategoriesTable: FC<ICategoriesTable> = ({
  headerData,
  tableBodyData,
}) => {
  const classes = useCategoriesTableStyle();
  const interviewGuideCategoriesLength = (
    interviewGuideCategories: IgInterviewGuideCategory[]
  ): number => {
    return Array.from(
      new Set(
        interviewGuideCategories.map(
          (item: IgInterviewGuideCategory) => item.interviewGuideId
        )
      )
    ).length;
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell className={classes.tableHeader} key={`a${index}`}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyData?.map(row => {
            const numberOfInterviewGuides = interviewGuideCategoriesLength(
              row.interviewGuideCategories as IgInterviewGuideCategory[]
            );
            return (
              <CategoriesTableRow
                key={row.id}
                id={row.id}
                name={row.internalName}
                numberOfInterviewGuides={numberOfInterviewGuides}
                isArchived={row.isArchived}
                updatedAt={row.updatedAt}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoriesTable;
