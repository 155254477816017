import { useStageFindManyQuery } from 'generated/graphql';
import { FC } from 'react';
import { Loader } from '@spotted-zebra-uk/ui-components';
import CreateStage from './CreateStage';
import EditStage from './EditStage';

interface IStages {
  projectId: number;
  companyId: number;
}

const Stages: FC<IStages> = ({ projectId, companyId }) => {
  const stagesQueryResponse = useStageFindManyQuery({
    variables: { projectId },
    onError: error => {
      console.log(error);
    },
  });

  if (stagesQueryResponse.loading) {
    return <Loader variant="bubbles" />;
  }

  if (stagesQueryResponse.data?.Stages) {
    const stages = stagesQueryResponse.data.Stages;

    return (
      <div className="stages">
        {!stages.length ? (
          <CreateStage projectId={projectId} />
        ) : (
          <EditStage
            stage={stages[0]}
            stagesLength={1}
            projectId={projectId}
            companyId={companyId}
          />
        )}
      </div>
    );
  }

  return null;
};

export default Stages;
