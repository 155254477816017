import { Redirect, Route, Switch } from 'react-router-dom';
import { appRoutes, interviewGuidesRoutes } from '../../constants/navigation';
import InterviewGuide from '../../views/InterviewGuide/InterviewGuide';
import InterviewGuideCategory from '../../views/InterviewGuideCategory/InterviewGuideCategory';
import InterviewGuides from '../../views/InterviewGuides/InterviewGuideList/InterviewGuideList';
import InterviewGuidesCategories from '../../views/InterviewGuidesCategories/InterviewGuidesCategories';

const InterviewGuidesSwitch = () => {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.interviewGuides.path}
        render={() => <Redirect to={interviewGuidesRoutes.categories.path} />}
      />
      <Route path={interviewGuidesRoutes.category.path}>
        <InterviewGuideCategory />
      </Route>
      <Route path={interviewGuidesRoutes.categories.path}>
        <InterviewGuidesCategories />
      </Route>
      <Route path={interviewGuidesRoutes.interviewGuide.path}>
        <InterviewGuide />
      </Route>
      <Route path={interviewGuidesRoutes.interviewGuides.path}>
        <InterviewGuides />
      </Route>
    </Switch>
  );
};

export default InterviewGuidesSwitch;
