import { makeStyles } from '@material-ui/core/styles';

const useTraitsHeaderStyle = makeStyles(() => ({
  button: {
    height: 48,
    width: 150,
  },
}));

export default useTraitsHeaderStyle;
