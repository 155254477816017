import { range } from 'lodash';
import { FC, useMemo } from 'react';
import { SelectFormField } from '@spotted-zebra-uk/ui-components';

interface IOrderStages {
  stagesLength: number;
  stageId: number;
  projectId: number;
  stageOrderIndex: number;
}
// TODO: Uncomment sections when support for multiple stages implemented. [EN-815]
// const reorderStages = (
//   stages: SmStageModel[],
//   stageId: number,
//   prevPosition: number,
//   newPosition: number
// ) =>
//   stages.map(stage => {
//     if (stage.id === stageId) {
//       return {
//         ...stage,
//         order: newPosition,
//       };
//     }

//     if (
//       newPosition > prevPosition &&
//       stage.order > prevPosition &&
//       stage.order <= newPosition
//     ) {
//       return {
//         ...stage,
//         order: stage.order - 1,
//       };
//     }

//     if (
//       newPosition < prevPosition &&
//       stage.order < prevPosition &&
//       stage.order >= newPosition
//     ) {
//       return {
//         ...stage,
//         order: stage.order + 1,
//       };
//     }

//     return stage;
//   });

const OrderStages: FC<IOrderStages> = ({
  stagesLength,
  stageId,
  stageOrderIndex,
  projectId,
}) => {
  // TODO: Uncomment sections when support for multiple stages implemented. [EN-815]
  // const client = useApolloClient();
  // Get current order of all stages.
  // const stagesQueryResponse = client.readQuery<
  //   StageFindManyQuery,
  //   StageFindManyQueryVariables
  // >({
  //   query: StageFindManyDocument,
  //   variables: {
  //     projectId,
  //   },
  // });

  // Initialize mutation;
  // const [orderStagesMutation] = useOrderStagesMutation({
  //   update(cache, { data }) {
  //     if (data && data.Stages) {
  //       cache.writeQuery({
  //         query: FindManyStagesByProjectDocument,
  //         variables: {
  //           projectId,
  //         },
  //         data: { Stages: data.Stages },
  //       });
  //     }
  //   },
  //   onError: () => {},
  // });

  // Gets options for select element.
  const orderOptions = useMemo(
    () =>
      range(stagesLength).map(index => ({
        value: (index + 1).toString(),
        label: `Stage ${index + 1}`,
      })),
    [stagesLength]
  );

  // TODO: Uncomment sections when support for multiple stages implemented. [EN-815]
  // Select element callback.
  // const handleChangeOrder = (value: TSelectOption) => {
  //   if (stagesQueryResponse?.Stages && value) {
  // const newStagesOrder = reorderStages(
  //   stagesQueryResponse?.Stages,
  //   stageId,
  //   stageOrderIndex,
  //   parseInt(value as string, 10)
  // );
  // orderStagesMutation({
  //   variables: {
  //     projectId,
  //     stageOrderings: newStagesOrder.map(stage => ({
  //       stageId: stage.id,
  //       order: stage.order,
  //     })),
  //   },
  // });
  //   }
  // };

  return (
    <SelectFormField
      label="Order"
      id={`stage-${stageId}-order`}
      name="order"
      onChange={() => {}}
      isDisabled
      value={orderOptions[0]}
      options={orderOptions}
      hasNullValue={false}
      hasClearIndicator={false}
    />
  );
};

export default OrderStages;
