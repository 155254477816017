import { FC } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import SwitchFormField from '../../../../components/molecules/SwitchFormField/SwitchFormField';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import useSoftSkillsHeaderStyle from './SoftSkillsHeaderStyle';

interface ISoftSkillsHeader {
  isArchivedVisible: boolean;
  setIsArchivedVisible: (isArchivedVisible: boolean) => void;
}

const SoftSkillsHeader: FC<ISoftSkillsHeader> = ({
  isArchivedVisible,
  setIsArchivedVisible,
}) => {
  const classes = useSoftSkillsHeaderStyle();

  const toggleArchivedProjectsVisibility = () => {
    setIsArchivedVisible(!isArchivedVisible);
  };

  const handleOpenAddSoftSkillsModal = () => {
    openModal(ModalTypes.ADD_SOFT_SKILL_MODAL, {});
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6}>
        <Typography variant="h5">Soft Skills</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        xs={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <SwitchFormField
            id="toggle-display-archived-soft-skills"
            name="isArchivedVisible"
            color="primary"
            value={isArchivedVisible}
            label="hide / display archived soft skills"
            onChange={toggleArchivedProjectsVisibility}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleOpenAddSoftSkillsModal}
            className={classes.button}
            variant="contained"
            color="default"
          >
            Add new
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SoftSkillsHeader;
