import { makeStyles } from '@material-ui/core/styles';

const useTextFormFieldWithActionIconStyle = makeStyles(() => ({
  formField: {
    marginTop: 0,
    marginBottom: 0,
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  disabled: {},
}));

export default useTextFormFieldWithActionIconStyle;
