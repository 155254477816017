import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { apiKeysRoutes, appRoutes } from '../../constants/navigation';
import ApiKeysCompany from '../../views/ApiKeys/ApiKeysCompany/ApiKeysCompany';
import ApiKeysUsers from '../../views/ApiKeys/ApiKeysUsers/ApiKeysUsers';

interface IApiKeysSwitch {}

const ApiKeysSwitch: FC<IApiKeysSwitch> = () => {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.apiKeys.path}
        render={() => <Redirect to={apiKeysRoutes.companies.path} />}
      />
      <Route path={apiKeysRoutes.companies.path}>
        <ApiKeysCompany />
      </Route>
      <Route path={apiKeysRoutes.users.path}>
        <ApiKeysUsers />
      </Route>
    </Switch>
  );
};

export default ApiKeysSwitch;
