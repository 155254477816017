import { FC } from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@material-ui/core';
import Sidebar from '../../molecules/Sidebar/Sidebar';

interface ISidebarNavigation {
  routes: {
    title: string;
    path: string;
  }[];
}

const SidebarNavigation: FC<ISidebarNavigation> = ({ routes }) => {
  return (
    <Sidebar>
      <List>
        {routes.map((route, index) => (
          <Link key={route.title} to={route.path}>
            <ListItem button>
              <ListItemText primary={route.title} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Sidebar>
  );
};

export default SidebarNavigation;
