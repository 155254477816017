import { FC } from 'react';
import SidebarNavigation from '../../components/organisms/SidebarNavigation/SidebarNavigation';
import { reportsSidebarConfig } from './ReportNavigation.constants';

interface IReportNavigation {}

const ReportNavigation: FC<IReportNavigation> = () => {
  return <SidebarNavigation routes={reportsSidebarConfig} />;
};

export default ReportNavigation;
