import './index.scss';
import '@spotted-zebra-uk/ui-components/dist/sz-theme/_globalStyles.scss';
import './helpers/il8n';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { setDocumentHeadLinks } from './helpers/setDocumentHeadLinks';
import * as serviceWorker from './serviceWorker';

setDocumentHeadLinks();

ReactDOM.render(
  <Suspense fallback="Loading...">
    <App />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA.
serviceWorker.unregister();
