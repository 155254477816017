import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../../../components/atoms/Button/Button';
import Snackbar from '../../../../../components/atoms/Snackbar/Snackbar';
import CompanySelectFormField from '../../../../../components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import RoleSelectFormField from '../../../../../components/molecules/SelectFormField/RoleSelectFormField/RoleSelectFormField';
import TextFormField from '../../../../../components/molecules/TextFormField/TextFormField';
import ConfirmationModal from '../../../../../components/organisms/ConfirmationModal/ConfirmationModal';
import {
  IdentityPermissionRole,
  RoleType,
  UserDocument,
  UserRole,
  useUserRoleDeleteMutation,
  useUserRoleUpdateMutation,
} from '../../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../../interfaces/forms/form';
import { ISnackBar } from '../../../../../interfaces/snackBar';
import useUserStyles from '../../../UserStyle';
import { IModalStatus } from '../../UserDetails.interface';

interface IUserRole {
  item: UserRole;
  isSuperAdmin?: boolean;
  allowCompanyEditing: boolean;
}

export interface IUserRoles {
  companyId?: number;
  externalId: string;
  role?: IdentityPermissionRole;
}

interface ChangeUserRoles {
  id: number;
  companyId?: number;
  externalId?: string;
  role?: IdentityPermissionRole;
}

const CUserRole: FC<IUserRole> = ({
  item,
  isSuperAdmin,
  allowCompanyEditing,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useUserStyles();

  const [userRoles, setUserRoles] = useState<IUserRoles>({
    companyId: item?.companyRole?.companyId,
    externalId: item.externalId || '',
    role: item.companyRole.role.name.toUpperCase() as IdentityPermissionRole,
  });
  const [updateError, setUpdateError] = useState<String>('');
  const [changedUserRoles, setChangedUserRoles] = useState<ChangeUserRoles>({
    companyId: Number(item?.companyRole?.companyId),
    id: item.id,
  });
  const [snackbar, setSnackbar] = useState<ISnackBar>({
    show: false,
    msg: '',
  });
  const [modalStatus, setModalStatus] = useState<IModalStatus>({
    isOpen: false,
    msg: '',
  });

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setUserRoles(props => ({
      ...props,
      [name]: value,
    }));
    setChangedUserRoles(changedUserRoles => ({
      ...changedUserRoles,
      [name]: value,
    }));
  };

  const [userRoleUpdateMutation] = useUserRoleUpdateMutation({
    onError: error => {
      setUpdateError(error.message);
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'user role updated successfully' });
      setUpdateError('');
    },
  });

  const handleComfirmDeleteRole = () => {
    userRoleDeleteMutation({
      variables: { id: item.id },
    });
  };

  const [userRoleDeleteMutation] = useUserRoleDeleteMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      setSnackbar({ show: true, msg: 'role successfully deleted' });
      setUpdateError('');
      closeModal();
    },
    refetchQueries: [
      {
        query: UserDocument,
        variables: {
          id: item.userId,
        },
      },
    ],
  });

  const onUserRoleUpdate = () => {
    userRoleUpdateMutation({
      variables: {
        ...changedUserRoles,
      },
    });
  };

  const onUserRoleDelete = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: true,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, show: false });
  };

  const closeModal = () => {
    setModalStatus({
      ...modalStatus,
      isOpen: false,
    });
  };
  const isDisabled = item
    ? !isSuperAdmin && item.companyRole.role.type === RoleType.SzProtected
      ? true
      : false
    : false;
  return (
    <>
      <div
        style={{
          marginBottom: 30,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {allowCompanyEditing && (
          <CompanySelectFormField
            id="companyFormField"
            name="companyId"
            label="Company"
            value={userRoles?.companyId}
            onChange={handleChange}
            className={classes.mr10}
            isDisabled={isDisabled}
          />
        )}
        <RoleSelectFormField
          value={userRoles?.role}
          onChange={handleChange}
          className={classes.mr10}
          isSuperAdmin={isSuperAdmin}
          isDisabled={isDisabled}
          filterForIdentity={false}
        />
        <TextFormField
          id="externalId"
          name="externalId"
          label="External Id"
          value={userRoles?.externalId}
          onChange={handleChange}
          className={classes.mr10}
          isDisabled={isDisabled}
        />
        <div style={{ display: 'flex' }}>
          <div className={classes.mr10}>
            <Button onClick={onUserRoleDelete} isDisabled={isDisabled}>
              Delete
            </Button>
          </div>
          <div>
            <Button onClick={onUserRoleUpdate} isDisabled={isDisabled}>
              Update
            </Button>
          </div>
        </div>
      </div>
      {updateError.length > 0 && (
        <div className={classes.error}>{updateError}</div>
      )}
      <ConfirmationModal
        open={modalStatus.isOpen}
        message={`Please confirm you wish to delete role`}
        onSave={handleComfirmDeleteRole}
        onClose={closeModal}
        saveBtnText="DELETE"
      />
      <Snackbar
        open={snackbar.show}
        alertType="success"
        snackbarMsg={snackbar.msg}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

export default CUserRole;
