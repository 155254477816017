import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { appRoutes, formManagerRoutes } from '../../constants/navigation';
import CandidateInfoFields from '../../views/CandidateInfo/CandidateInfoFields/CandidateInfoFields';
import CandidateRequirements from '../../views/CandidateInfo/CompanyRequirements/CompanyRequirements';
import FormManager from '../../views/CandidateInfo/FormManagerView/FormManager';

interface ICandidateInfoSwitch {}

const CandidateInfoSwitch: FC<ICandidateInfoSwitch> = () => {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.formManager.path}
        render={() => <Redirect to={formManagerRoutes.fields.path} />}
      />
      <Route path={formManagerRoutes.fields.path}>
        <CandidateInfoFields />
      </Route>
      <Route path={formManagerRoutes.companyForms.path}>
        <CandidateRequirements />
      </Route>
      <Route path={formManagerRoutes.downloadSubmittedForms.path}>
        <FormManager />
      </Route>
    </Switch>
  );
};

export default CandidateInfoSwitch;
