import { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@material-ui/core';
import { TCheckboxFormFieldValue } from '../../../interfaces/forms/form';

interface ISwitchFormField {
  id: string;
  name: string;
  label?: string;
  onChange: (value: TCheckboxFormFieldValue, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  value: TCheckboxFormFieldValue;
  isDisabled?: boolean;
  color?: 'primary' | 'secondary' | 'default';
}

const SwitchFormField: FC<ISwitchFormField> = ({
  id,
  name,
  label,
  onChange,
  hasError = false,
  errorText,
  value,
  isDisabled = false,
  color = 'default',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, name);
  };

  return (
    <FormControl variant="outlined" fullWidth error={hasError}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            disabled={isDisabled}
            checked={value}
            onChange={handleChange}
            color={color}
            inputProps={{ 'aria-label': `${color} checkbox` }}
          />
        }
        label={label}
      />
      {hasError ? <FormHelperText>{errorText}</FormHelperText> : null}
    </FormControl>
  );
};

export default SwitchFormField;
