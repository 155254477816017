import { appRoutes, resultsRoutes } from 'constants/navigation';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CandidateUsage from 'views/Results/CandidateUsage/CandidateUsage';
import QBRData from 'views/Results/QBR/QBR';
import SkillsValidated from 'views/Results/SkillsValidated/SkillsValidated';
import StageCSV from 'views/Results/StageCSV/StageCSV';
import Users from 'views/Results/Users/Users';

interface IResultsSwitch {}

const ResultsSwitch: FC<IResultsSwitch> = () => {
  return (
    <Switch>
      <Route path={resultsRoutes.candidateResults.path}>
        <StageCSV />
      </Route>
      <Route path={resultsRoutes.candidateUsage.path}>
        <CandidateUsage />
      </Route>
      <Route path={resultsRoutes.skillsValidated.path}>
        <SkillsValidated />
      </Route>
      <Route path={resultsRoutes.users.path}>
        <Users />
      </Route>
      <Route path={resultsRoutes.QBRData.path}>
        <QBRData />
      </Route>
      <Redirect
        from={appRoutes.results.path}
        to={resultsRoutes.candidateResults.path}
      />
    </Switch>
  );
};

export default ResultsSwitch;
