import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { Typography } from '@material-ui/core';

interface IStageCSVFormErrorMessage {
  error?: ApolloError;
}

const StageCSVFormErrorMessage: FC<IStageCSVFormErrorMessage> = ({ error }) => {
  return error ? (
    <Typography color="error">Some error occured!</Typography>
  ) : null;
};

export default StageCSVFormErrorMessage;
