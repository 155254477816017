import { FC, memo, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../../../../../../components/atoms/Button/Button';
import TraitSelectFormField from '../../../../../../../components/molecules/SelectFormField/TraitSelectFormField/TraitSelectFormField';
import TextFormField from '../../../../../../../components/molecules/TextFormField/TextFormField';
import { TFormFieldValue } from '../../../../../../../interfaces/forms/form';
import { IConnectedTraitFormValues } from '../ConnectedTraitsEditList';

interface IConnectedTraitsEditListItem {
  softSkillTrait: IConnectedTraitFormValues;
  updateTraits: (values: IConnectedTraitFormValues) => void;
  deleteTraits: (values: IConnectedTraitFormValues) => void;
  traitsToBeExcluded?: number[];
}

const ConnectedTraitsEditListItem: FC<IConnectedTraitsEditListItem> = ({
  softSkillTrait,
  updateTraits,
  deleteTraits,
  traitsToBeExcluded,
}) => {
  const [values, setValues] = useState<IConnectedTraitFormValues>({
    id: softSkillTrait.id,
    traitName: softSkillTrait.traitName,
    traitId: softSkillTrait.traitId,
    softSkillId: softSkillTrait.softSkillId,
    weight: softSkillTrait.weight,
    orientation: softSkillTrait.orientation,
    isAdded: softSkillTrait.isAdded,
  });

  useEffect(() => {
    updateTraits(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleChangeTrait = (value: TFormFieldValue, name: string) => {
    setValues(prevValues => ({
      ...prevValues,
      traitId: (value as unknown) as number,
    }));
  };

  const handleChangeWeight = (value: string) => {
    setValues(prevValues => ({
      ...prevValues,
      weight: (value as unknown) as number,
    }));
  };

  const handleChangeOrientation = (value: string) => {
    setValues(prevValues => ({ ...prevValues, orientation: value }));
  };

  const handleDelete = () => {
    deleteTraits(values);
  };

  const key = softSkillTrait.id;
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      key={key}
    >
      <Grid item xs={3}>
        {softSkillTrait.isAdded ? (
          <TraitSelectFormField
            id={`${key}-traits`}
            name="traits"
            label="Traits"
            value={values.traitId}
            onChange={handleChangeTrait}
            traitsToBeExcluded={traitsToBeExcluded}
          />
        ) : (
          <Typography>{softSkillTrait.traitName}</Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          id={`${key}-weight`}
          name="weight"
          label="Weight"
          onChange={handleChangeWeight}
          value={`${values.weight}`}
        />
      </Grid>
      <Grid item xs={3}>
        <TextFormField
          id={`${key}-orientation`}
          name="orientation"
          label="Orientation"
          onChange={handleChangeOrientation}
          value={values.orientation}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={handleDelete}>Delete</Button>
      </Grid>
    </Grid>
  );
};

export const MemoizedConnectedTraitsEditListItem = memo(
  ConnectedTraitsEditListItem
);
