import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import SelectFormField from '../../../components/molecules/SelectFormField/SelectFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  IgCategoryColour,
  IgInterviewGuideCategory,
  IgInterviewGuideCategoryFindManyDocument,
  IgInterviewGuideCategoryFindManyQuery,
  IgInterviewGuideCategoryFindManyQueryVariables,
  useIgInterviewGuideCategoryUpdateMutation,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { colours } from '../../InterviewGuide/InterviewGuide.constants';
import { useInterviewGuideCategoryModalStyle } from './InterviewGuideCategoryConfigModalStyle';

// import WidgetConfigTextField from './WidgetConfigTextField/WidgetConfigTextField';

interface IInterviewGuideCategoryConfigModal {
  interviewGuideCategory: IgInterviewGuideCategory;
}
export interface IWidgetSetting {
  settings?: string;
  id?: number;
}

const InterviewGuideCategoryConfigModal: FC<IInterviewGuideCategoryConfigModal> = ({
  interviewGuideCategory,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useInterviewGuideCategoryModalStyle();
  const [
    updateInterviewGuideCategoryMutation,
  ] = useIgInterviewGuideCategoryUpdateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const retrievedInterviewGuideCategory =
        data?.IgInterviewGuideCategoryUpdate;
      if (retrievedInterviewGuideCategory) {
        const interviewGuideCategoriesData = cache.readQuery<
          IgInterviewGuideCategoryFindManyQuery,
          IgInterviewGuideCategoryFindManyQueryVariables
        >({
          query: IgInterviewGuideCategoryFindManyDocument,
          variables: {
            interviewGuideId: interviewGuideCategory.id,
          },
        });
        if (
          retrievedInterviewGuideCategory &&
          interviewGuideCategoriesData?.IgInterviewGuideCategoryFindMany
        ) {
          let temp =
            interviewGuideCategoriesData?.IgInterviewGuideCategoryFindMany;
          temp = temp.map((item, index) => {
            if (item.id === interviewGuideCategory.id) {
              item.colour = retrievedInterviewGuideCategory.colour;
            }
            return item;
          });
          cache.writeQuery<
            IgInterviewGuideCategoryFindManyQuery,
            IgInterviewGuideCategoryFindManyQueryVariables
          >({
            query: IgInterviewGuideCategoryFindManyDocument,
            variables: {
              interviewGuideId: interviewGuideCategory.id,
            },
            data: {
              IgInterviewGuideCategoryFindMany: temp,
            },
          });
        }
      }
    },
  });
  const [
    updatedInterviewGuideCategory,
    setUpdatedInterviewGuideCategory,
  ] = useState<IgInterviewGuideCategory>(interviewGuideCategory || {});

  const handleCloseModal = () => {
    closeModal(ModalTypes.INTERVIEW_GUIDE_CATEGORY_CONFIG_MODAL);
  };

  const handleSubmit = () => {
    if (
      updatedInterviewGuideCategory.colour?.toString() &&
      interviewGuideCategory.order?.toString()
    ) {
      const variables = {
        id: interviewGuideCategory.id,
        order: interviewGuideCategory.order,
        colour: updatedInterviewGuideCategory.colour as IgCategoryColour,
      };
      updateInterviewGuideCategoryMutation({ variables });
    }
    handleCloseModal();
  };
  const handleColourChange = (value: TSelectFormFieldValue) => {
    setUpdatedInterviewGuideCategory({
      ...interviewGuideCategory,
      colour: value as IgCategoryColour,
    });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid style={{ marginBottom: 10 }}>
          <Typography>Name: {interviewGuideCategory?.category.name}</Typography>
        </Grid>
        <Grid style={{ marginBottom: 10 }}>
          <Typography>
            Internal Name: {interviewGuideCategory?.category.internalName}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <SelectFormField
            value={updatedInterviewGuideCategory.colour || ''}
            name={`${updatedInterviewGuideCategory.id}-name`}
            id={`${updatedInterviewGuideCategory.id}`}
            label="Colour"
            options={colours}
            onChange={handleColourChange}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="default"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default InterviewGuideCategoryConfigModal;
