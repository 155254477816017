import _ from 'lodash';
import { FC, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import Button from '../../../components/atoms/Button/Button';
import Modal from '../../../components/molecules/Modal/Modal';
import WidgetTypeSelectFormField from '../../../components/molecules/SelectFormField/WidgetTypeSelectFormField/WidgetTypeSelectFormField';
import TextFormField from '../../../components/molecules/TextFormField/TextFormField';
import { ModalTypes } from '../../../enums/modalTypes';
import {
  useWidgetCreateMutation,
  Widget,
  WidgetType,
} from '../../../generated/graphql';
import { closeModal } from '../../../graphql/modals';
import { addWidgetToCache } from '../../../graphql/widget';
import validate from '../../../helpers/validate';
import { TSelectFormFieldValue } from '../../../interfaces/forms/form';
import { useReportModalStyle } from '../ReportsModal/ReportsModalStyle';

interface IAddWidgetPayload {
  widgetName: string;
  widgetType: TSelectFormFieldValue;
}
interface IAddWidgetError {
  widgetName: string;
  widgetType: string;
}

const AddWidgetModal: FC = () => {
  const { handleMsgType } = useNotification();

  const classes = useReportModalStyle();
  const initialValue = {
    widgetName: '',
    widgetType: '',
  };

  const [createWidgetMutation] = useWidgetCreateMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    update: (cache, { data }) => {
      const widget = data?.Widget as Widget;
      if (widget) addWidgetToCache(cache, widget);
    },
    onCompleted: () => {
      closeModal(ModalTypes.ADD_WIDGET_MODAL);
    },
  });

  const [widgetInfo, setWidgetInfo] = useState<IAddWidgetPayload>(initialValue);

  const [errors, setErrors] = useState<IAddWidgetError>(initialValue);
  const handleCloseModal = () => {
    closeModal(ModalTypes.ADD_WIDGET_MODAL);
  };

  const getWidgetName = (value: string) => {
    setWidgetInfo(prev => ({ ...prev, widgetName: value }));
    if (errors.widgetName) {
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, widgetName: '' } : prevErrors
      );
    }
  };

  const getWidgetType = (value: TSelectFormFieldValue) => {
    setWidgetInfo(prev => ({ ...prev, widgetType: value }));
    if (errors.widgetType)
      setErrors(prevErrors =>
        prevErrors ? { ...prevErrors, widgetType: '' } : prevErrors
      );
  };

  const addWidgetFormValidation = (newValues: IAddWidgetPayload) =>
    validate(newValues, {
      widgetName: {
        nonEmptyString: true,
      },
      widgetType: {
        nonEmptyString: {
          message: 'must be selected',
        },
      },
    });

  const handleSubmitWidget = () => {
    const reportErrors = addWidgetFormValidation(widgetInfo);
    if (!_.isEmpty(reportErrors)) return setErrors(reportErrors);
    const variables = {
      name: widgetInfo.widgetName,
      type: widgetInfo.widgetType as WidgetType,
    };
    createWidgetMutation({ variables });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography variant="h2" className={classes.h2}>
            New Widget
          </Typography>
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <TextFormField
            id="widgetName"
            name="widgetName"
            value={widgetInfo.widgetName}
            label="Widget Name"
            onChange={getWidgetName}
            hasError={!!errors?.widgetName}
            errorText={errors?.widgetName}
          />
        </Grid>
        <Grid item className={classes.textfieldContainer}>
          <WidgetTypeSelectFormField
            id="typeFormField"
            name="widgetType"
            label="Widget Type"
            value={widgetInfo.widgetType}
            onChange={getWidgetType}
            hasError={!!errors?.widgetType}
            errorText={errors?.widgetType}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
        >
          <Button
            color="default"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmitWidget}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddWidgetModal;
