import { makeStyles } from '@material-ui/core/styles';

const useProjectManagerFormStyle = makeStyles({
  button: {
    height: 48,
    width: 150,
    marginTop: 5,
    marginRight: 5,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default useProjectManagerFormStyle;
