import { getAccessToken } from 'helpers/auth';
import { FunctionComponent } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';

interface IPrivateRoute extends RouteProps {
  component: FunctionComponent<RouteComponentProps>;
  redirectUrl?: string;
}

const PrivateRoute: FunctionComponent<IPrivateRoute> = ({
  component: Component,
  redirectUrl = '/auth',
  ...rest
}) => {
  const accessToken = getAccessToken();
  return (
    <Route
      {...rest}
      render={props =>
        accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
