import { FC } from 'react';
import { InputBaseComponentProps } from '@material-ui/core';
import {
  SoftSkillFindManyDocument,
  SoftSkillFindManyQuery,
} from '../../../../generated/graphql';
import { TSelectFormFieldValue } from '../../../../interfaces/forms/form';
import SelectWithQuery from '../SelectWithQuery/SelectWithQuery';

interface ISoftSkillSelectFormField {
  id: string;
  name: string;
  value: TSelectFormFieldValue;
  label?: string;
  onChange: (value: TSelectFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  isDisabled?: boolean;
  inputProps?: InputBaseComponentProps;
}

const SoftSkillSelectFormField: FC<ISoftSkillSelectFormField> = props => {
  const getOptionsFromResponse = (response: SoftSkillFindManyQuery) => {
    if (response.SoftSkillFindMany)
      return response.SoftSkillFindMany.map(softSkill => ({
        label: softSkill.name,
        value: softSkill.id,
      }));
    else return [];
  };

  return (
    <SelectWithQuery
      query={SoftSkillFindManyDocument}
      getOptionsFromResponse={getOptionsFromResponse}
      selectElementProps={props}
      queryOptions={{
        variables: {
          isArchived: false,
        },
      }}
    />
  );
};

export default SoftSkillSelectFormField;
