import Button from 'components/atoms/Button/Button';
import { reportsRoutes } from 'constants/navigation';
import { ReportConfig, WidgetType } from 'generated/graphql';
import { formatDate } from 'helpers/date';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow } from '@material-ui/core';
import useWidgetsTableRowStyle from './WidgetsTableRowStyle';

interface IWidgetsTableRow {
  name: string;
  id: number;
  type: WidgetType;
  isArchived: boolean;
  reportConfigs: ReportConfig[];
  updatedAt: string;
}

const WidgetsTableRow: React.FC<IWidgetsTableRow> = ({
  name,
  id,
  type,
  reportConfigs,
  isArchived,
  updatedAt,
}) => {
  const classes = useWidgetsTableRowStyle();
  const history = useHistory();

  const handleViewBtnClick = () => {
    history.push(reportsRoutes.widget.url(id), {
      name,
      type,
      isArchived,
    });
  };
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>{name}</TableCell>
      <TableCell className={classes.tableCell}>
        {reportConfigs?.length}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {formatDate(updatedAt, 'dd MMM yyyy')}{' '}
        {isArchived && <span className={classes.archivedText}>Archived</span>}
      </TableCell>
      <TableCell align="right">
        <Button className={classes.btn} onClick={handleViewBtnClick}>
          VIEW
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default WidgetsTableRow;
