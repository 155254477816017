import Icon from 'components/atoms/Icon';
import { ModalTypes } from 'enums/modalTypes';
import {
  DeiStageActionFieldsModel,
  useDeiStageActionArchiveMutation,
} from 'generated/graphql';
import { closeModal, openModal } from 'graphql/modals';
import { NotificationModalProps } from 'views/ModalView/NotificationModal';
import {
  Button,
  TextInputField,
  TNotification,
  Tooltip,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  getDeiTooltipContent,
  removeModalContent,
  updateCacheHelper,
} from './DeiActionForm/dei.helpers';

interface IStageDeiFormAction {
  stageId: number;
  projectId: number;
  fields: DeiStageActionFieldsModel;
  completedCount?: number;
}

const StageDeiFormAction = ({
  stageId,
  projectId,
  fields,
  completedCount,
}: IStageDeiFormAction) => {
  const { handleMsgType } = useNotification();
  const questionNumbers = Object.values(fields).filter(
    elem => elem !== 'DeiStageActionFieldsModel' && elem
  ).length;
  const questionLabel = `${questionNumbers} question${
    questionNumbers > 1 ? 's' : ''
  }`;
  const tooltipContent = getDeiTooltipContent(fields);

  const [archiveDeiForm] = useDeiStageActionArchiveMutation({
    onCompleted: () => {
      handleMsgType({
        type: TNotification.success,
        message: `Diversity, equity & inclusion action removed`,
      });
    },
    onError: () => {
      handleMsgType({
        type: TNotification.error,
        title: `Diversity, equity & inclusion action could not be removed.`,
        message: 'Something went wrong. Please try again.',
      });
    },
    update(cache, { data }) {
      if (data?.DeiStageActionArchive) {
        updateCacheHelper(
          cache,
          data.DeiStageActionArchive,
          projectId,
          stageId
        );
      }
    },
  });

  const onEditButtonClick = () => {
    const modalProps = {
      stageId,
      projectId,
      completedCount: completedCount,
    };
    openModal(ModalTypes.EDIT_DEI_ACTION, modalProps);
  };

  const onRemoveButtonClick = () => {
    const confirmationModalProps: NotificationModalProps = {
      ...removeModalContent,
      onOkClick: () => {
        archiveDeiForm({
          variables: {
            stageId: stageId,
          },
        });
        closeModal(ModalTypes.NOTIFICATION_MODAL);
      },
    };

    openModal(ModalTypes.NOTIFICATION_MODAL, confirmationModalProps);
  };

  return (
    <div
      className="stage-dei-action"
      data-testid="edit-project__stage-dei-action"
    >
      <Icon icon="dei" className="stage-dei-action__dei-icon" />
      <TextInputField
        id={`stage-${stageId}-dei-action-name`}
        disabled
        onChange={() => {}}
        value="Diversity, equity & inclusion"
        className="stage-dei-action__action-name-label"
      />
      <div className="stage-dei-action__questions-preview">
        <p data-testid="edit-project__stage-dei-action-questions-preview">
          {questionLabel}
        </p>
        <Tooltip content={tooltipContent} id="dei-tooltip-trigger" place="top">
          <Icon
            icon="eye"
            id="dei-tooltip-trigger"
            className="stage-dei-action__questions-preview__eye-icon"
          />
        </Tooltip>
      </div>
      <Button
        variant="secondary"
        size="medium"
        className="stage-dei-action__edit-button"
        data-testid="edit-project__stage-dei-action-edit-button"
        onClick={onEditButtonClick}
      >
        <Icon
          icon="bluePencil"
          className="stage-dei-action__edit-button-icon"
        />
      </Button>
      <Button
        variant="secondary"
        size="medium"
        className="stage-dei-action__remove-button"
        data-testid="edit-project__stage-dei-action-remove-button"
        onClick={onRemoveButtonClick}
      >
        Remove
      </Button>
    </div>
  );
};

export default StageDeiFormAction;
