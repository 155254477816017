import { FC, useContext } from 'react';
import useTraitContentListItemStyle from 'views/StaticContent/TraitContent/TraitContentList/TraitContentListItem/TraitContentListItemStyle';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../../../components/atoms/Button/Button';
import { ModalTypes } from '../../../../enums/modalTypes';
import { openModal } from '../../../../graphql/modals';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';

export interface ITraitContentHeader {}

const TraitContentHeader: FC<ITraitContentHeader> = () => {
  const { filters, updateFilters } = useContext(StaticContentContext);
  const classes = useTraitContentListItemStyle();
  const openStaticContentFiltersModal = () => {
    openModal(ModalTypes.STATIC_CONTENT_FILTERS_MODAL, {
      isSoftSkillFilterIncluded: true,
      isTraitFilterIncluded: true,
      isClassificationFilterIncluded: true,
      isRoleLevelFilterIncluded: true,
      filters: filters,
      onSubmit: updateFilters,
    });
  };
  return (
    <Grid item container direction="row" alignItems="center" spacing={3}>
      <Grid item xs={1}>
        <Typography variant="h6">Soft Skills</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">Trait</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">
          Classifi
          <wbr />
          &#173;cation
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h6">Role level</Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemTextArea}>
        <Typography variant="h6">Summary</Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemTextArea}>
        <Typography variant="h6">Content</Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemTextArea}>
        <Typography variant="h6">Development feedback</Typography>
      </Grid>
      <Grid item xs={1}>
        <Button onClick={openStaticContentFiltersModal}>Filter/Sort</Button>
      </Grid>
    </Grid>
  );
};

export default TraitContentHeader;
