import { appRoutes, contentRoutes } from 'constants/navigation';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import InterviewQuestionContent from 'views/StaticContent/InterviewQuestionContent/InterviewQuestionContent';
import SoftSkillContent from 'views/StaticContent/SoftSkillContent/SoftSkillContent';
import SoftSkills from 'views/StaticContent/SoftSkills/SoftSkills';
import SoftSkillSuccessProfileContent from 'views/StaticContent/SoftSkillSuccessProfileContent/SoftSkillSuccessProfileContent';
import TechnicalSkills from 'views/StaticContent/TechnicalSkills/TechnicalSkills';
import TraitContent from 'views/StaticContent/TraitContent/TraitContent';
import Traits from 'views/StaticContent/Traits/Traits';
import Validate from 'views/StaticContent/Validate/Validate';
import BehaviourContent from 'views/StaticContent/BehaviourContent/BehaviourContent';

interface IStaticContentSwitch {}

const StaticContentSwitch: FC<IStaticContentSwitch> = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.content.path}>
        {() => (
          <Redirect
            from={appRoutes.content.path}
            to={contentRoutes.softSkills.path}
          />
        )}
      </Route>
      <Route path={contentRoutes.softSkills.path}>
        <SoftSkills />
      </Route>
      <Route path={contentRoutes.traits.path}>
        <Traits />
      </Route>
      <Route path={contentRoutes.softSkillContent.path}>
        <SoftSkillContent />
      </Route>
      <Route path={contentRoutes.softSkillSuccessProfileContent.path}>
        <SoftSkillSuccessProfileContent />
      </Route>
      <Route path={contentRoutes.traitContent.path}>
        <TraitContent />
      </Route>
      <Route path={contentRoutes.interviewQuestionContent.path}>
        <InterviewQuestionContent />
      </Route>
      <Route path={contentRoutes.validate.path}>
        <Validate />
      </Route>
      <Route path={contentRoutes.technicalSkills.path}>
        <TechnicalSkills />
      </Route>
      <Route path={contentRoutes.behaviourContent.path}>
        <BehaviourContent />
      </Route>
    </Switch>
  );
};

export default StaticContentSwitch;
