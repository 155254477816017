import { FC } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { FieldOwnerType } from '../../../../../enums/forms/form';
import { ModalTypes } from '../../../../../enums/modalTypes';
import { openModal } from '../../../../../graphql/modals';
import { TFieldGQL } from '../../../../../interfaces/forms/formGQL';
import useCandidateInfoFieldsListItemStyle from './CandidateInfoFieldsListItemStyle';

interface ICandidateInfoFieldsListItem {
  candidateInfoField: TFieldGQL;
}

const CandidateInfoFieldsListItem: FC<ICandidateInfoFieldsListItem> = ({
  candidateInfoField,
}) => {
  const classes = useCandidateInfoFieldsListItemStyle();

  const handleOpenCreateCandidateInfoFieldModal = () => {
    openModal(ModalTypes.CANDIDATE_INFO_FIELD_MODAL, {
      candidateInfoFieldId: candidateInfoField.id,
    });
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={3} className={classes.gridItemWrapper}>
        <Typography>{candidateInfoField.name}</Typography>
      </Grid>
      <Grid item xs={3} className={classes.gridItemWrapper}>
        <Typography>
          {candidateInfoField.fieldOwnerType ===
          FieldOwnerType.COMPANY_FIELD_OWNER
            ? candidateInfoField.fieldOwnerName
            : ''}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.gridItemWrapper}>
        <Typography>{candidateInfoField.formManagerType}</Typography>
      </Grid>
      <Grid item xs={2} className={classes.gridItemWrapper}>
        <Button
          onClick={handleOpenCreateCandidateInfoFieldModal}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          Edit
        </Button>
      </Grid>
    </Grid>
  );
};

export default CandidateInfoFieldsListItem;
