import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ModalTypes } from '../../../../../enums/modalTypes';
import {
  Trait,
  TraitFragmentDoc,
  TraitsDocument,
  TraitsQuery,
  TraitsQueryVariables,
  useArchiveTraitMutation,
  useDeleteTraitMutation,
} from '../../../../../generated/graphql';
import { closeModal } from '../../../../../graphql/modals';
import TraitFormHeaderPresentational from './TraitFormHeaderPresentational/TraitFormHeaderPresentational';

export interface ITraitFormHeader {
  trait?: Trait;
  isEditMode: boolean;
}

const TraitFormHeader: FC<ITraitFormHeader> = ({ trait, isEditMode }) => {
  const { handleMsgType } = useNotification();

  const [error, setError] = useState('');
  const [archiveTraitMutation] = useArchiveTraitMutation({
    onError: error => {
      setError(error.message);
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, { data }) => {
      if (data?.Trait) {
        cache.writeFragment<Trait>({
          id: cache.identify(data?.Trait),
          fragment: TraitFragmentDoc,
          fragmentName: 'Trait',
          data: data.Trait,
        });

        try {
          const cachedTraitsResult = cache.readQuery<
            TraitsQuery,
            TraitsQueryVariables
          >({
            query: TraitsDocument,
            variables: {
              isArchived: false,
            },
          });

          if (cachedTraitsResult?.Traits) {
            const updatedTraitsList = data.Trait.isArchived
              ? cachedTraitsResult.Traits.filter(
                  cachedTrait => cachedTrait.id !== trait?.id
                )
              : [...cachedTraitsResult.Traits, data.Trait];

            cache.writeQuery<TraitsQuery, TraitsQueryVariables>({
              query: TraitsDocument,
              variables: {
                isArchived: false,
              },
              data: {
                Traits: updatedTraitsList,
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });
  const [deleteTraitMutation] = useDeleteTraitMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    onCompleted: () => {
      handleCloseModal();
    },
    update: (cache, { data }) => {
      if (data?.affected) {
        try {
          const cachedTraitsResult = cache.readQuery<
            TraitsQuery,
            TraitsQueryVariables
          >({
            query: TraitsDocument,
            variables: {
              isArchived: false,
            },
          });

          if (cachedTraitsResult?.Traits) {
            cache.writeQuery<TraitsQuery, TraitsQueryVariables>({
              query: TraitsDocument,
              variables: {
                isArchived: false,
              },
              data: {
                Traits: cachedTraitsResult.Traits.filter(
                  cachedTrait => cachedTrait.id !== trait?.id
                ),
              },
            });
          }
        } catch (error) {
          console.log(error);
        }

        try {
          const cachedTraitsResult = cache.readQuery<
            TraitsQuery,
            TraitsQueryVariables
          >({
            query: TraitsDocument,
          });

          if (cachedTraitsResult?.Traits) {
            cache.writeQuery<TraitsQuery, TraitsQueryVariables>({
              query: TraitsDocument,
              data: {
                Traits: cachedTraitsResult.Traits.filter(
                  cachedTrait => cachedTrait.id !== trait?.id
                ),
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_TRAIT_MODAL);
  };

  const handleArchive = () => {
    archiveTraitMutation({
      variables: {
        id: trait?.id as number,
        isArchived: !(trait?.isArchived as boolean),
      },
    });
  };

  const handleDelete = () => {
    deleteTraitMutation({
      variables: {
        id: trait?.id as number,
      },
    });
  };

  return (
    <Grid container item>
      <TraitFormHeaderPresentational
        isEditMode={isEditMode}
        isArchived={trait?.isArchived}
        onArchive={handleArchive}
        onDelete={handleDelete}
        error={error}
      />
    </Grid>
  );
};

export default TraitFormHeader;
