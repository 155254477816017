import './styles.scss';
import { ReactComponent as Logo } from 'icons/SpottedZebraLogo.svg';
import { navbarConfig } from 'navigation/AppbarNavigation/MainNavbar.constants';
import { FC, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Logout from './Logout';

const AppBar: FC = () => (
  <div className="appbar">
    <div className="appbar__left-content">
      <Logo className="appbar__logo" />
      <nav className="appbar__nav">
        {navbarConfig.map(route => (
          <Fragment key={route.title}>
            <NavLink
              activeClassName="appbar__nav-item_active"
              className="appbar__nav-item"
              title={route.title}
              to={route.path}
            >
              <div className="appbar__nav-item-content">{route.title}</div>
              <div className="appbar__nav-item-underline" />
            </NavLink>
          </Fragment>
        ))}
      </nav>
    </div>
    <div>
      <Logout />
    </div>
  </div>
);

export default AppBar;
