import { FC, useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { TraitRoleLevelContent } from '../../../../generated/graphql';
import { StaticContentContext } from '../../StaticContentContext/StaticContentContext';
import TraitContentListItem from './TraitContentListItem/TraitContentListItem';

export interface ITraitContentList {
  traitContent: TraitRoleLevelContent[];
  count: number; // Number of content pieces in the database
}

const TraitContentList: FC<ITraitContentList> = ({ traitContent, count }) => {
  const rowsPerPage = 50;
  const { filters, updateFilters } = useContext(StaticContentContext);
  const [page, setPage] = useState(1);

  const handlePageChange = (_event: unknown, value: number) => {
    setPage(value);
    updateFilters({ ...filters, page: value, take: rowsPerPage });
  };

  useEffect(() => {
    setPage(filters.page ? filters.page : 1);
  }, [filters]);

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
    >
      {traitContent.map(row => {
        return (
          <TraitContentListItem
            contentPiece={row}
            key={`${row.softSkillId}-${row.traitId}-${row.roleLevel}-${row.classification}`}
          />
        );
      })}
      <Grid item>
        <Pagination
          count={Math.ceil(count / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
};

export default TraitContentList;
