import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { companyRoutes, editCompanyRoutes } from '../../constants/navigation';
import CompanyApiKeys from '../../views/Company/CompanyView/CompanyApiKeys/CompanyApiKeys';
import CompanyRoles from '../../views/Company/CompanyView/CompanyRoles/CompanyRoles';
import EditCompany from '../../views/Company/CompanyView/EditCompany/EditCompany';

interface IEditCompanySwitch {}

const EditCompanySwitch: FC<IEditCompanySwitch> = () => {
  return (
    <Switch>
      <Route exact path={companyRoutes.editCompany.path}>
        <EditCompany />
      </Route>
      <Route path={editCompanyRoutes.editCompanyApiKeys.path}>
        <CompanyApiKeys />
      </Route>
      <Route path={editCompanyRoutes.editCompanyRoles.path}>
        <CompanyRoles />
      </Route>
    </Switch>
  );
};

export default EditCompanySwitch;
