import { FC, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  TraitRoleLevelContent,
  useTraitRoleLevelContentFindManyAndCountLazyQuery,
} from '../../../generated/graphql';
import { StaticContentContext } from '../StaticContentContext/StaticContentContext';
import TraitContentHeader from './TraitContentHeader/TraitContentHeader';
import TraitContentList from './TraitContentList/TraitContentList';
import useTraitContentStyle from './TraitContentStyle';

export interface ITraitContent {}

const TraitContent: FC<ITraitContent> = () => {
  const { filters } = useContext(StaticContentContext);
  const classes = useTraitContentStyle();
  const [
    traitContentQuery,
    traitContentQueryResponse,
  ] = useTraitRoleLevelContentFindManyAndCountLazyQuery();

  useEffect(() => {
    const {
      softSkills,
      traits,
      roleLevels,
      classifications,
      page,
      take,
      sortBy,
    } = filters;
    traitContentQuery({
      variables: {
        softSkillIds: softSkills,
        traitIds: traits,
        roleLevels,
        classifications,
        page: page ? page : 1,
        take: take ? take : 50,
        order: sortBy ? sortBy : [],
      },
    });
  }, [filters, traitContentQuery]);

  if (traitContentQueryResponse.error) {
    return <Typography className={classes.container}>Error</Typography>;
  }

  if (traitContentQueryResponse.loading) {
    return (
      <Typography className={classes.container}>Loading content...</Typography>
    );
  }

  if (traitContentQueryResponse.data) {
    const traitContent = traitContentQueryResponse.data.traitContent;
    const count = traitContent.count;

    if (traitContent.traitContent) {
      return (
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={5}
          className={classes.container}
        >
          <TraitContentHeader />
          <TraitContentList
            traitContent={traitContent.traitContent as TraitRoleLevelContent[]}
            count={count as number}
          />
        </Grid>
      );
    }
  }

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={5}
      className={classes.container}
    >
      <TraitContentHeader />
      <Typography>No content to display</Typography>
    </Grid>
  );
};

export default TraitContent;
