import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  SoftSkillTrait,
  Trait,
  useFindManySoftSkillTraitsBySsIdLazyQuery,
  useSoftSkillTraitsLazyQuery,
} from '../../../../generated/graphql';
import {
  TFormFieldValue,
  TMultiSelectFormFieldValue,
} from '../../../../interfaces/forms/form';
import MultiselectFormField from '../MultiselectFormField';

export interface ITraitFiltersMultiselectFormField {
  id: string;
  name: string;
  value: TMultiSelectFormFieldValue;
  label: string;
  onChange: (value: TFormFieldValue, name: string) => void;
  onBlur?: (value: string, name: string) => void;
  hasError?: boolean;
  errorText?: string;
  softSkillIds?: number[];
}

const TraitFiltersMultiselectFormField: FC<ITraitFiltersMultiselectFormField> = props => {
  const { handleMsgType } = useNotification();

  const [softSkillTraits, setSoftSkillTraits] = useState<SoftSkillTrait[]>([]);
  const [
    softSkillTraitsQuery,
    softSkillTraitsQueryResponse,
  ] = useSoftSkillTraitsLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [
    softSkillTraitsBySSIdsQuery,
    softSkillTraitsBySSIdsQueryResponse,
  ] = useFindManySoftSkillTraitsBySsIdLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  useEffect(() => {
    getTraits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.softSkillIds]);

  const getTraits = () => {
    // If there are filters for some softSkills, get traits just for those softSkills
    if (props.softSkillIds && !_.isEmpty(props.softSkillIds)) {
      softSkillTraitsBySSIdsQuery({
        variables: {
          ids: props.softSkillIds,
        },
      });
      // Else get all traits
    } else {
      softSkillTraitsQuery();
    }
  };

  useEffect(() => {
    if (softSkillTraitsQueryResponse.data) {
      setSoftSkillTraits(
        softSkillTraitsQueryResponse.data.softSkillTraits as SoftSkillTrait[]
      );
    }
  }, [softSkillTraitsQueryResponse]);

  useEffect(() => {
    if (props.softSkillIds && softSkillTraitsBySSIdsQueryResponse.data) {
      if (props.softSkillIds?.length === 1) {
        // If there is only one softSkill, the state needs to be cleared and populated with the new data
        setSoftSkillTraits(
          softSkillTraitsBySSIdsQueryResponse.data
            ?.softSkillTraits as SoftSkillTrait[]
        );
      } else {
        // Else there are many softSkills, meaning the state needs to be pushed to and not reset
        setSoftSkillTraits(prevValues => [
          ...prevValues,
          ...(softSkillTraitsBySSIdsQueryResponse.data
            ?.softSkillTraits as SoftSkillTrait[]),
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [softSkillTraitsBySSIdsQueryResponse]);

  if (
    softSkillTraitsQueryResponse.error ||
    softSkillTraitsBySSIdsQueryResponse.error
  ) {
    return <Typography>Error</Typography>;
  }

  if (
    softSkillTraitsQueryResponse.loading ||
    softSkillTraitsBySSIdsQueryResponse.loading
  ) {
    return <Typography>Loading traits filters..</Typography>;
  }

  if (
    softSkillTraitsQueryResponse.data ||
    softSkillTraitsBySSIdsQueryResponse.data
  ) {
    let traitIds = [];
    let uniqueTraits: Trait[] = [];

    // Make sure there are no duplicate traits
    for (let i = 0; i < softSkillTraits.length; i++) {
      let isInArray = false;
      traitIds.forEach(traitId => {
        if (softSkillTraits[i].traitId === traitId) isInArray = true;
      });
      if (!isInArray) {
        traitIds.push(softSkillTraits[i].traitId);
        uniqueTraits.push(softSkillTraits[i]?.trait as Trait);
      }
    }

    uniqueTraits = _.sortBy(uniqueTraits, 'name');
    return (
      <MultiselectFormField
        {...props}
        inputProps={{
          'data-role': 'soft-skill-filter-select',
        }}
        options={uniqueTraits.map(trait => ({
          label: trait.name,
          value: trait.id,
        }))}
      />
    );
  }

  return null;
};

export default TraitFiltersMultiselectFormField;
