import { Trait } from 'generated/graphql';
import { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import TraitsListItem from './TraitsListItem/TraitsListItem';
import useTraitsListStyle from './TraitsListStyle';

interface ITraitsList {
  traits: Trait[];
}

const TraitsList: FC<ITraitsList> = ({ traits }) => {
  const classes = useTraitsListStyle();
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Trait ID</Typography>
        </Grid>
        <Grid item xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Name</Typography>
        </Grid>
        <Grid item xs={3} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Description</Typography>
        </Grid>
        <Grid item xs={2} className={classes.listHeadingGridItemWrapper}>
          <Typography variant="h6">Type(s)</Typography>
        </Grid>
        <Grid item xs={3} className={classes.listHeadingGridItemWrapper}></Grid>
      </Grid>
      {traits?.map(trait => (
        <TraitsListItem trait={trait} key={trait.id} />
      ))}
    </Grid>
  );
};

export default TraitsList;
