import './styles.scss';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import ResultsNavigation from '../../navigation/ResultsNavigation/ResultsNavigation';
import ResultsSwitch from '../../navigation/ResultsNavigation/ResultsSwitch';

interface IResults {}

const useResultsStyle = makeStyles(() => ({
  container: {
    padding: '30px 30px 30px 270px',
  },
}));
const Results: FC<IResults> = () => {
  const classes = useResultsStyle();

  return (
    <>
      <ResultsNavigation />
      <div className={classes.container}>
        <ResultsSwitch />
      </div>
    </>
  );
};

export default Results;
