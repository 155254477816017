import { FC } from 'react';
import ReportNavigation from '../../navigation/ReportNavigation/ReportNavigation';
import ReportsSwitch from '../../navigation/ReportNavigation/ReportSwitch';
import useReportStyle from './ReportsStyle';

interface ReportsMain {}

const ReportMain: FC<ReportsMain> = () => {
  const classes = useReportStyle();
  return (
    <>
      <ReportNavigation />
      <div className={classes.container}>
        <ReportsSwitch />
      </div>
    </>
  );
};

export default ReportMain;
