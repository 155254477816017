import './styles.scss';
import Icon from 'components/atoms/Icon';
import { appRoutes } from 'constants/navigation';
import {
  ProjectRequestFileUploadFindManyQuery,
  ProjectRequestFragment,
  ProjectRequestUserModelFragment,
  RelationDepth,
  useCompanyLazyQuery,
  useCompanyMaybeUserEmployeeFindManyLazyQuery,
  useProjectRequestFileUploadFindManyQuery,
  useProjectRequestFindOneLazyQuery,
  useProjectRequestUserFindManyLazyQuery,
} from 'generated/graphql';
import { ProjectRequestPath } from 'interfaces/routes';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  TNotification,
  useNotification,
  Button,
  Loader,
} from '@spotted-zebra-uk/ui-components';
import { ArchiveProjectRequestButton } from '../components/ArchiveProjectRequestButton';
import CompanySign from '../components/CompanySign';
import { DeleteProjectRequestButton } from '../components/DeleteProjectRequestButton';
import { UnarchiveProjectRequestButton } from '../components/UnarchiveProjectRequestButton';
import ContactsList from './ContactsList';
import DocumentsList from './DocumentsList';
import ProjectAndApprovalActions from './ProjectAndApprovalActions';
import ProjectNameField from './ProjectNameField';
import ProjectRequestActions from './ProjectRequestActions';
import ProjectRequestStatusSelect from './ProjectRequestStatusSelect';
import { TContactData } from './types';

interface IProjectRequestPresentational {
  projectRequest: ProjectRequestFragment;
  projectRequestUsers: ProjectRequestUserModelFragment[];
  contacts: TContactData[];
  documents: ProjectRequestFileUploadFindManyQuery['ProjectRequestFileUploadFindMany'];
  onProjectRequestsButtonClick: () => void;
  onProjectRequestDeleted: () => void;
}

const ProjectRequestPresentational: FC<IProjectRequestPresentational> = ({
  projectRequest,
  projectRequestUsers,
  contacts,
  documents,
  onProjectRequestsButtonClick,
  onProjectRequestDeleted,
}) => (
  <div className="project-request">
    <div className="project-request__header">
      <div className="project-request__header-left">
        <Button
          onClick={onProjectRequestsButtonClick}
          size="small"
          variant="text"
          className="project-request__back-button"
          leftIcon={
            <Icon
              icon="left_arrow"
              className="project-request__back-button-icon"
            />
          }
        >
          Project Requests
        </Button>
      </div>
      <div className="project-request__header-right">
        {projectRequest.deletedAt ? (
          <>
            <UnarchiveProjectRequestButton
              projectRequestId={projectRequest.id}
            />
            <DeleteProjectRequestButton
              projectRequestId={projectRequest.id}
              projectRequestName={projectRequest.projectName}
              onCompleted={onProjectRequestDeleted}
            />
          </>
        ) : (
          <ArchiveProjectRequestButton
            projectRequestId={projectRequest.id}
            projectRequestName={projectRequest.projectName}
          />
        )}
      </div>
    </div>
    <div className="project-request__content">
      <div className="project-request-update">
        <div className="project-request-update__company-sign">
          <CompanySign companyId={projectRequest.companyId} />
        </div>
        <div className="project-request-update__header">
          <ProjectNameField
            projectRequestId={projectRequest.id}
            projectName={projectRequest.projectName}
          />
          <ProjectRequestStatusSelect
            projectRequestId={projectRequest.id}
            projectRequestStatus={projectRequest.status}
          />
        </div>
        <ContactsList contacts={contacts} creator={projectRequest.creator} />
        {documents.length ? <DocumentsList documents={documents} /> : null}
        <ProjectRequestActions
          projectRequestId={projectRequest.id}
          companyId={projectRequest.companyId}
        />
        <ProjectAndApprovalActions
          projectRequest={projectRequest}
          projectRequestUsers={projectRequestUsers}
        />
      </div>
    </div>
  </div>
);

const ProjectRequest: FC = () => {
  const { handleMsgType } = useNotification();

  const { projectRequestId } = useParams<ProjectRequestPath>();
  const history = useHistory();
  const [
    projectRequestUserFindManyQuery,
    projectRequestUserFindManyQueryResult,
  ] = useProjectRequestUserFindManyLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [
    projectRequestQuery,
    projectRequestQueryResponse,
  ] = useProjectRequestFindOneLazyQuery();
  const [
    companyEmployeesQuery,
    companyEmployeesQueryResponse,
  ] = useCompanyMaybeUserEmployeeFindManyLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const [companyQuery, companyQueryResponse] = useCompanyLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });
  const projectRequestFileUploadFindManyQueryResponse = useProjectRequestFileUploadFindManyQuery(
    {
      skip: !projectRequestId,
      variables: {
        projectRequestId: projectRequestId ? parseInt(projectRequestId) : 0,
      },
      onError: error => {
        handleMsgType({ type: TNotification.error, message: error?.message });
      },
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      if (projectRequestId) {
        try {
          const projectRequestQueryResponse = await projectRequestQuery({
            variables: { id: parseInt(projectRequestId) },
          });

          if (projectRequestQueryResponse.data?.ProjectRequestFindOne) {
            projectRequestUserFindManyQuery({
              variables: {
                projectRequestId:
                  projectRequestQueryResponse.data.ProjectRequestFindOne.id,
                relationDepth: RelationDepth.Full,
              },
            });
            companyEmployeesQuery({
              variables: {
                companyId:
                  projectRequestQueryResponse.data.ProjectRequestFindOne
                    .companyId,
              },
            });
            companyQuery({
              variables: {
                id:
                  projectRequestQueryResponse.data.ProjectRequestFindOne
                    .companyId,
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPreviousPage = useCallback(() => {
    history.push(appRoutes.projectRequests.url());
  }, [history]);

  const contacts: TContactData[] = useMemo(() => {
    const projectRequestUsers =
      projectRequestUserFindManyQueryResult.data?.ProjectRequestUserFindMany;
    const companyMaybeEmployees =
      companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany;
    if (!projectRequestUsers || !companyMaybeEmployees) {
      return [];
    }

    return projectRequestUsers.map(projectRequestUser => {
      const companyEmployee = companyMaybeEmployees.find(
        cme => cme.userId === projectRequestUser.userId
      );

      return {
        firstName: companyEmployee?.firstName,
        lastName: companyEmployee?.lastName,
        email: companyEmployee?.email,
        type: projectRequestUser.type,
        projectRequestUserId: projectRequestUser.id,
      };
    });
  }, [
    companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany,
    projectRequestUserFindManyQueryResult.data?.ProjectRequestUserFindMany,
  ]);

  if (
    projectRequestUserFindManyQueryResult.data?.ProjectRequestUserFindMany &&
    projectRequestQueryResponse.data?.ProjectRequestFindOne &&
    companyEmployeesQueryResponse.data?.CompanyMaybeUserEmployeeFindMany &&
    companyQueryResponse.data?.company &&
    projectRequestFileUploadFindManyQueryResponse.data
      ?.ProjectRequestFileUploadFindMany
  ) {
    return (
      <ProjectRequestPresentational
        projectRequest={projectRequestQueryResponse.data.ProjectRequestFindOne}
        projectRequestUsers={
          projectRequestUserFindManyQueryResult.data
            .ProjectRequestUserFindMany || []
        }
        contacts={contacts}
        documents={
          projectRequestFileUploadFindManyQueryResponse.data
            .ProjectRequestFileUploadFindMany
        }
        onProjectRequestsButtonClick={goToPreviousPage}
        onProjectRequestDeleted={goToPreviousPage}
      />
    );
  }

  return (
    <div className="project-request-loader-wrapper">
      <Loader isPageLoader variant="bubbles" />
    </div>
  );
};

export default ProjectRequest;
