import { FC } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { TApiKeys } from '../../../../interfaces/apiKey';
import ApiKeysUserListItem from './ApiKeysUserListItem/ApiKeysUserListItem';
import { useApiKeysUserListStyle } from './ApiKeysUserListStyle';

interface IApiKeysUserList {
  apiKeys: TApiKeys;
}

const ApiKeysUserList: FC<IApiKeysUserList> = ({ apiKeys }) => {
  const headerData = ['Api key', 'Created at', 'User email', 'Actions'];
  const classes = useApiKeysUserListStyle();

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            {headerData.map((item, index) => (
              <TableCell key={`a${index}`}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys.map(apiKey => (
            <ApiKeysUserListItem key={apiKey.id} apiKey={apiKey} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApiKeysUserList;
