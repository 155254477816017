import { makeStyles } from '@material-ui/core/styles';

const useTextFormFieldStyle = makeStyles(() => ({
  formField: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export default useTextFormFieldStyle;
