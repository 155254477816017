import { makeStyles } from '@material-ui/core/styles';

const useTraitFormStyle = makeStyles({
  container: {
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default useTraitFormStyle;
